"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audioNoticeDelete = audioNoticeDelete;
exports.audioNoticeInsert = audioNoticeInsert;
exports.audioNoticePage = audioNoticePage;
exports.audioNoticeUpdate = audioNoticeUpdate;
exports.channelDelete = channelDelete;
exports.channelInsert = channelInsert;
exports.channelList = channelList;
exports.channelPage = channelPage;
exports.channelUpdate = channelUpdate;
exports.disciplineDelete = disciplineDelete;
exports.disciplineInsert = disciplineInsert;
exports.disciplinePage = disciplinePage;
exports.disciplineUpdate = disciplineUpdate;
exports.dismissRoom = dismissRoom;
exports.fileRecordUploadFile = fileRecordUploadFile;
exports.markBookRecord = markBookRecord;
exports.markCommit = markCommit;
exports.markCommonlyDelete = markCommonlyDelete;
exports.markCommonlyInsert = markCommonlyInsert;
exports.markCommonlyList = markCommonlyList;
exports.markCommonlyPage = markCommonlyPage;
exports.markCommonlyUpdate = markCommonlyUpdate;
exports.markRecord = markRecord;
exports.markSend = markSend;
exports.partyList = partyList;
exports.partyPage = partyPage;
exports.partyUserCaseList = partyUserCaseList;
exports.recordCaseSituation = recordCaseSituation;
exports.recordCheckUser = recordCheckUser;
exports.recordEnterpriseCaseList = recordEnterpriseCaseList;
exports.recordFinish = recordFinish;
exports.recordHands = recordHands;
exports.recordHandsFix = recordHandsFix;
exports.recordHandsRecord = recordHandsRecord;
exports.recordPage = recordPage;
exports.recordSelect = recordSelect;
exports.recordStart = recordStart;
exports.removeUser = removeUser;
exports.trialPartySign = trialPartySign;
exports.trtcControl = trtcControl;
exports.trtcGetControl = trtcGetControl;
var _request = _interopRequireDefault(require("@/utils/request"));
//移除用户
function removeUser(data) {
  return (0, _request.default)({
    url: "trial/trtc/remove-user",
    method: "post",
    data: data
  });
}

//解散房间
function dismissRoom(data) {
  return (0, _request.default)({
    url: "trial/trtc/dismiss-room",
    method: "post",
    data: data
  });
}

/** 录音公告配置 */
//获取录音公告接口
function audioNoticePage(data) {
  return (0, _request.default)({
    url: "/trial/audio-notice/page",
    method: "post",
    data: data
  });
}
//添加录音公告接口
function audioNoticeInsert(data) {
  return (0, _request.default)({
    url: "/trial/audio-notice/insert",
    method: "post",
    data: data
  });
}

//修改录音公告接口
function audioNoticeUpdate(data) {
  return (0, _request.default)({
    url: "/trial/audio-notice/update",
    method: "post",
    data: data
  });
}

//删除录音公告接口
function audioNoticeDelete(data) {
  return (0, _request.default)({
    url: "/trial/audio-notice/delete",
    method: "post",
    data: data
  });
}

/**会议通道配置 */
//获取会议通道接口
function channelPage(data) {
  return (0, _request.default)({
    url: "/trial/channel/page",
    method: "post",
    data: data
  });
}
//获取全部会议通道接口
function channelList(data) {
  return (0, _request.default)({
    url: "/trial/channel/list",
    method: "post",
    data: data
  });
}

//添加会议通道接口
function channelInsert(data) {
  return (0, _request.default)({
    url: "/trial/channel/insert",
    method: "post",
    data: data
  });
}

//修改会议通道接口
function channelUpdate(data) {
  return (0, _request.default)({
    url: "/trial/channel/update",
    method: "post",
    data: data
  });
}

// 删除会议通道接口
function channelDelete(data) {
  return (0, _request.default)({
    url: "/trial/channel/delete",
    method: "post",
    data: data
  });
}

/**庭审纪律配置 */
//获取庭审纪律接口
function disciplinePage(data) {
  return (0, _request.default)({
    url: "/trial/discipline/page",
    method: "post",
    data: data
  });
}

//添加庭审纪律接口
function disciplineInsert(data) {
  return (0, _request.default)({
    url: "/trial/discipline/insert",
    method: "post",
    data: data
  });
}

//修改庭审纪律接口
function disciplineUpdate(data) {
  return (0, _request.default)({
    url: "/trial/discipline/update",
    method: "post",
    data: data
  });
}

//删除庭审纪律接口
function disciplineDelete(data) {
  return (0, _request.default)({
    url: "/trial/discipline/delete",
    method: "post",
    data: data
  });
}

/** 参庭人员 */
//获取参庭人员接口
function partyPage(data) {
  return (0, _request.default)({
    url: "/trial/party/page",
    method: "post",
    data: data
  });
}

/* 视频庭审记录*/
//获取视频庭审记录接口
function recordPage(data) {
  return (0, _request.default)({
    url: "/trial/record/page",
    method: "post",
    data: data
  });
}

//获取视频庭审详情接口
function recordSelect(id, data) {
  return (0, _request.default)({
    url: "/trial/record/select/" + id,
    method: "GET",
    data: data
  });
}

// 上传接口
function fileRecordUploadFile() {
  return (0, _request.default)({
    url: "/trial/file-record/uploadFile",
    method: "post",
    data: data
  });
}

// 视频页面接口
// 获取所有参庭人员
function partyList(data) {
  return (0, _request.default)({
    url: "/trial/party/list",
    method: "POST",
    data: data
  });
}

// 获取案情&材料
function recordCaseSituation(id, data) {
  return (0, _request.default)({
    url: "/trial/record/caseSituation/" + id,
    method: "GET",
    data: data
  });
}

// 当事人获取视频庭审案件（待庭审）
function partyUserCaseList(data) {
  return (0, _request.default)({
    url: "/trial/party/user/caseList",
    method: "POST",
    data: data
  });
}

// 企业端获取视频庭审案件（待庭审）
function recordEnterpriseCaseList(data) {
  return (0, _request.default)({
    url: "/trial/record/enterprise/caseList",
    method: "POST",
    data: data
  });
}

// 校验会议参庭人员
function recordCheckUser(data) {
  return (0, _request.default)({
    url: "/trial/record/checkUser",
    method: "POST",
    data: data
  });
}

// 发送笔录内容（秘书账户用）
function markSend(data) {
  return (0, _request.default)({
    url: "/trial/mark/mark-send",
    method: "POST",
    data: data
  });
}

//获取笔录内容（企业与当事人账户用）
function markRecord(data) {
  return (0, _request.default)({
    url: "/trial/mark/mark-record",
    method: "POST",
    data: data
  });
}

//举手接口（企业与当事人账户用）
function recordHands(data) {
  return (0, _request.default)({
    url: "/trial/record/hands",
    method: "POST",
    data: data
  });
}

// 获取举手内容记录
function recordHandsRecord(data) {
  return (0, _request.default)({
    url: "/trial/record/hands-record",
    method: "POST",
    data: data
  });
}

//确认举手（秘书账户用）
function recordHandsFix(data) {
  return (0, _request.default)({
    url: "/trial/record/hands-fix",
    method: "POST",
    data: data
  });
}

//---------------------

// 获取常用话术接口
function markCommonlyPage(data) {
  return (0, _request.default)({
    url: "/trial/mark-commonly/page",
    method: "POST",
    data: data
  });
}
// 获取常用话术全部数据接口
function markCommonlyList(data) {
  return (0, _request.default)({
    url: "/trial/mark-commonly/list",
    method: "POST",
    data: data
  });
}

// 添加常用话术接口
function markCommonlyInsert(data) {
  return (0, _request.default)({
    url: "/trial/mark-commonly/insert",
    method: "POST",
    data: data
  });
}

// 修改常用话术接口
function markCommonlyUpdate(data) {
  return (0, _request.default)({
    url: "/trial/mark-commonly/update",
    method: "POST",
    data: data
  });
}

// 删除常用话术接口
function markCommonlyDelete(data) {
  return (0, _request.default)({
    url: "/trial/mark-commonly/delete",
    method: "POST",
    data: data
  });
}

// 发送笔录提交，生成笔录PDF（秘书账户用）
function markCommit(data) {
  return (0, _request.default)({
    url: "/trial/mark/commit",
    method: "POST",
    data: data
  });
}

// 笔录PDF获取（所有账户用）
function markBookRecord(data) {
  return (0, _request.default)({
    url: "/trial/mark/mark-book-record",
    method: "POST",
    data: data
  });
}

// 控制音视频
function trtcControl(data) {
  return (0, _request.default)({
    url: "/trial/trtc/control",
    method: "POST",
    data: data
  });
}

// 获取控制信息
function trtcGetControl(data) {
  return (0, _request.default)({
    url: "/trial/trtc/get-control",
    method: "POST",
    data: data
  });
}

// 开始庭审
function recordStart(data) {
  return (0, _request.default)({
    url: "/trial/record/start",
    method: "POST",
    data: data
  });
}

// 完成庭审
function recordFinish(data) {
  return (0, _request.default)({
    url: "/trial/record/finish",
    method: "POST",
    data: data
  });
}
function trialPartySign(data) {
  return (0, _request.default)({
    url: "/trial/party/sign",
    method: "POST",
    data: data
  });
}