"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _configure = require("@/api/configure.js");
var _security = require("@/utils/security");
var _encryptKey = require("@/config/encryptKey");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getToken } from 'api/qiniu'
var _default = {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data: function data() {
    return {
      imageUploadVisible: false,
      listObj: {},
      fileList: [],
      headers: {
        'Authorization': localStorage.getItem('token')
      },
      action: process.env.VUE_APP_BASE_API + (0, _configure.uploadCommonURL)()
    };
  },
  methods: {
    checkAllSuccess: function checkAllSuccess() {
      var _this = this;
      return Object.keys(this.listObj).every(function (item) {
        return _this.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      var arr = Object.keys(this.listObj).map(function (v) {
        return _this2.listObj[v];
      });
      if (!this.checkAllSuccess()) {
        this.$message('请先等待图片全部上传完成，如果当前网络有问题，请刷新当前页面重新上传！');
        return;
      }
      this.$emit('successCBK', arr);
      this.listObj = {};
      this.fileList = [];
      this.imageUploadVisible = false;
    },
    handleSuccess: function handleSuccess(res, file) {
      var response = JSON.parse((0, _security.decryptByAES)(res.response, _encryptKey.encryptKey.key));
      if (response.code == 1) {
        var uid = file.uid;
        var objKeyArr = Object.keys(this.listObj);
        for (var i = 0, len = objKeyArr.length; i < len; i++) {
          if (this.listObj[objKeyArr[i]].uid === uid) {
            this.listObj[objKeyArr[i]].url = response.data.href;
            this.listObj[objKeyArr[i]].hasSuccess = true;
            return;
          }
        }
      } else {
        location.reload();
      }
    },
    handleRemove: function handleRemove(file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);
      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      var _self = this;
      var _URL = window.URL || window.webkitURL;
      var fileName = file.uid;
      this.listObj[fileName] = {};
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height
          };
        };
        resolve(true);
      });
    }
  }
};
exports.default = _default;