"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      enterAccountDate: '',
      //入账时间
      accountName: '',
      //账户名称
      accountType: '',
      //账户类型
      payType: '',
      //支付方式
      input: '',
      //单号
      accountNameArr: [{
        //账户种类
        value: '专项预缴费用(代收)',
        label: '专项预缴费用(代收)'
      }, {
        value: '预缴费用',
        label: '预缴费用'
      }, {
        value: '应收仲裁费',
        label: '应收仲裁费'
      }, {
        value: '应付费用',
        label: '应付费用'
      }, {
        value: '应付薪酬',
        label: '应付薪酬'
      }],
      accountTypeArr: [{
        //账户类型
        value: '互仲链代收',
        label: '互仲链代收'
      }, {
        value: '现金',
        label: '现金'
      }, {
        value: '对公打款',
        label: '对公打款'
      }],
      tableData: [{
        aa1: '仲裁退费',
        aa2: '2019-08-28 00:00:00',
        aa3: 'AJ20190903180541LS20190903180541',
        aa4: '预缴费用',
        aa5: '收入',
        aa6: '林某某',
        aa7: '现金',
        aa8: '100',
        aa9: '1000'
      }]
    };
  },
  methods: {
    // 筛选按钮
    screen: function screen() {},
    // 表格 按钮
    handleClick: function handleClick() {}
  }
};
exports.default = _default;