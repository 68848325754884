var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("el-button", { attrs: { type: "success", plain: "" } }, [
            _vm._v("待对账"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "basic-message" },
        [
          _c(
            "el-main",
            [
              _c("div", { staticClass: "basic-message-title" }, [
                _vm._v("基本信息"),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单人：张科")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("对账备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("收款备注：")]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "summary" },
        [
          _c("div", { staticClass: "summary-title" }, [_vm._v("单据汇总")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "summary-tip" }, [
            _vm._v("共 3000 个案件，合计金额 300000 元"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "expand-change": _vm.rowExpand },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: props.row.tableData1 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "案号",
                                width: "200",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "结案时间",
                                width: "200",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "报酬类型",
                                width: "300",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "报酬金额(元)",
                                width: "300",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发放对象", prop: "name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "报酬周期", prop: "time" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "报酬总金额(元)", prop: "money" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  _vm.noteMakingVisible = true
                },
              },
            },
            [_vm._v("备注")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.agreeHandle },
            },
            [_vm._v("同意发放")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "备注", visible: _vm.noteMakingVisible },
          on: {
            "update:visible": function ($event) {
              _vm.noteMakingVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              resize: "none",
              rows: "5",
              autocomplete: "off",
              placeholder: "请输入备注",
            },
            model: {
              value: _vm.note,
              callback: function ($$v) {
                _vm.note = $$v
              },
              expression: "note",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }