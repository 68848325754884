"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onBase64 = onBase64;
exports.onExport = onExport;
require("core-js/modules/es6.function.name");
function onExport(config) {
  var col = config.col || [];
  var data = config.data || [];
  if (!data || data.length <= 0) return;
  var content = '<tr>';
  for (var i = 0; i < col.length; i++) {
    content += '<th>' + col[i].name + '\t' + '</th>';
  }
  content + '</tr>';
  for (var i = 0; i < data.length; i++) {
    var item = data[i];
    content += '<tr>';
    for (var j = 0; j < col.length; j++) {
      var key = col[j].index;
      if (key == 'index') {
        content += '<td>' + (i + 1) + '\t' + '</td>';
      } else {
        content += '<td>' + item[key] + '\t' + '</td>';
      }
    }
    content += '</tr>';
  }
  var worksheet = 'sheet1';
  var template = "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\" \n      xmlns:x=\"urn:schemas-microsoft-com:office:excel\" \n      xmlns=\"http://www.w3.org/TR/REC-html40\">\n      <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>\n        <x:Name>".concat(worksheet, "</x:Name>\n        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>\n        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->\n         <style type=\"text/css\">\n        .bg-gray {\n          background: #aaa;\n          }\n          .bg-gray td {\n             text-align: left;\n          }\n          th{\n            border:1px solid #ddd;\n             height:30px;\n             font-size:18px;\n               text-align: center;font-weight:bold;\n          }\n          td {\n            border:1px solid #ddd;\n            height:30px;font-size:18px;\n            text-align: center;\n            mso-number-format:@;\n            vnd.ms-excel.numberformat:@;\n          }\n        </style>\n \n        </head><body><table>").concat(content, "</table></body></html>");
  var url = 'data:application/vnd.ms-excel;base64,' + onBase64(template);
  var oa = document.createElement('a');
  oa.href = url;
  oa.download = new Date().getTime() + '.xls';
  document.body.appendChild(oa);
  oa.click();
  document.body.removeChild(oa);
}
;
function onBase64(s) {
  return window.btoa(unescape(encodeURIComponent(s)));
}
;