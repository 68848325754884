"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    svgName: function svgName() {
      return "#icon-".concat(this.iconName);
    },
    svgClass: function svgClass() {
      if (this.className) {
        return "svg-icon ".concat(this.className);
      }
      return 'svg-icon';
    }
  }
};
exports.default = _default;