var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      attrs: { placeholder: _vm.label, type: _vm.type },
      model: {
        value: _vm.infoValue,
        callback: function ($$v) {
          _vm.infoValue = $$v
        },
        expression: "infoValue",
      },
    },
    [
      _c("template", { slot: "prepend" }, [
        _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }