var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stream" }, [
    _c("div", { staticClass: "center video" }, [
      _c("div", {
        key: _vm.dataValue ? _vm.dataValue.key : "",
        staticClass: "local-stream-content",
        attrs: { id: _vm.dataValue ? _vm.dataValue.key : "" },
      }),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "title" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.dataValue ? _vm.dataValue.title : "") + "\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.dataValue.type == 7
      ? _c(
          "div",
          { staticClass: "toolbar" },
          [
            _c("stream-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasBtnAudio,
                  expression: "hasBtnAudio",
                },
              ],
              attrs: {
                "data-icon": "ic:round-mic",
                "data-content": "关闭麦克",
                "data-size": _vm.dataSize,
              },
              on: {
                "handle-click": function ($event) {
                  return _vm.handleClick("audio", false)
                },
              },
            }),
            _vm._v(" "),
            _c("stream-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasBtnAudio,
                  expression: "!hasBtnAudio",
                },
              ],
              attrs: {
                "data-icon": "ic:round-mic-off",
                "data-content": "开启麦克",
                "data-size": _vm.dataSize,
              },
              on: {
                "handle-click": function ($event) {
                  return _vm.handleClick("audio", true)
                },
              },
            }),
            _vm._v(" "),
            _c("stream-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasBtnVideo,
                  expression: "hasBtnVideo",
                },
              ],
              attrs: {
                "data-icon": "ic:round-videocam",
                "data-content": "关闭视频",
                "data-size": _vm.dataSize,
              },
              on: {
                "handle-click": function ($event) {
                  return _vm.handleClick("video", false)
                },
              },
            }),
            _vm._v(" "),
            _c("stream-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasBtnVideo,
                  expression: "!hasBtnVideo",
                },
              ],
              attrs: {
                "data-icon": "ic:round-videocam-off",
                "data-content": "打开视频",
                "data-size": _vm.dataSize,
              },
              on: {
                "handle-click": function ($event) {
                  return _vm.handleClick("video", true)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wifi" }, [
      _c("span", {
        staticClass: "iconify-inline",
        staticStyle: { color: "#fff" },
        attrs: {
          "data-icon": "ic:baseline-arrow-right-alt",
          "data-width": "24",
          "data-height": "24",
          width: "18",
          "data-rotate": "270deg",
        },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "iconify-inline",
        staticStyle: {
          color: "#fff",
          "font-size": "24px",
          "margin-left": "-12px",
        },
        attrs: { "data-icon": "ic:round-signal-cellular-alt" },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "iconify-inline",
        staticStyle: { color: "#fff" },
        attrs: {
          "data-width": "24",
          "data-height": "24",
          "data-icon": "ic:baseline-arrow-right-alt",
          "data-rotate": "90deg",
        },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "iconify-inline",
        staticStyle: {
          color: "#fff",
          "font-size": "24px",
          "margin-left": "-12px",
        },
        attrs: { "data-icon": "ic:round-signal-cellular-alt" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }