var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "holdcourt global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: { rowSecondName: "立案日期", rowThirdName: "组庭日期" },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "holdcourt-all-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.batchReview },
            },
            [_vm._v("批量审核")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "holdcourt-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == "3"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核\n          ")]
                            )
                          : _c("el-button", {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleReview(scope.row)
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "holdcourtLot",
              attrs: {
                title: "案件开庭(批量)",
                visible: _vm.holdcourtLotVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.holdcourtLotVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "holdcourtLotForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "120px",
                    model: _vm.holdcourtLotForm,
                    rules: _vm.holdcourtLotRules,
                  },
                },
                [
                  _c("div", { staticClass: "tipCount" }, [
                    _vm._v("批量开庭共计" + _vm._s(_vm.caseNum) + "个案件。"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭时间", prop: "moment" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择开庭日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.holdcourtLotForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtLotForm, "date", $$v)
                              },
                              expression: "holdcourtLotForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              placeholder: "选择开庭时间",
                              "picker-options": {
                                start: "08:00",
                                step: "00:05",
                                end: "21:00",
                              },
                            },
                            model: {
                              value: _vm.holdcourtLotForm.moment,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtLotForm, "moment", $$v)
                              },
                              expression: "holdcourtLotForm.moment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭地址", prop: "courtAddress" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.holdcourtLotForm.courtAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.holdcourtLotForm,
                                "courtAddress",
                                $$v
                              )
                            },
                            expression: "holdcourtLotForm.courtAddress",
                          },
                        },
                        _vm._l(_vm.courtList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.address, value: item.name },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "holdcourtLot-add",
                          on: {
                            click: function ($event) {
                              _vm.holdcourtInnerVisible = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.holdcourtLotVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.batchReviewSubmit("holdcourtLotForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "添加开庭地址",
                    visible: _vm.holdcourtInnerVisible,
                    "close-on-click-modal": false,
                    "append-to-body": "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.holdcourtInnerVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addAddressForm",
                      attrs: {
                        "label-position": "right",
                        "label-width": "120px",
                        model: _vm.addAddressForm,
                        rules: _vm.addAddressRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写开庭名称（20字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "name", $$v)
                              },
                              expression: "addAddressForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写开庭地址（255字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "address", $$v)
                              },
                              expression: "addAddressForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式", prop: "tel" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写联系方式",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "tel", $$v)
                              },
                              expression: "addAddressForm.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.holdcourtInnerVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddAddress("addAddressForm")
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "holdcourt",
              attrs: {
                title: "案件开庭",
                visible: _vm.holdcourtVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.holdcourtVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "holdcourtForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "150px",
                    model: _vm.holdcourtForm,
                    rules: _vm.holdcourtRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭方式", prop: "courtType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.holdcourtForm.courtType,
                            callback: function ($$v) {
                              _vm.$set(_vm.holdcourtForm, "courtType", $$v)
                            },
                            expression: "holdcourtForm.courtType",
                          },
                        },
                        _vm._l(_vm.wayList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭时间", prop: "moment" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择开庭日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.holdcourtForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtForm, "date", $$v)
                              },
                              expression: "holdcourtForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              placeholder: "选择开庭时间",
                              "picker-options": {
                                start: "08:00",
                                step: "00:05",
                                end: "21:00",
                              },
                            },
                            model: {
                              value: _vm.holdcourtForm.moment,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtForm, "moment", $$v)
                              },
                              expression: "holdcourtForm.moment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.holdcourtForm.courtType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开庭地址", prop: "courtAddress" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.holdcourtForm.courtAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.holdcourtForm,
                                    "courtAddress",
                                    $$v
                                  )
                                },
                                expression: "holdcourtForm.courtAddress",
                              },
                            },
                            _vm._l(_vm.courtList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.address,
                                  value: item.address,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "holdcourtLot-add",
                              on: {
                                click: function ($event) {
                                  _vm.holdcourtInnerVisible = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.holdcourtForm.courtType == 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开庭地址(会议通道)",
                            prop: "courtAddress",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.holdcourtForm.courtAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.holdcourtForm,
                                    "courtAddress",
                                    $$v
                                  )
                                },
                                expression: "holdcourtForm.courtAddress",
                              },
                            },
                            _vm._l(_vm.courtList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.address,
                                  value: item.address,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.holdcourtVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.gotoServicing },
                      on: {
                        click: function ($event) {
                          return _vm.gotoService("holdcourtForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "添加开庭地址",
                    visible: _vm.holdcourtInnerVisible,
                    "close-on-click-modal": false,
                    "append-to-body": "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.holdcourtInnerVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addAddressForm",
                      attrs: {
                        "label-position": "right",
                        "label-width": "120px",
                        model: _vm.addAddressForm,
                        rules: _vm.addAddressRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写开庭名称（20字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "name", $$v)
                              },
                              expression: "addAddressForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写开庭地址（255字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "address", $$v)
                              },
                              expression: "addAddressForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式", prop: "tel" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写联系方式",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "tel", $$v)
                              },
                              expression: "addAddressForm.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.holdcourtInnerVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddAddress("addAddressForm")
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.sendCaseVisible
        ? _c("send-case", {
            ref: "sendCase",
            on: {
              handleShowing: _vm.updateShow,
              handleErrorShowing: _vm.updateErrorShow,
              initList: _vm.refreshPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }