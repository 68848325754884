var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auditInCase global-page-base-layout" },
    [
      _c("Caseconditons", {
        on: {
          choose: _vm.checkAuditInList,
          cleanCondition: _vm.cleanConditionData,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "auditInCase-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "procedureType", label: "审核类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.caseExamineType[scope.row.procedureType]
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseState", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.caseState[scope.row.caseState]) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "checkState", label: "审核状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.caseExamineState[scope.row.checkState])
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "权利申请时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.checkState == 1 ||
                        scope.row.checkState == "-1" ||
                        scope.row.checkState == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoExamineDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !scope.row.checkState &&
                        scope.row.procedureName != "答辩"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoExamineDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.procedureName == "答辩"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoCheck(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "examine-detail",
          attrs: {
            "close-on-click-modal": false,
            title: "审核详情-" + _vm.examineType,
            visible: _vm.examineDetailVisible,
            top: "5vh",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.examineDetailVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("案号："),
            _c("span", { staticStyle: { color: "#1067D1" } }, [
              _vm._v(_vm._s(_vm.detailData.caseNo)),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "describe" }, [
            _c("div", [_vm._v("申请人：" + _vm._s(_vm.detailData.appName))]),
            _vm._v(" "),
            _c("div", [
              _vm._v("审核类型：" + _vm._s(_vm.detailData.procedureName)),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "审核状态：" +
                  _vm._s(_vm.caseExamineState[_vm.detailData.checkState])
              ),
            ]),
            _vm._v(" "),
            _vm.examineType == "变更仲裁请求"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [
                    _vm._v(
                      "仲裁请求：" + _vm._s(_vm.detailData.arbitrationRequest)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "事实与理由：" + _vm._s(_vm.detailData.arbitrationAruth)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("变更仲裁申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: _vm.detailData.applyDocumentPath,
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.applyDocumentName))]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "申请鉴定"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [_vm._v("鉴定理由：仲裁仲裁")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("鉴定事项：1. 要求鉴定借款合同真实性")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("调解申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "申请调解"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [_vm._v("调解依据：仲裁仲裁")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("调解请求：1. 要求鉴定借款合同真实性")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("调解申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: _vm.detailData.applyDocumentPath,
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.applyDocumentName))]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "补充材料"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c(
                    "div",
                    [
                      _vm._v("补充材料："),
                      _vm._l(_vm.detailData.proofFiles, function (item, index) {
                        return _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: item,
                              target: "_blank",
                              underline: false,
                            },
                          },
                          [_vm._v("材料" + _vm._s(index + 1) + "、")]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "撤案申请"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [
                    _vm._v("撤案理由：" + _vm._s(_vm.detailData.content)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("调解申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: _vm.detailData.applyDocumentPath,
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailData.applyDocumentName))]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "申请延期"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [_vm._v("延期理由：仲裁仲裁")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("延期申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "仲裁员回避"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [
                    _vm._v(
                      "涉及仲裁员：" + _vm._s(_vm.detailData.arbitratorName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "事实与理由：" + _vm._s(_vm.detailData.arbitrationAruth)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("办案秘书回避申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "反请求"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [_vm._v("仲裁反请求事项：仲裁仲裁")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("仲裁反请求事实与理由：仲裁仲裁")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("仲裁反请求申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("证据清单："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examineType == "申请保全"
              ? _c("div", { staticClass: "diff-desc" }, [
                  _c("div", [_vm._v("仲裁反请求事项：仲裁仲裁")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("仲裁反请求事实与理由：仲裁仲裁")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("仲裁反请求申请书："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v("证据清单："),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: "https://element.eleme.io",
                            target: "_blank",
                            underline: false,
                          },
                        },
                        [_vm._v("主要链接")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "secret-comments" }, [
            _c("p", [
              _vm._v(
                "办案秘书" + _vm._s(_vm.detailData.handingSecretaryName) + "："
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.detailData.reason))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "examine-record" },
            [
              _c("div", { staticClass: "examine-record-title" }, [
                _vm._v("审核记录"),
              ]),
              _vm._v(" "),
              _c(
                "el-timeline",
                { staticClass: "examine-record-content" },
                _vm._l(_vm.activities, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        type: "primary",
                        size: "large",
                        placement: "top",
                        timestamp: item.auditTime,
                      },
                    },
                    [
                      _vm._v(
                        "\n               " +
                          _vm._s(item.opinionName) +
                          ":" +
                          _vm._s(item.opinion) +
                          "\n             "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.detailData.checkState == 0
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.examineRefuse()
                        },
                      },
                    },
                    [_vm._v("拒 绝")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.examineAdopt()
                        },
                      },
                    },
                    [_vm._v("通过审核")]
                  ),
                  _vm._v(" "),
                  _vm.examineType == "申请保全"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.sumbitToCourt },
                        },
                        [_vm._v("提交法院")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "check",
          attrs: {
            title: "答辩信息",
            visible: _vm.checkVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "check-desc" }, [
            _c("div", [_vm._v("答辩内容：" + _vm._s(this.detailData.content))]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(" 答辩材料："),
                _vm._l(this.detailData.replyPaths, function (item, index) {
                  return _c(
                    "el-link",
                    {
                      attrs: {
                        underline: false,
                        type: "primary",
                        target: "_blank",
                        href: item,
                      },
                    },
                    [_vm._v(_vm._s(item) + "、")]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.checkVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "award-editor",
          attrs: {
            title: "出具" + _vm.editorTitle,
            visible: _vm.editVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "p",
            { staticClass: "preview-decision" },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    type: "primary",
                    href: "https://www.baidu.com",
                    target: "_blank",
                    underline: false,
                  },
                },
                [_vm._v("预览决定书")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { height: "500px" } },
            [
              _c("Editor", {
                on: { input: _vm.contentChange },
                model: {
                  value: _vm.article.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.article, "content", $$v)
                  },
                  expression: "article.content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sendNotice } },
                [_vm._v("出具" + _vm._s(_vm.editorTitle) + "并通知当事人")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "resolution",
          attrs: {
            title: "处理意见",
            visible: _vm.resolutionVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.resolutionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "resolutionForm", attrs: { model: _vm.resolutionform } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "regionInput",
                    rules: [{ required: true, message: "经办意见不能为空" }],
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "resolution-dialog-textarea",
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请填写经办意见",
                      resize: "none",
                    },
                    model: {
                      value: _vm.resolutionform.regionInput,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.resolutionform,
                          "regionInput",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "resolutionform.regionInput",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.resolutionConfirm("resolutionForm")
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交法院",
            visible: _vm.courtVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.courtVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 10,
              resize: "none",
              placeholder: "在此处填写提交反馈",
            },
            model: {
              value: _vm.courtTextarea,
              callback: function ($$v) {
                _vm.courtTextarea = $$v
              },
              expression: "courtTextarea",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.courtVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.courtConfirm } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }