"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _commission = require("@/api/commission.js");
var _dictionary = require("@/config/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'handing',
  data: function data() {
    var _ref;
    return _ref = {
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      caseId: '',
      selectSecVisble: false,
      selectSecform: {
        secretaryId: ''
      },
      selectSecRules: {
        secretaryId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      transferState: ''
    }, (0, _defineProperty2.default)(_ref, "caseState", _dictionary.dictionary.caseState), (0, _defineProperty2.default)(_ref, "secretaryList", []), _ref;
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {},
  methods: {
    init: function init(data) {
      var _this = this;
      this.loading = true;
      (0, _commission.findTransferLogPage)({
        transferState: data,
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //转移
    handleBatchTransfer: function handleBatchTransfer() {
      var _this2 = this;
      this.multipleSelectionId = [];
      this.multipleSelection.forEach(function (item) {
        _this2.multipleSelectionId.push(item.id);
      });
      this.selectSecVisble = true;
      (0, _commission.findSecretaryList)().then(function (res) {
        if (res.code == 1) {
          _this2.secretaryList = res.data;
        }
      });
    },
    handleSelectSecSubmit: function handleSelectSecSubmit(selectSecform) {
      var _this3 = this;
      this.$refs[selectSecform].validate(function (valid) {
        if (valid) {
          var params = {
            secretaryId: _this3.selectSecform.secretaryId,
            ids: _this3.multipleSelectionId
          };
          (0, _commission.allotCase)(params).then(function (res) {
            if (res.code == 1) {
              _this3.selectSecVisble = false;
              _this3.init();
            } else {
              _this3.$message.error(res.msg);
            }
          });
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      if (this.transferState) {
        this.currentPage = 1;
        this.init(this.transferState);
      }
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.transferState = '';
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.pageLimit = val;
      if (this.transferState) {
        // 有筛选条件的情况下
        (0, _commission.findTransferLogPage)({
          limit: val,
          page: this.currentPage,
          transferState: ''
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _commission.findTransferLogPage)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      if (this.transferState) {
        // 有筛选条件的情况下
        (0, _commission.findTransferLogPage)({
          limit: this.pageLimit,
          page: val,
          transferState: ''
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _commission.findTransferLogPage)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;