"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dictionary = require("@/config/dictionary");
var _role = require("@/api/role");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {},
  data: function data() {
    return {
      loading: false,
      visible: false,
      span: 18,
      dataForm: {
        id: '',
        name: '',
        account: '',
        mobile: '',
        password: '',
        // roleIds: [],
        state: '',
        validityDay: ''
      },
      roleIds: [],
      roleList: [],
      stateList: _dictionary.dictionary.adminState,
      dataRule: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }, {
          max: 30,
          message: '名称最大长度11位数',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '账号不能为空',
          trigger: 'blur'
        }, {
          min: 5,
          max: 30,
          message: '账号长度5-30位数',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机不能为空',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '手机号码11位数',
          trigger: 'blur'
        }],
        password: [{
          required: false,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          max: 30,
          message: '名称长度6-30位数',
          trigger: 'blur'
        }],
        roleIds: [{
          required: false,
          message: '角色不能为空',
          trigger: 'blur'
        }],
        state: [{
          required: false,
          message: '状态不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getRoleList();
  },
  methods: {
    getRoleList: function getRoleList() {
      var _this = this;
      (0, _role.getRoleList)().then(function (res) {
        _this.roleList = res.data;
      }).catch(function (error) {});
    },
    init: function init(id) {
      var _this2 = this;
      this.dataForm.id = id;
      this.visible = true;
      if (this.dataForm.id) {
        (0, _role.inquiryAdmin)(this.dataForm.id).then(function (res) {
          _this2.loading = false;
          _this2.dataForm = res.data;
          _this2.dataForm.state = res.data.state + '';
        }).catch(function (error) {
          _this2.loading = false;
        });

        //查询用户已有权限和可选权限
        (0, _role.inquiryAdminRole)(this.dataForm.id).then(function (res) {
          _this2.roleList = res.data.allRole;
          // this.dataForm.roleIds = res.data.haveRole;
          _this2.roleIds = res.data.haveRole;
        }).catch(function (error) {});
      }
    },
    handleUpdateAdmin: function handleUpdateAdmin() {
      var _this3 = this;
      this.dataForm.roleIds = this.roleIds;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          (0, _role.updateAdmin)(_this3.dataForm).then(function (res) {
            if (res.code === 1) {
              _this3.visible = false;
              _this3.loading = false;
              _this3.$refs['dataForm'].resetFields();
              _this3.$emit('refreshDataList');
              _this3.$message({
                message: '修改成功',
                type: 'success',
                duration: 1500
              });
            } else {
              _this3.$message.error(res.msg);
            }
          }).catch(function (error) {});
        }
      });
    },
    cancel: function cancel(dataForm) {
      this.$refs[dataForm].resetFields();
      this.roleIds = [];
      this.visible = false;
    }
  }
};
exports.default = _default;