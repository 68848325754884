var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "financial-profile" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "el-col",
          { staticStyle: { "text-align": "center" }, attrs: { span: 8 } },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "title-left" }, [
                _vm._v("应收费用（元）"),
              ]),
              _c("span", { staticClass: "title-right" }, [_vm._v("明细")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-left" }, [_vm._v("10000")]),
              _vm._v(" "),
              _c("span", { staticClass: "content-right" }, [_vm._v("对账")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticStyle: { "text-align": "center" }, attrs: { span: 8 } },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "title-left" }, [
                _vm._v("预售仲裁费（元）"),
              ]),
              _c("span", { staticClass: "title-right" }, [_vm._v("明细")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-left" }, [_vm._v("10000")]),
              _vm._v(" "),
              _c("span", { staticClass: "content-right" }, [_vm._v("对账")]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticStyle: { "text-align": "center" }, attrs: { span: 8 } },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "title-left" }, [
                _vm._v("在途金额（元）"),
              ]),
              _c("span", { staticClass: "title-right" }, [_vm._v("明细")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-left" }, [_vm._v("10000")]),
              _vm._v(" "),
              _c("span", { staticClass: "content-right" }, [_vm._v("对账")]),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "el-col",
          { staticStyle: { "text-align": "center" }, attrs: { span: 8 } },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "title-left" }, [
                _vm._v("待发放报酬（元）"),
              ]),
              _c("span", { staticClass: "title-right" }, [_vm._v("明细")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("span", { staticClass: "content-left" }, [_vm._v("10000")]),
              _vm._v(" "),
              _c("span", { staticClass: "content-right" }, [_vm._v("对账")]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }