//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getcaseSendTemplateList, getOperateLogPage } from '@/api/configure.js';
import { dictionary } from '@/config/dictionary';
export default {
  name: 'IPManagement',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      method: '',
      //状态值
      url: '',
      //状态值
      stateArr: [{
        //状态数组
        value: 'GET',
        label: 'GET'
      }, {
        value: 'POST',
        label: 'POST'
      }, {
        value: 'PUT',
        label: 'PUT'
      }, {
        value: 'DELETE',
        label: 'DELETE'
      }],
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1
    };
  },
  created: function created() {
    this.loading = false;
    this.init();
  },
  methods: {
    // 初始化
    init: function init() {
      var _this = this;
      this.total = 1;
      console.log(">>>>111");
      getOperateLogPage({
        method: this.method,
        url: this.url,
        limit: 10,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    //筛选
    screen: function screen() {
      var _this2 = this;
      this.total = 1;
      console.log(">>>>111");
      getOperateLogPage({
        method: this.method,
        url: this.url,
        limit: 10,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
          _this2.loading = false;
        }
      });
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.method = '';
      this.url = "";
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      this.currentPage = val;
      var param = {
        method: this.method,
        url: this.url,
        limit: 10,
        page: this.currentPage
      };
      console.log(param);
      getOperateLogPage(param).then(function (res) {
        console.log("res");
        console.log(res);
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
          _this3.loading = false;
        }
      });
    }
  }
};