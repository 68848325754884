"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry");
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "bookExam",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      tableData: [],
      multipleSelection: [],
      multipleSelectionId: [],
      caseState: _dictionary.dictionary.caseState,
      opinionVisble: false,
      //  确认送达弹窗显示
      opinionform: {
        txt: "",
        radio: "通过审核",
        dealperson: ""
      },
      conditionData: {
        states: [42, 43, 44, 45, 46]
      },
      //筛选条件
      dealPeople: [],
      //处理人列表
      currentCaseId: "",
      //当前的caseid
      examineData: "",
      btnLoading: false,
      //按钮loading
      ruleFile: {},
      closeCaseVisible: false,
      gotoServicing: false,
      rejectLoading: false,
      rejectVisible: false,
      //拒绝弹窗
      opinionform1: {
        //拒绝表单
        txt: ""
      }
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      this.currentPage = 1;
      (0, _inquiry.getProcessList)({
        limit: this.pageLimit,
        page: this.currentPage,
        states: [42, 43, 44, 45, 46]
      }).then(function (res) {
        _this.loading = false;
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    //重新加载列表
    refreshList: function refreshList() {
      var _this2 = this;
      if (this.conditionData.length > 0) {
        this.conditionData.page = this.currentPage;
        this.conditionData.states = [42, 43, 44, 45, 46];
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      } else {
        (0, _inquiry.getProcessList)({
          limit: 10,
          page: this.currentPage,
          states: [42, 43, 44, 45, 46]
        }).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginRuleTime: data.rowSecondDate[0],
        endRuleTime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1,
        states: [42, 43, 44, 45, 46]
      };
      this.conditionData = postData;
      (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量审核
    batchCheck: function batchCheck() {
      var _this4 = this;
      this.$confirm("确定批量审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.multipleSelectionId = [];
        _this4.multipleSelection.forEach(function (item) {
          _this4.multipleSelectionId.push(item.caseId);
        });
        (0, _inquiry.batchTask)({
          caseIds: _this4.multipleSelectionId,
          caseState: 44,
          content: ''
        }).then(function (res) {
          if (res.code == 1) {
            _this4.sumbiting = false;
            _this4.$message({
              message: '批量操作成功',
              type: 'success'
            });
            _this4.caseBriefVisible = false;
            _this4.init();
          } else {
            _this4.$message.error(res.msg);
          }
        });
      });
    },
    // 表格  点击案号
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //表格  审核
    handleClick: function handleClick(val) {
      var _this5 = this;
      this.currentCaseId = val.caseId;
      this.currentNode = val.node;
      if (val.state == 42 || val.state == 43 || val.state == 44 || val.state == 45 || val.state == 46) {
        (0, _inquiry.createRuledFile)({
          caseId: val.caseId
        }).then(function (res) {
          if (res.code == 1) {
            _this5.ruleFile = res.data;
            _this5.closeCaseVisible = true;
          } else {
            _this5.$message.error(res.msg);
          }
        });
      } else {
        (0, _inquiry.getDealPeopleList)({
          caseId: val.caseId,
          node: val.node
        }).then(function (res) {
          if (res.code == 1) {
            _this5.opinionVisble = true;
            _this5.examineData = res.data;
            _this5.dealPeople = res.data[0].allReviews;
            _this5.opinionform.dealperson = _this5.dealPeople[0].name;
            _this5.opinionform.txt = res.data[0].content;
          }
        });
      }
    },
    // 通过审核
    adoptCase: function adoptCase() {
      var _this6 = this;
      this.gotoServicing = true;
      (0, _inquiry.procedureByAdopt)({
        caseId: this.currentCaseId,
        node: this.currentNode
      }).then(function (res) {
        if (res.code == 1) {
          _this6.gotoServicing = false;
          _this6.closeCaseVisible = false;
          _this6.refreshList();
        }
      });
    },
    handleRefuseClick: function handleRefuseClick(val) {
      this.rejectVisible = true;
      this.currentCaseId = val.caseId;
      this.currentNode = val.node;
    },
    //拒绝表单
    confirmReject: function confirmReject(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this7.rejectLoading = true;
          (0, _inquiry.procedureByRefuse)({
            caseId: _this7.currentCaseId,
            node: _this7.currentNode,
            content: _this7.opinionform.txt
          }).then(function (res) {
            if (res.code == 1) {
              _this7.$refs[formName].resetFields();
              _this7.rejectVisible = false;
              _this7.rejectLoading = false;
              _this7.closeCaseVisible = false;
              _this7.refreshList();
            } else _this7.rejectLoading = false;
          }).catch(function (error) {
            _this7.rejectLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    changeAdvice: function changeAdvice(val) {
      if (val == "通过审核") {
        this.opinionform.dealperson = this.dealPeople[0].name;
        this.opinionform.txt = this.examineData[0].content;
      } else {
        this.opinionform.txt = "";
      }
    },
    // 处理人列表改变时
    changeList: function changeList(val) {
      // let item = this.dealPeople.find((ele)=>{
      //   return ele.id == val
      // })
      // this.opinionform.txt = item.content;
    },
    //重置审核表单
    resetOpinionForm: function resetOpinionForm(formName) {
      this.$refs[formName].resetFields();
      this.opinionVisble = false;
    },
    // 提交审核意见
    sumbitOpinionForm: function sumbitOpinionForm(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var index = _this8.dealPeople.findIndex(function (ele) {
            return ele.name == _this8.opinionform.dealperson;
          });
          if (_this8.opinionform.radio == "通过审核") {
            _this8.btnLoading = true;
            (0, _inquiry.procedureByAdopt)({
              caseId: _this8.currentCaseId,
              reviewId: _this8.dealPeople[index].id,
              reviewName: _this8.opinionform.dealperson,
              content: _this8.opinionform.txt,
              node: _this8.currentNode
            }).then(function (res) {
              if (res.code == 1) {
                _this8.btnLoading = false;
                _this8.$refs[formName].resetFields();
                _this8.opinionVisble = false;
                _this8.refreshList();
              }
            });
          } else if (_this8.opinionform.radio == "拒绝") {
            (0, _inquiry.procedureByRefuse)({
              caseId: _this8.currentCaseId,
              content: _this8.opinionform.txt
            }).then(function (res) {
              if (res.code == 1) {
                _this8.$refs[formName].resetFields();
                _this8.opinionVisble = false;
                _this8.refreshList();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this9 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getProcessList)({
          limit: val,
          page: this.currentPage,
          states: [42, 43, 44, 45, 46]
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this10 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getProcessList)({
          limit: this.pageLimit,
          page: val,
          states: [42, 43, 44, 45, 46]
        }).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      }
    },
    // 表格 审核按钮
    check: function check(data) {
      this.opinionVisble = true;
    },
    //提交表单
    submitForm: function submitForm(data) {
      this.opinionVisble = false;
    }
  }
};
exports.default = _default;