var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rtc-container" }, [
    _c("div", { staticClass: "control-container" }, [
      _c(
        "div",
        { staticClass: "rtc-control-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "button",
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.isJoining || _vm.isJoined,
              },
              on: { click: _vm.handleJoinRoom },
            },
            [_vm._v("进入房间")]
          ),
          _vm._v(" "),
          _vm.isHostMode
            ? _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.isPublishing || _vm.isPublished,
                  },
                  on: { click: _vm.handlePublish },
                },
                [_vm._v("发布流")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isHostMode
            ? _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleUnpublish },
                },
                [_vm._v("取消发布流")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleLeave },
            },
            [_vm._v("离开房间")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isHostMode
        ? _c(
            "div",
            { staticClass: "screen-share-control-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.isShareJoined && _vm.isSharePublished,
                  },
                  on: { click: _vm.handleStartScreenShare },
                },
                [_vm._v("开始共享屏幕")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleStopScreenShare },
                },
                [_vm._v("停止共享屏幕")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.inviteLink
      ? _c(
          "div",
          { staticClass: "invite-link-container" },
          [
            _vm.isEnLang
              ? _c("span", [
                  _vm._v(
                    "Copy the link to invite friends to join the video call, one link can\n      invite only one person, the link will be updated automatically after\n      copying."
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。"
                  ),
                ]),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "invite-input",
                model: {
                  value: _vm.inviteLink,
                  callback: function ($$v) {
                    _vm.inviteLink = $$v
                  },
                  expression: "inviteLink",
                },
              },
              [
                _c(
                  "template",
                  { slot: "prepend" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          visibleArrow: false,
                          effect: "dark",
                          content: "Copied!",
                          placement: "bottom",
                          manual: true,
                        },
                        model: {
                          value: _vm.showCopiedTip,
                          callback: function ($$v) {
                            _vm.showCopiedTip = $$v
                          },
                          expression: "showCopiedTip",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "invite-btn",
                            on: { click: _vm.handleCopyInviteLink },
                          },
                          [_c("svg-icon", { attrs: { "icon-name": "copy" } })],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "info-container" }, [
      _vm.isHostMode
        ? _c(
            "div",
            { ref: "logContainer", staticClass: "log-container" },
            [
              _c("p", { staticClass: "log-label" }, [_vm._v("Log:")]),
              _vm._v(" "),
              _vm._l(_vm.logList, function (item, index) {
                return _c("div", { key: index }, [
                  item.type === "success"
                    ? _c("span", { staticClass: "log-state" }, [_vm._v("🟩 ")])
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "failed"
                    ? _c("span", { staticClass: "log-state" }, [_vm._v("🟥 ")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.log))]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.localStream
        ? _c("div", { staticClass: "local-stream-container" }, [
            _c("div", {
              staticClass: "local-stream-content",
              attrs: { id: "localStream" },
            }),
            _vm._v(" "),
            _vm.isPlayingLocalStream
              ? _c("div", { staticClass: "local-stream-control" }, [
                  _c("div", { staticClass: "video-control control" }, [
                    !_vm.isMutedVideo
                      ? _c(
                          "span",
                          { on: { click: _vm.muteVideo } },
                          [
                            _c("svg-icon", {
                              staticClass: "icon-class",
                              attrs: { "icon-name": "video" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMutedVideo
                      ? _c(
                          "span",
                          { on: { click: _vm.unmuteVideo } },
                          [
                            _c("svg-icon", {
                              staticClass: "icon-class",
                              attrs: { "icon-name": "video-muted" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "audio-control control" }, [
                    !_vm.isMutedAudio
                      ? _c(
                          "span",
                          { on: { click: _vm.muteAudio } },
                          [
                            _c("svg-icon", {
                              staticClass: "icon-class",
                              attrs: { "icon-name": "audio" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMutedAudio
                      ? _c(
                          "span",
                          { on: { click: _vm.unmuteAudio } },
                          [
                            _c("svg-icon", {
                              staticClass: "icon-class",
                              attrs: { "icon-name": "audio-muted" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "remote-container" },
      _vm._l(_vm.remoteStreamList, function (item) {
        return _c("div", {
          key: item.getUserId(),
          staticClass: "remote-stream-container",
          attrs: { id: item.getUserId() },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }