var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage global-page-base-layout" },
    [
      _c("Caseconditons", {
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "manage-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.supple(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("补充材料")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        (scope.row.state == 6 ? true : false)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fixt(scope.row)
                                  },
                                },
                              },
                              [_vm._v("裁决书补正")]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.mainTain(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("维护")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "supple-material",
          attrs: {
            title: "补充材料",
            visible: _vm.suppleMaterialVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.suppleMaterialVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提交人", prop: "submitter" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择提交人" },
                      model: {
                        value: _vm.ruleForm.submitter,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "submitter", $$v)
                        },
                        expression: "ruleForm.submitter",
                      },
                    },
                    _vm._l(_vm.submitterArr, function (item, index) {
                      return _c("el-option", {
                        attrs: {
                          label: item.name,
                          value: item.id,
                          id: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "材料名称", prop: "sumbitName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    model: {
                      value: _vm.ruleForm.sumbitName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sumbitName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sumbitName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "附件" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-50px",
                        color: "#F56C6C",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleSuccess,
                        "before-remove": _vm.beforeRemove,
                        headers: _vm.headers,
                        multiple: false,
                        limit: 1,
                        accept: ".png,.jpg,.pdf",
                        "on-exceed": _vm.handleExceed,
                        "before-upload": _vm.beforeUpload,
                        "file-list": _vm.materFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            单个文件不可以超过5M，支持PNG/JPG/PDF格式\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.materUploadTip
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#F56C6C",
                            "font-size": "10px",
                          },
                        },
                        [_vm._v("请完善必要信息和上传符合规范文件")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "party",
          attrs: {
            title: _vm.title + "当事人",
            visible: _vm.partyVisible,
            "close-on-click-modal": false,
            top: "5vh",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.partyVisible = $event
            },
          },
        },
        [
          _c(
            "el-main",
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addInit("partyRuleForm")
                        },
                      },
                    },
                    [_vm._v("添加当事人")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.partyLoading,
                          expression: "partyLoading",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "party-list" },
                        [
                          _c("span", { staticClass: "party-type" }, [
                            _vm._v("申请方"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.appParty, function (item, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "party-name",
                                class: index == _vm.appisActive ? "active" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseAppName(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.name) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "party-list" },
                        [
                          _c("span", { staticClass: "party-type" }, [
                            _vm._v("被申请方"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.resParty, function (item, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "party-name",
                                class: index == _vm.resisActive ? "active" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseResName(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.name) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticStyle: { "border-left": "1px solid #ccc" },
                  attrs: { span: 19 },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "partyRuleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.partyRuleForm,
                        rules: _vm.partyRules,
                        "label-position": "right",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "当事人类型", prop: "partyType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "partyform-item",
                              attrs: { placeholder: "请选择当事人类型" },
                              model: {
                                value: _vm.partyRuleForm.partyType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.partyRuleForm, "partyType", $$v)
                                },
                                expression: "partyRuleForm.partyType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "申请人", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "被申请人", value: "2" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "申请人代理人", value: "4" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "被申请人代理人", value: "5" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "企业/法人", prop: "partyIdentity" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "partyform-item",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.partyRuleForm.partyIdentity,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.partyRuleForm,
                                    "partyIdentity",
                                    $$v
                                  )
                                },
                                expression: "partyRuleForm.partyIdentity",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "企业", value: "0" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "个人", value: "1" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "partyName" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.partyName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "partyName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.partyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "partySex" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "partyform-item",
                              attrs: { placeholder: "请选择性别" },
                              model: {
                                value: _vm.partyRuleForm.partySex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.partyRuleForm, "partySex", $$v)
                                },
                                expression: "partyRuleForm.partySex",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "男", value: "0" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "女", value: "1" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件类型", prop: "DocType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "partyform-item",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.partyRuleForm.DocType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.partyRuleForm, "DocType", $$v)
                                },
                                expression: "partyRuleForm.DocType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "身份证", value: "3" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "律师证", value: "5" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "统一信用代码", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "其他证件", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件号码", prop: "DocNum" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.DocNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "DocNum",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.DocNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件地址", prop: "DocAddress" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.DocAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "DocAddress",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.DocAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "phone" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱地址", prop: "email" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人代表人", prop: "legalPerson" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.legalPerson,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "legalPerson",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.legalPerson",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人身份证", prop: "legalId" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.legalId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "legalId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.legalId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "职务", prop: "post" } },
                        [
                          _c("el-input", {
                            staticClass: "partyform-item",
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.partyRuleForm.post,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.partyRuleForm,
                                  "post",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "partyRuleForm.post",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件材料" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                position: "absolute",
                                left: "-78px",
                                color: "#F56C6C",
                              },
                            },
                            [_vm._v("*")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              ref: "partyUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.action,
                                "on-preview": _vm.partyHandlePreview,
                                "on-success": _vm.partyHandleSuccess,
                                "on-remove": _vm.partyHandleRemove,
                                "before-remove": _vm.partyBeforeRemove,
                                "before-upload": _vm.partyBeforeUpload,
                                headers: _vm.headers,
                                multiple: false,
                                limit: 3,
                                accept: ".jpg,.png",
                                "on-exceed": _vm.partyHandleExceed,
                                "file-list": _vm.partyFileList,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "\n                只能上传jpg/png/pdf文件，且不超过5M\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.uploadTip
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#F56C6C",
                                    "font-size": "10px",
                                  },
                                },
                                [_vm._v("请完善必要信息和上传符合规范文件")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitPartyForm("partyRuleForm")
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "裁决书补正",
            visible: _vm.upcomingVisible,
            top: "0vh",
            fullscreen: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.upcomingVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "50%", margin: "0 auto" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "padding-right": "100px",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-header" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dataContentForm.subject) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-subject" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dataContentForm.title) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-caseno" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dataContentForm.caseNo) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.dataContent, function (item, index) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "rule-input",
                          attrs: {
                            type: "textarea",
                            autosize: "",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: item.text,
                            callback: function ($$v) {
                              _vm.$set(item, "text", $$v)
                            },
                            expression: "item.text",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [_vm._v("[删除]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdd(index)
                                  },
                                },
                              },
                              [_vm._v("[新增]")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-right",
              staticStyle: { "padding-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upcomingVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingSave },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("1")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补正",
            visible: _vm.selectVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.selectType,
                    callback: function ($$v) {
                      _vm.selectType = $$v
                    },
                    expression: "selectType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("编辑补正书"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("上传补正书"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectShow,
                      expression: "selectShow",
                    },
                  ],
                  staticStyle: { padding: "10px" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-50px",
                        color: "#F56C6C",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        "on-preview": _vm.docHandlePreview,
                        "on-remove": _vm.docHandleRemove,
                        "on-success": _vm.docHandleSuccess,
                        "before-remove": _vm.docBeforeRemove,
                        headers: _vm.headers,
                        multiple: false,
                        limit: 1,
                        accept: ".docx,.doc",
                        "on-exceed": _vm.docHandleExceed,
                        "before-upload": _vm.docBeforeUpload,
                        "file-list": _vm.docFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("\n            支持word格式\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-right",
              staticStyle: { "padding-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingUpload },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpload("1")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }