"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.typed.uint8-array");
var _validate = require("@/utils/validate");
var _user = require("@/api/user");
var _settings = _interopRequireDefault(require("@/settings"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('请输入正确的用户名'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('请输入至少6位的密码'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        // account: 'mishu001/admin/mishu002',
        // password: '888888'
        account: '',
        password: '',
        code: ''
      },
      loginPhoneForm: {
        mobile: '',
        verifyNum: '',
        verifyPic: ''
      },
      accountLoginShow: true,
      loginRules: {
        // account: [{ required: true, trigger: 'blur', validator: validateUsername }],
        // password: [{ required: true, trigger: 'blur', validator: validatePassword }]
        account: [{
          required: true,
          message: '账号不能为空',
          trigger: 'blur'
        }, {
          min: 5,
          max: 30,
          message: '账号长度5-30位数',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          max: 30,
          message: '名称长度6-30位数',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          max: 6,
          message: '验证码6位数',
          trigger: 'blur'
        }]
      },
      loginPhoneRules: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '手机号长度11位数',
          trigger: 'blur'
        }],
        verifyPic: [{
          required: true,
          message: '图形验证码不能为空',
          trigger: 'blur'
        }, {
          min: 5,
          max: 5,
          message: '图形验证码长度5位数',
          trigger: 'blur'
        }],
        verifyNum: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          max: 6,
          message: '验证码长度6位数',
          trigger: 'blur'
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      vImg: '',
      sendMsgDisabled: false,
      smsDisabled: false,
      codeTime: 90,
      codeInterval: '',
      canSendMessage: false,
      //判断电话号码存在的情况才可以允许发验证码
      title: _settings.default.title,
      logo: _settings.default.logo
    };
  },
  created: function created() {
    this.getCheckImg();
  },
  watch: {
    $route: {
      handler: function handler(route) {
        // this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    gotoSome: function gotoSome() {
      this.$router.push({
        path: '/chainIndex'
      });
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    //获取验证码图片
    getCheckImg: function getCheckImg() {
      var _this2 = this;
      (0, _user.getPicture)({
        t: new Date().getTime()
      }).then(function (res) {
        _this2.vImg = 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));
      });
    },
    //手机号登陆
    phoneLogin: function phoneLogin() {
      this.accountLoginShow = false;
      if (this.$refs['loginPhoneForm']) {
        this.$refs['loginPhoneForm'].resetFields();
      }
    },
    //账号密码登陆
    accountLogin: function accountLogin() {
      this.accountLoginShow = true;
      if (this.$refs['loginForm']) {
        this.$refs['loginForm'].resetFields();
      }
    },
    stop: function stop() {
      this.codeTime = 90;
      this.sendMsgDisabled = false;
      this.smsDisabled = false;
      clearInterval(this.codeInterval);
    },
    count: function count() {
      this.codeTime--;
      if (this.codeTime <= 0) {
        this.stop();
      }
    },
    //点击获取验证码
    handleGetSmsCode: function handleGetSmsCode() {
      var _this3 = this;
      if (this.loginPhoneForm.mobile == '') {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'warning'
        });
        return;
      }
      if (this.loginPhoneForm.verifyPic == '') {
        this.$message({
          message: '请输入图形验证码',
          type: 'warning'
        });
        return;
      }
      var param = {
        imgCode: this.loginPhoneForm.verifyPic,
        mobile: this.loginPhoneForm.mobile,
        commId: _settings.default.commId
      };
      (0, _user.sendMess)(param).then(function (res) {
        if (res.code === 1) {
          _this3.$message({
            message: '发送成功',
            type: 'success'
          });
          _this3.sendMsgDisabled = true;
          _this3.smsDisabled = true;
          _this3.codeInterval = setInterval(_this3.count, 1000);
        } else {
          _this3.$message.error(res.msg);
          _this3.stop();
          _this3.getCheckImg();
        }
      });
    },
    //点击获取验证码
    handleSmsCode: function handleSmsCode() {
      var _this4 = this;
      if (this.loginForm.account == '') {
        this.$message({
          message: '请输入正确的账号',
          type: 'warning'
        });
        return;
      }
      var param = {
        account: this.loginForm.account
      };
      (0, _user.sendMess)(param).then(function (res) {
        if (res.code === 1) {
          _this4.$message({
            message: '发送成功',
            type: 'success'
          });
          _this4.sendMsgDisabled = true;
          _this4.smsDisabled = true;
          _this4.codeInterval = setInterval(_this4.count, 1000);
        } else {
          _this4.$message.error(res.msg);
          _this4.stop();
        }
      });
    },
    // 账号密码的登录按钮
    handleLogin: function handleLogin() {
      var _this5 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          _this5.$store.dispatch('user/login', _this5.loginForm).then(function (res) {
            _this5.$router.push({
              path: _this5.redirect || '/'
            });
            _this5.loading = false;
            // localStorage.setItem('menusList', JSON.stringify(res.menusList));
            // 菜单设置
          }).catch(function (err) {
            if (err.msg) {
              _this5.$message.error(err.msg);
            }
            _this5.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 手机登录的按钮
    handlePhoneLogin: function handlePhoneLogin() {
      var _this6 = this;
      this.$refs.loginPhoneForm.validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$store.dispatch('user/phoneLogin', _this6.loginPhoneForm).then(function (res) {
            _this6.$router.push({
              path: _this6.redirect || '/'
            });
            _this6.loading = false;
          }).catch(function (err) {
            if (err.msg) {
              _this6.$message.error(err.msg);
            }
            _this6.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;