"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAdmin = addAdmin;
exports.addRolePermission = addRolePermission;
exports.deleteAdmin = deleteAdmin;
exports.deleteRole = deleteRole;
exports.getAdminList = getAdminList;
exports.getCommList = getCommList;
exports.getRoleList = getRoleList;
exports.inquiryAdmin = inquiryAdmin;
exports.inquiryAdminRole = inquiryAdminRole;
exports.inquiryRolePermission = inquiryRolePermission;
exports.updateAdmin = updateAdmin;
exports.updateRolePermission = updateRolePermission;
var _request = _interopRequireDefault(require("@/utils/request"));
function getRoleList() {
  return (0, _request.default)({
    url: '/back/role/all',
    method: 'get'
  });
}
function getAdminList(data) {
  if (data.roleId) {
    return (0, _request.default)({
      url: "/back/admin/page/?limit=".concat(data.pageSize, "&offset=").concat((data.page - 1) * 10, "&roleId=").concat(data.roleId),
      method: 'get'
    });
  } else {
    return (0, _request.default)({
      url: "/back/admin/page/?limit=".concat(data.pageSize, "&offset=").concat((data.page - 1) * 10),
      method: 'get'
    });
  }
}
function getCommList() {
  return (0, _request.default)({
    url: '/back/commissionInfo/getAllCommission',
    method: 'get'
  });
}
function addAdmin(data) {
  return (0, _request.default)({
    url: '/back/admin',
    method: 'post',
    data: data
  });
}
function deleteAdmin(data) {
  return (0, _request.default)({
    url: '/back/admin/deletes',
    method: 'post',
    data: data
  });
}
function inquiryAdmin(data) {
  return (0, _request.default)({
    url: '/back/admin/getUserById/' + data,
    method: 'get'
  });
}
function updateAdmin(data) {
  return (0, _request.default)({
    url: '/back/admin',
    method: 'put',
    data: data
  });
}

// 查询用户已有权限和可选权限
function inquiryAdminRole(data) {
  return (0, _request.default)({
    url: "/back/admin/loadRole?adminId=".concat(data),
    method: 'get'
  });
}
function deleteRole(data) {
  return (0, _request.default)({
    url: "/back/role/".concat(data),
    method: 'delete'
  });
}

// 查询角色菜单权限
function inquiryRolePermission(data) {
  if (data) {
    return (0, _request.default)({
      url: "/back/role/loadMenu?roleId=".concat(data),
      method: 'get'
    });
  } else {
    return (0, _request.default)({
      url: "/back/role/loadMenu",
      method: 'get'
    });
  }
}

// 添加角色菜单权限
function addRolePermission(data) {
  return (0, _request.default)({
    url: "/back/role",
    method: 'post',
    data: data
  });
}

// 修改角色菜单权限
function updateRolePermission(data) {
  return (0, _request.default)({
    url: "/back/role",
    method: 'put',
    data: data
  });
}