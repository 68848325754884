var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block-chain" },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "txt" }, [_vm._v("互仲链·区块链查询")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "el-input",
              {
                staticClass: "input-with-select",
                attrs: { placeholder: "请输入内容" },
                on: { focus: _vm.gotoDetail },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "block-chain-content",
          staticStyle: { background: "#404A59" },
        },
        [
          _c(
            "el-main",
            [
              _c(
                "el-col",
                {
                  staticClass: "map-box",
                  staticStyle: { background: "#404A59" },
                  attrs: { span: 18 },
                },
                [_c("div", { attrs: { id: "map" } })]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { background: "#404A59" }, attrs: { span: 6 } },
                [
                  _vm.clientWidth > 1450
                    ? _c(
                        "div",
                        { staticClass: "content-right" },
                        [
                          _c(
                            "el-carousel",
                            {
                              attrs: {
                                height: "500px",
                                direction: "vertical",
                                interval: 5000,
                                "indicator-position": "none",
                                loop: true,
                                autoplay: true,
                              },
                            },
                            _vm._l(_vm.list, function (item, index) {
                              return _c("el-carousel-item", { key: index }, [
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全主体名称"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.entityName))]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全备案号"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(item.preservationId)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("摘要序列"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.digestSeq))]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全 HASH 摘要"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.digestValue))]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全哈希"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.docHash))]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全时间戳"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(item.preservationTime)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "txt" }, [
                                  _c("div", { staticClass: "txt-title" }, [
                                    _vm._v("保全来源"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.sourceName))]),
                                ]),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.clientWidth <= 1450
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-carousel",
                {
                  attrs: {
                    height: "200px",
                    interval: 5000,
                    "indicator-position": "none",
                    loop: true,
                    autoplay: true,
                  },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "el-carousel-item",
                    { key: index },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全主体名称："),
                          _c("span", [_vm._v(_vm._s(item.entityName))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全哈希：\n              "),
                          _c("span", [_vm._v(_vm._s(item.docHash))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全备案号：\n              "),
                          _c("span", [_vm._v(_vm._s(item.preservationId))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全时间戳：\n              "),
                          _c("span", [_vm._v(_vm._s(item.preservationTime))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("摘要序列：\n              "),
                          _c("span", [_vm._v(_vm._s(item.digestSeq))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全来源：\n              "),
                          _c("span", [_vm._v(_vm._s(item.sourceName))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c("div", { staticClass: "row-txt" }, [
                          _vm._v("保全 HASH 摘要：\n              "),
                          _c("span", [_vm._v(_vm._s(item.digestValue))]),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "btn",
          attrs: { round: "" },
          on: { click: _vm.gotoIndex },
        },
        [_vm._v("返回首页")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }