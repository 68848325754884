"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addStaffAllot = addStaffAllot;
exports.allotByWeight = allotByWeight;
exports.allotCase = allotCase;
exports.commChoiceArbitrator = commChoiceArbitrator;
exports.commUserPage = commUserPage;
exports.delCourtInfo = delCourtInfo;
exports.deleteStaffAllot = deleteStaffAllot;
exports.findAssignableSecretaryList = findAssignableSecretaryList;
exports.findSecretaryList = findSecretaryList;
exports.findStaffAllotList = findStaffAllotList;
exports.findTransferLogPage = findTransferLogPage;
exports.findUnAllotCasePage = findUnAllotCasePage;
exports.getCourtInfoPage = getCourtInfoPage;
exports.insertCourtInfo = insertCourtInfo;
exports.manualAllotCase = manualAllotCase;
exports.remindTodoConfigList = remindTodoConfigList;
exports.updateProcessNode = updateProcessNode;
exports.updateStaffAllot = updateStaffAllot;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询仲裁庭列表
function getCourtInfoPage(data) {
  return (0, _request.default)({
    url: '/back/court/info/courtInfoPage',
    method: 'post',
    data: data
  });
}

// 新增/修改开庭地址
function insertCourtInfo(data) {
  return (0, _request.default)({
    url: '/back/court/info/insertCourtInfo',
    method: 'post',
    data: data
  });
}

// 删除开庭地址
function delCourtInfo(data) {
  return (0, _request.default)({
    url: '/back/court/info/delCourtInfo',
    method: 'post',
    data: data
  });
}

// 仲裁委选择仲裁员
function commChoiceArbitrator(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitrator/commChoiceArbitrator',
    method: 'post',
    data: data
  });
}

// 人员列表
function commUserPage(data) {
  return (0, _request.default)({
    url: '/back/admin/commUserPage',
    method: 'post',
    data: data
  });
}

// 查询转移记录
function findTransferLogPage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseTransferLog/findTransferLogPage',
    method: 'post',
    data: data
  });
}

// 分配待转移案件
function allotCase(data) {
  return (0, _request.default)({
    url: '/batchCase/caseTransferLog/allotCase',
    method: 'post',
    data: data
  });
}

// 查询所属仲裁委的秘书列表
function findSecretaryList() {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findSecretaryList',
    method: 'get'
  });
}

// 查询分配人员列表
function findStaffAllotList(data) {
  return (0, _request.default)({
    url: '/back/commStaffAllotConfig/findStaffAllotList',
    method: 'post',
    data: data
  });
}

// 修改分配用户比例
function updateStaffAllot(data) {
  return (0, _request.default)({
    url: '/back/commStaffAllotConfig/update',
    method: 'post',
    data: data
  });
}

// 删除分配人员
function deleteStaffAllot(params) {
  return (0, _request.default)({
    url: '/back/commStaffAllotConfig/delete/' + params,
    method: 'get'
  });
}

// 添加分配人员
function addStaffAllot(data) {
  return (0, _request.default)({
    url: '/back/commStaffAllotConfig/add',
    method: 'post',
    data: data
  });
}

// 分页查询待分配的案件列表
function findUnAllotCasePage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findUnAllotCasePage',
    method: 'post',
    data: data
  });
}

// 仲裁委手动分配案件
function manualAllotCase(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/manualAllotCase',
    method: 'post',
    data: data
  });
}

// 仲裁委自动分配案件
function allotByWeight() {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/allotByWeight',
    method: 'get'
  });
}

// 查询可分配的秘书列表
function findAssignableSecretaryList() {
  return (0, _request.default)({
    url: '/back/commStaffAllotConfig/findAssignableSecretaryList',
    method: 'get'
  });
}

// 查询提醒时间配置列表
function remindTodoConfigList() {
  return (0, _request.default)({
    url: '/activiti/tProcessNode/remindTodoConfigList',
    method: 'get'
  });
}

// 修改提醒时间配置
function updateProcessNode(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessNode/updateProcessNode',
    method: 'post',
    data: data
  });
}