var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "addProcess",
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        _vm._l(_vm.arr, function (item, index) {
          return _c(
            "div",
            {
              staticClass: "header-item",
              on: {
                click: function ($event) {
                  return _vm.currentEdit(index)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "header-icon",
                  class: { active: _vm.isActive == index },
                },
                [_vm._v(_vm._s(index + 1))]
              ),
              _vm._v(_vm._s(item) + "\n    "),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive == 0,
              expression: "isActive==0",
            },
          ],
          staticClass: "basicInformation",
        },
        [
          _c("div", { staticClass: "basicInformation-header" }, [
            _vm._v("工作流基本信息"),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工作流名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工作流说明", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "desc", $$v)
                      },
                      expression: "ruleForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择仲裁委", prop: "region" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择仲裁委" },
                      on: {
                        change: function ($event) {
                          return _vm.getCurrentCommiteeId(_vm.ruleForm.region)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.region,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "region", $$v)
                        },
                        expression: "ruleForm.region",
                      },
                    },
                    _vm._l(_vm.committeeArr, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择初始审核人", prop: "reviewId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选选择初始审核人" },
                      model: {
                        value: _vm.ruleForm.reviewId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "reviewId", $$v)
                        },
                        expression: "ruleForm.reviewId",
                      },
                    },
                    _vm._l(_vm.auditorList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "自动分配是否开启", prop: "autoStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.autoStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "autoStatus", $$v)
                        },
                        expression: "ruleForm.autoStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "启用时间限制", prop: "timeLimitStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.timeLimitStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "timeLimitStatus", $$v)
                        },
                        expression: "ruleForm.timeLimitStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "流程状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.laodingBtn1 },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存&下一步")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive == 1,
              expression: "isActive==1",
            },
          ],
          staticClass: "stateDefine",
        },
        [
          _c("div", { staticClass: "stateDefine-header" }, [
            _vm._v("状态定义"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "stateDefine-tip" }, [
            _vm._v("支持添加、编辑和删除工作流状态。"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px" },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addStatus },
            },
            [_vm._v("添加状态")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "dragTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "状态别名" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "content", label: "状态说明" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "startStatus",
                  label: "起始状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            nativeOn: {
                              change: function ($event) {
                                return _vm.getCurrentRow(
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                            model: {
                              value: scope.row.startStatus,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "startStatus", $$v)
                              },
                              expression: "scope.row.startStatus",
                            },
                          },
                          [_vm._v("\n             \n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "endStatus",
                  label: "结束状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": "1", "false-label": "0" },
                            model: {
                              value: scope.row.endStatus,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "endStatus", $$v)
                              },
                              expression: "scope.row.endStatus",
                            },
                          },
                          [_vm._v(" ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remindDay", label: "办结时间(天)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm.isActive == 1
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "medium" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editStatus(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-edit-outline",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "medium" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.clearStatus(
                                        scope.$index,
                                        _vm.tableData
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "stateDefine-tip" }, [
            _vm._v(
              "注：拖动行可以调整状态顺序哦。(状态的顺序会体现在流转时状态的展现和列表排序中)"
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px" },
              attrs: { type: "primary", loading: _vm.laodingBtn2 },
              on: {
                click: function ($event) {
                  return _vm.nextTo3()
                },
              },
            },
            [_vm._v("保存&下一步")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "statusPopup",
          attrs: {
            title: _vm.statusTitle,
            visible: _vm.statusVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.statusVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "statusForm", attrs: { model: _vm.statusform } },
            [
              _vm.statusTitle == "添加状态"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态原名",
                        "label-width": "100px",
                        prop: "originName",
                        rules: { required: true, message: "状态原名不能为空" },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.statusOriginChange(
                                _vm.statusform.originName
                              )
                            },
                          },
                          model: {
                            value: _vm.statusform.originName,
                            callback: function ($$v) {
                              _vm.$set(_vm.statusform, "originName", $$v)
                            },
                            expression: "statusform.originName",
                          },
                        },
                        _vm._l(_vm.statusList, function (item, index) {
                          return _c("el-option", {
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "status-tip" }, [
                        _vm._v("状态原名是状态的唯一标识。"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态别名",
                    "label-width": "100px",
                    prop: "alias",
                    rules: { required: true, message: "状态别名不能为空" },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.statusform.alias,
                      callback: function ($$v) {
                        _vm.$set(_vm.statusform, "alias", $$v)
                      },
                      expression: "statusform.alias",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "status-tip" }, [
                    _vm._v("状态别名是显示在页面上的名字，最多输入28个字符。"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "办结时间",
                    "label-width": "100px",
                    prop: "alias",
                    rules: { required: true, message: "办结时间不能为空" },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", autocomplete: "off" },
                    model: {
                      value: _vm.statusform.remindDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.statusform, "remindDay", $$v)
                      },
                      expression: "statusform.remindDay",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "status-tip" }, [
                    _vm._v(
                      "节点处理最多几天，超时进行提醒，默认为0时不进行提醒。"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态说明", "label-width": "100px" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "最多可输入512个字符",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.statusform.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.statusform, "desc", $$v)
                      },
                      expression: "statusform.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.restStatusForm("statusForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sumbitStatusForm("statusForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive == 2,
              expression: "isActive==2",
            },
          ],
          staticClass: "transfer-settings",
        },
        [
          _c("div", { staticClass: "transfer-settings-header" }, [
            _vm._v("流转设置"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "transfer-settings-tip" }, [
            _vm._v(
              "工作流流转设置，是设置工作流各状态间的先后流转关系。如果需要设置该流转，请在两个状态间的复选框内打勾。"
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "transferTable",
              attrs: {
                data: _vm.transferTableData,
                "row-key": "uid",
                align: "left",
              },
            },
            [
              _c("el-table-column", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isActive == 2,
                    expression: "isActive==2",
                  },
                ],
                attrs: { prop: "name" },
              }),
              _vm._v(" "),
              _vm._l(_vm.transferCol, function (item, index) {
                return _vm.isActive == 2
                  ? _c("el-table-column", {
                      key: index,
                      attrs: { label: item.label },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  key: index,
                                  attrs: { disabled: scope.$index == index },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        scope.row.checked[index],
                                        scope.row,
                                        item
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.checked[index],
                                    callback: function ($$v) {
                                      _vm.$set(scope.row.checked, index, $$v)
                                    },
                                    expression: "scope.row.checked[index]",
                                  },
                                }),
                                _vm._v(" "),
                                scope.row.checked[index] ||
                                scope.$index == index
                                  ? _c("i", {
                                      staticClass: "el-icon-setting",
                                      staticStyle: {
                                        "font-size": "16px",
                                        "margin-left": "5px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editTansferData(
                                            scope.row,
                                            item
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "transfer-settings-tip" }, [
            _vm._v("点击图标，可以设置流转的附加字段及授权用户。"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.sumbitTransferSet },
            },
            [_vm._v("保存&返回列表")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "transfer-popup",
          attrs: {
            title: "编辑流转设置",
            visible: _vm.transferPopupVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.transferPopupVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "transferForm",
              staticClass: "transferForm",
              attrs: { model: _vm.transferForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核组" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.auditor,
                            callback: function ($$v) {
                              _vm.auditor = $$v
                            },
                            expression: "auditor",
                          },
                        },
                        _vm._l(_vm.auditorList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { color: "#999" } }, [
                        _vm._v("可审核用户"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.auditorRole,
                            callback: function ($$v) {
                              _vm.auditorRole = $$v
                            },
                            expression: "auditorRole",
                          },
                        },
                        _vm._l(_vm.auditorRoleList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { color: "#999" } }, [
                        _vm._v("可审核角色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "默认审批语句", prop: "Sentence" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.transferForm.Sentence,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferForm, "Sentence", $$v)
                      },
                      expression: "transferForm.Sentence",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.transferPopupVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sumbitTransferForm("transferForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }