"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
var _send = _interopRequireDefault(require("../service/send"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'suppleMaterial',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      caseDealing: _dictionary.dictionary.caseDealing,
      fileVisble: false,
      MaterialData: [],
      fileLoading: false,
      num: '',
      reviewVisible: false,
      //审核弹窗
      rejectVisible: false,
      //驳回弹窗
      reviewState: {
        '0': '未审核',
        '1': '审核通过',
        '-1': '审核不通过'
      },
      state: '',
      stateArr: [{
        value: '0',
        label: '未审核'
      }, {
        value: '1',
        label: '审核通过'
      }, {
        value: '-1',
        label: '审核不通过'
      }],
      reviewForm: {},
      rejectForm: {
        id: '',
        reason: ''
      },
      id: '',
      clickState: '',
      btnLoading: false,
      sendCaseVisible: false
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;
      (0, _inquiry.findArbClaimChangePage)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.loading = false;
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //表格 审核按钮
    handleReview: function handleReview(data) {
      var _this2 = this;
      this.id = data.id;
      this.caseId = data.caseId;
      this.clickState = data.state;
      this.reviewVisible = true;
      (0, _inquiry.findArbClaimChange)(this.id).then(function (res) {
        if (res.code == 1) {
          _this2.reviewForm = res.data;
        } else {
          _this2.$message.error(res.msg);
        }
      });
    },
    // 通过
    handlePass: function handlePass(val) {
      var _this3 = this;
      var reviewData = {
        reviewState: '1',
        reason: ''
      };
      this.btnLoading = true;
      this.$nextTick(function () {
        _this3.$refs.sendCase.init(_this3.caseId, 10, 'changeRequest', reviewData);
      });
    },
    updateShow: function updateShow() {
      this.btnLoading = false;
      this.sendCaseVisible = true;
    },
    updateErrorShow: function updateErrorShow() {
      this.btnLoading = false;
      this.sendCaseVisible = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.reviewVisible = false;
      this.rejectVisible = false;
      this.init();
    },
    // 拒绝
    handleReject: function handleReject(val) {
      this.rejectVisible = true;
    },
    // 拒绝 -提交
    handleRejectSubmit: function handleRejectSubmit(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var reviewData = {
            reviewState: '-1',
            reason: _this4.rejectForm.reason
          };
          _this4.btnLoading = true;
          _this4.$nextTick(function () {
            _this4.$refs.sendCase.init(_this4.caseId, 10, 'changeRequest', reviewData);
          });
        } else {
          return false;
        }
      });
    },
    //点击筛选，发送请求列表
    screen: function screen(data) {
      var _this5 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        state: this.state,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.findArbClaimChangePage)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this5.tableData = res.rows;
          _this5.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.state = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.pageLimit = val;
      if (this.num) {
        // 有筛选条件的情况下
        (0, _inquiry.findArbClaimChangePage)({
          state: this.state,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findArbClaimChangePage)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this7 = this;
      if (this.num) {
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        // 有筛选条件的情况下
        (0, _inquiry.findArbClaimChangePage)({
          state: this.state,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findArbClaimChangePage)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;