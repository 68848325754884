var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inquiry global-page-base-layout" },
    [
      _c("Caseconditons", {
        ref: "Caseconditons",
        attrs: { caseReplyCondition: false, casePayCondition: true },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.tableData.length <= 0 },
              on: { click: _vm.btnExport },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inquiry-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c("span", [_vm._v(_vm._s(scope.row.caseNo))])
                          : _c("span", [_vm._v("暂无案号")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "标的额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "payVerify", label: "费用核实" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "网络案号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "受理文书送达时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeSendTime", label: "组庭通知送达时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "courtSendTime", label: "开庭通知送达时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "courtTime", label: "开庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "results", label: "处理结果" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }