"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inquiry = require("@/api/inquiry");
var _loading = require("@/utils/loading");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  props: {},
  data: function data() {
    return {
      partyInfoVisble: false,
      loading: true,
      ruleForm: {},
      rules: {
        // type: [
        //     {  required: true, message: '请选择', trigger: 'change' }
        // ],
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // this.init();
  },
  methods: {
    init: function init(data) {
      var _this = this;
      (0, _loading.showFullScreenLoading)('.partyInfo-main');
      this.partyInfoVisble = true;
      (0, _inquiry.getPartyInfo)(data).then(function (res) {
        if (res.code == 1) {
          (0, _loading.hideFullScreenLoading)('.partyInfo-main');
          _this.ruleForm = res.data;
          _this.ruleForm.partyType = res.data.partyType + '';
          _this.ruleForm.type = res.data.type + '';
          _this.ruleForm.idtype = res.data.idtype + '';
        } else {}
      }).catch(function (error) {});
    },
    submitForm: function submitForm(formName) {
      this.partyInfoVisble = false;
    }
  }
};
exports.default = _default;