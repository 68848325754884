"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry.js");
var _trial = require("@/api/trial.js");
var _court = require("@/api/court.js");
var _dictionary = require("@/config/dictionary");
var _send = _interopRequireDefault(require("../../case/service/send"));
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "holdcourt",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: "",
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      wayList: [{
        name: "书面审理",
        id: "1"
      }, {
        name: "线下开庭",
        id: "2"
      }, {
        name: "视频开庭",
        id: "3"
      }],
      courtVedioList: [],
      holdcourtLotVisible: false,
      //案件组庭批量弹窗显示
      holdcourtLotForm: {
        courtType: "1",
        courtAddress: "",
        courtTime: "",
        date: "",
        moment: ""
      },
      holdcourtLotRules: {
        courtTime: [{
          required: true,
          message: "请选择开庭时间",
          trigger: "change"
        }],
        courtAddress: [{
          required: true,
          message: "请选择开庭地址",
          trigger: "change"
        }]
      },
      holdcourtVisible: false,
      //案件组庭弹窗显示
      holdcourtForm: {
        //案件组庭数据
        caseId: "",
        courtAddress: "",
        courtTime: "",
        date: "",
        moment: "",
        courtType: "1",
        conferenceAisle: [],
        courtId: ""
      },
      holdcourtRules: {
        courtTime: [{
          required: true,
          message: "请选择开庭时间",
          trigger: "change"
        }],
        CourtAddress: [{
          required: true,
          message: "请选择开庭地址",
          trigger: "change"
        }],
        courtId: [{
          required: true,
          message: "请选择会议通道",
          trigger: "change"
        }]
      },
      holdcourtInnerVisible: false,
      //添加开庭地址弹窗显示
      caeAddress: "",
      //添加开庭地址数据
      courtList: [],
      addAddressForm: {
        name: "",
        address: "",
        tel: ""
      },
      addAddressRules: {
        name: [{
          required: true,
          message: "请填写开庭名称",
          trigger: "blur"
        }, {
          min: 1,
          max: 20,
          message: "开庭名称长度1-20位数",
          trigger: "blur"
        }],
        address: [{
          required: true,
          message: "请填写开庭地址",
          trigger: "blur"
        }, {
          min: 1,
          max: 20,
          message: "开庭地址长度1-255位数",
          trigger: "blur"
        }],
        tel: [{
          required: true,
          message: "请填写联系方式",
          trigger: "blur"
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // return time.getTime() < Date.now();
        }
      },
      sendCaseVisible: false,
      //案件送达
      gotoServicing: false,
      //案件开庭弹窗  按钮loading
      caseNum: ""
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _inquiry.findCaseCourtListByPage)({
        states: [3, 4],
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });

      //channelPage
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginComposeTime: data.rowThirdDate[0],
        endComposeTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1,
        states: [3, 4]
      };
      this.conditionData = postData;
      (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //仲裁庭列表
    getCourtList: function getCourtList() {
      var _this3 = this;
      (0, _inquiry.getCourtList)().then(function (res) {
        if (res.code == 1) {
          _this3.courtList = res.data;
        }
      });
    },
    //获取视频会议通道列表
    getcourtVedioList: function getcourtVedioList() {
      var _this4 = this;
      (0, _trial.channelList)({
        limit: 1000,
        page: 1
      }).then(function (res) {
        if (res.code == 1) {
          _this4.courtVedioList = [];
          // for(let item of res.data){
          //   this.courtVedioList.push({
          //     name: ""
          //   });
          // }

          _this4.courtVedioList = res.data;
        }
      });
    },
    //表格 审核按钮
    handleReview: function handleReview(data) {
      this.caseId = data.caseId;
      this.holdcourtVisible = true;
      this.getCourtList();
      this.getcourtVedioList();
    },
    //添加开庭地址
    handleAddAddress: function handleAddAddress(addAddressForm) {
      var _this5 = this;
      this.$refs[addAddressForm].validate(function (valid) {
        if (valid) {
          (0, _inquiry.insertCourtInfo)(_this5.addAddressForm).then(function (res) {
            if (res.code == 1) {
              _this5.$message({
                message: "添加开庭地址成功",
                type: "success"
              });
              _this5.holdcourtInnerVisible = false;
              _this5.getCourtList();
              _this5.getcourtVedioList();
            } else {
              _this5.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    updateShow: function updateShow() {
      this.holdcourtVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //弹窗确认按钮
    gotoService: function gotoService(divisionForm) {
      var _this6 = this;
      this.$refs[divisionForm].validate(function (valid) {
        if (valid) {
          _this6.gotoServicing = true;
          _this6.holdcourtForm.caseId = _this6.caseId;
          if (_this6.holdcourtForm.date == "") {
            _this6.$message.error("请选择开庭日期");
          } else if (_this6.holdcourtForm.moment == "") {
            _this6.$message.error("请选择开庭时间");
          } else if (_this6.holdcourtForm.courtType == "3" && _this6.holdcourtForm.courtId == "") {
            _this6.$message.error("请选择会议通道");
          } else {
            _this6.holdcourtForm.courtTime = _this6.holdcourtForm.date + " " + _this6.holdcourtForm.moment;
            // this.loading = true;
            if (_this6.holdcourtForm.courtType == 3) {
              var trialItem = _this6.courtVedioList.find(function (ele) {
                return ele.id === _this6.holdcourtForm.courtId;
              });
              // this.holdcourtForm.conferenceAisle = trialItem;
              _this6.holdcourtForm.courtAddress = trialItem.address;
            }
            (0, _inquiry.choiceCourtAddress)(_this6.holdcourtForm).then(function (res) {
              _this6.loading = false;
              if (res.code == 1) {
                _this6.sendCaseVisible = true;
                _this6.$nextTick(function () {
                  _this6.$refs.sendCase.init(_this6.caseId, 3);
                });
              } else {
                _this6.gotoServicing = false;
                _this6.$message.error(res.msg);
              }
            }).catch(function (error) {
              _this6.gotoServicing = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this7 = this;
      this.multipleSelectionId = [];
      this.multipleSelection.forEach(function (item) {
        _this7.multipleSelectionId.push(item.caseId);
      });
      this.caseNum = this.multipleSelectionId.length;
      this.holdcourtLotVisible = true;
      this.getCourtList();
      this.getcourtVedioList();
    },
    batchReviewSubmit: function batchReviewSubmit(holdcourtLotForm) {
      var _this8 = this;
      this.$refs[holdcourtLotForm].validate(function (valid) {
        if (valid) {
          if (_this8.holdcourtLotForm.date == "") {
            _this8.$message.error("请选择开庭日期");
          } else if (_this8.holdcourtLotForm.moment == "") {
            _this8.$message.error("请选择开庭时间");
          } else {
            _this8.holdcourtLotForm.courtTime = _this8.holdcourtLotForm.date + " " + _this8.holdcourtLotForm.moment;
            _this8.loading = true;
            (0, _inquiry.batchTask)({
              caseIds: _this8.multipleSelectionId,
              caseState: 25,
              content: JSON.stringify(_this8.holdcourtLotForm)
            }).then(function (res) {
              _this8.loading = false;
              if (res.code == 1) {
                _this8.sumbiting = false;
                _this8.$message({
                  message: '批量操作成功',
                  type: 'success'
                });
                _this8.holdcourtLotVisible = false;
                _this8.init();
              } else {
                _this8.$message.error(res.msg);
              }
            });

            // batchSendCourt(this.holdcourtLotForm)
            //   .then(res => {
            //     this.loading = false;
            //     if (res.code == 1) {
            //       this.$message({ message: "批量审核成功", type: "success" });
            //       this.holdcourtLotVisible = false;
            //       this.init();
            //     } else {
            //       this.$message.error(res.msg);
            //     }
            //   })
            //   .catch(error => {});
          }
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this9 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this10 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;