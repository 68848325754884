"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry.js");
var _dictionary = require("@/config/dictionary");
var _send = _interopRequireDefault(require("../service/send"));
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'division',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      total: 0,
      //总页数
      conditionData: '',
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      divisionLotVisible: false,
      //案件组庭批量弹窗显示
      divisionLotForm: {
        //案件组庭批量数据
        chiefArbitratorId: "",
        chiefArbitratorName: ''
      },
      divisionLotRules: {
        chiefArbitratorId: [{
          required: true,
          message: '请选择首席仲裁员',
          trigger: 'change'
        }]
      },
      divisionVisible: false,
      //案件组庭弹窗显示
      divisionForm: {
        //案件组庭数据
        chiefArbitratorId: "",
        chiefArbitratorName: '',
        secondArbitratorId: '',
        secondArbitratorName: '',
        thirdArbitratorId: '',
        thirdArbitratorName: ''
      },
      divisionRules: {
        chiefArbitratorId: [{
          required: true,
          message: '请选择首席仲裁员',
          trigger: 'change'
        }]
      },
      caseId: '',
      caseNum: '',
      arbitratorList: [],
      partyChoiceArb: {
        appArbitratorName: '',
        resArbitratorName: ''
      },
      sendCaseVisible: false,
      //案件送达
      gotoServicing: false,
      //选定仲裁员弹窗 按钮loading
      examineDetailVisible: false,
      //审核详情弹窗
      detailData: '',
      //审核详情内容
      caseExamineState: _dictionary.dictionary.caseExamineState,
      //案件审核状态
      examineAdopting: false,
      //审核详情通过按钮loading
      resolutionVisible: false,
      //审核填写意见弹窗显示
      resolutionform: {
        regionInput: ''
      }
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _inquiry.getevadingAuditList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getevadingAuditList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //已选择仲裁员
    getChoiceArbitrator: function getChoiceArbitrator(data) {
      var _this3 = this;
      (0, _inquiry.getChoiceArbitrator)(data).then(function (res) {
        if (res.code == 1) {
          _this3.partyChoiceArb = res.data;
        }
      });
    },
    //仲裁员列表
    getArbitratorList: function getArbitratorList() {
      var _this4 = this;
      (0, _inquiry.getArbitratorList)().then(function (res) {
        if (res.code == 1) {
          _this4.arbitratorList = res.data;
        }
      });
    },
    //表格  审核
    handleReview: function handleReview(data) {
      var _this5 = this;
      this.caseId = data.caseId;
      (0, _inquiry.getevadingAuditDetail)(this.caseId).then(function (res) {
        if (res.code == 1) {
          _this5.detailData = res.data;
          _this5.examineDetailVisible = true;
        }
      });
    },
    // 审核处理接口封装
    passOrRefuse: function passOrRefuse(status) {
      var _this6 = this;
      (0, _inquiry.evadingAuditHanding)({
        caseId: this.caseId,
        auditState: status == '拒绝' ? '-1' : '1',
        opinion: status == '拒绝' ? this.resolutionform.regionInput : '',
        remark: ''
      }).then(function (res) {
        if (res.code == 1) {
          if (status == '拒绝') {
            _this6.resolutionVisible = false;
            _this6.examineDetailVisible = false;
            _this6.init();
          } else {
            _this6.getChoiceArbitrator(_this6.caseId);
            _this6.getArbitratorList();
            _this6.examineDetailVisible = false;
            _this6.examineAdopting = false;
            _this6.divisionVisible = true;
          }
        } else {
          _this6.examineAdopting = false;
          _this6.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      }).catch(function (err) {
        _this6.examineAdopting = false;
      });
    },
    //审核详情弹窗 拒绝
    examineRefuse: function examineRefuse() {
      this.resolutionVisible = true;
      if (this.$refs['resolutionForm']) {
        this.$refs['resolutionForm'].resetFields();
      }
    },
    //审核详情弹窗 通过
    examineAdopt: function examineAdopt() {
      this.examineAdopting = true;
      this.passOrRefuse();
    },
    //拒绝理由弹窗  提交理由
    resolutionConfirm: function resolutionConfirm(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this7.passOrRefuse('拒绝');
          _this7.$message({
            message: '拒绝成功',
            type: 'success'
          });
        } else {
          return false;
        }
      });
    },
    updateShow: function updateShow() {
      this.divisionVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //弹窗确认按钮
    gotoService: function gotoService(divisionForm) {
      var _this8 = this;
      this.$refs[divisionForm].validate(function (valid) {
        if (valid) {
          _this8.gotoServicing = true;
          _this8.arbitratorList.forEach(function (item) {
            if (item.id == _this8.divisionForm.chiefArbitratorId) {
              _this8.divisionForm.chiefArbitratorName = item.name;
            } else if (item.id == _this8.divisionForm.secondArbitratorId) {
              _this8.divisionForm.secondArbitratorName = item.name;
            } else if (item.id == _this8.divisionForm.thirdArbitratorId) {
              _this8.divisionForm.thirdArbitratorName = item.name;
            }
          });
          _this8.divisionForm.caseId = _this8.caseId;
          // this.loading = true;
          (0, _inquiry.choiceArbitrator)(_this8.divisionForm).then(function (res) {
            _this8.loading = false;
            if (res.code == 1) {
              _this8.sendCaseVisible = true;
              _this8.$nextTick(function () {
                _this8.$refs.sendCase.init(_this8.caseId, 2);
              });
            } else {
              _this8.gotoServicing = false;
              _this8.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this8.gotoServicing = false;
          });
        } else {
          return false;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this9 = this;
      this.multipleSelection.forEach(function (item) {
        _this9.multipleSelectionId.push(item.caseId);
      });
      this.caseNum = this.multipleSelectionId.length;
      this.divisionLotVisible = true;
      this.getArbitratorList();
    },
    batchReviewSubmit: function batchReviewSubmit(divisionLotForm) {
      var _this10 = this;
      this.$refs[divisionLotForm].validate(function (valid) {
        if (valid) {
          _this10.arbitratorList.forEach(function (item) {
            if (item.id == _this10.divisionLotForm.chiefArbitratorId) {
              _this10.divisionLotForm.chiefArbitratorName = item.name;
            }
          });
          _this10.divisionLotForm.caseIds = _this10.multipleSelectionId;
          (0, _inquiry.batchSendCompose)(_this10.divisionLotForm).then(function (res) {
            if (res.code == 1) {
              _this10.$message({
                message: '批量审核成功',
                type: 'success'
              });
              _this10.divisionLotVisible = false;
              _this10.init();
              // 批量送达页面？？？
            } else {
              _this10.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      var _this11 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getevadingAuditList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this11.tableData = res.rows;
            _this11.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getevadingAuditList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this11.tableData = res.rows;
            _this11.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this12 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getevadingAuditList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this12.tableData = res.rows;
            _this12.total = res.total;
            _this12.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getevadingAuditList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this12.tableData = res.rows;
            _this12.total = res.total;
            _this12.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;