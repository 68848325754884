"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _commission = require("@/api/commission.js");
var _role = require("@/api/role");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _addAdmin = _interopRequireDefault(require("./addAdmin"));
var _updateAdmin = _interopRequireDefault(require("./updateAdmin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'handing',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: true,
      tableData: [],
      span: 18,
      multipleSelection: [],
      multipleSelectionId: [],
      adminType: '',
      id: '',
      addAdminVisible: false,
      updateAdminVisible: false,
      state: _dictionary.dictionary.adminState,
      conditionData: '' //来自子组件的数据
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init(); //默认秘书
  },

  components: {
    Caseconditons: _index.default,
    AddAdmin: _addAdmin.default,
    UpdateAdmin: _updateAdmin.default
  },
  methods: {
    init: function init(data) {
      var _this = this;
      this.loading = true;
      (0, _commission.commUserPage)({
        adminType: data,
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleAddAdmin: function handleAddAdmin() {
      var _this2 = this;
      this.addAdminVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addAdmin.init();
      });
    },
    handleUpdateAdmin: function handleUpdateAdmin(param) {
      var _this3 = this;
      this.updateAdminVisible = true;
      this.$nextTick(function () {
        _this3.$refs.updateAdmin.init(param);
      });
    },
    handleDeleteAdmin: function handleDeleteAdmin() {
      var _this4 = this;
      this.$confirm('确定删除选中的数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.multipleSelection.forEach(function (item, index) {
          _this4.multipleSelectionId.push(item.id);
        });
        _this4.loading = true;
        (0, _role.deleteAdmin)({
          ids: _this4.multipleSelectionId
        }).then(function (res) {
          _this4.loading = false;
          if (res.code == 1) {
            _this4.$message({
              message: '删除成功！',
              type: 'success'
            });
            _this4.init();
          } else {
            _this4.$message.error(res.msg);
          }
          _this4.multipleSelectionId = [];
        }).catch(function (error) {
          _this4.loading = false;
        });
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      if (this.adminType) {
        this.currentPage = 1;
        this.init(this.adminType);
      }
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.adminType = '';
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this5 = this;
      this.pageLimit = val;
      if (this.adminType) {
        // 有筛选条件的情况下
        (0, _commission.commUserPage)({
          limit: val,
          page: this.currentPage,
          adminType: this.adminType
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _commission.commUserPage)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this6 = this;
      if (this.adminType) {
        // 有筛选条件的情况下
        (0, _commission.commUserPage)({
          limit: this.pageLimit,
          page: val,
          adminType: this.adminType
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _commission.commUserPage)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;