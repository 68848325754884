var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "financial-setup" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "setup-item" },
            [
              _c("span", { staticClass: "setup-item-header" }, [
                _vm._v("分账设置"),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("span", { staticClass: "setup-item-tip" }, [
                _vm._v("通过第三方通道导入的案件，给通道方的分账。"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分账方式", prop: "SeparateAccounts" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.SeparateAccounts,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "SeparateAccounts", $$v)
                        },
                        expression: "ruleForm.SeparateAccounts",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "按仲裁费百分比" } }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "按单个案件固定金额" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    model: {
                      value: _vm.ruleForm.SeparateAccountsNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "SeparateAccountsNum", $$v)
                      },
                      expression: "ruleForm.SeparateAccountsNum",
                    },
                  }),
                  _vm._v("%\n        "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setup-item" },
            [
              _c("span", { staticClass: "setup-item-header" }, [
                _vm._v("报酬设置"),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("span", { staticClass: "setup-item-tip" }, [
                _vm._v("给办案人员的报酬计算方式。"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仲裁员报酬", prop: "arbitratorPay" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.arbitratorPay,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "arbitratorPay", $$v)
                        },
                        expression: "ruleForm.arbitratorPay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "按仲裁费百分比" } }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "按单个案件固定金额" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "100px" },
                      model: {
                        value: _vm.ruleForm.arbitratorPayNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "arbitratorPayNum", $$v)
                        },
                        expression: "ruleForm.arbitratorPayNum",
                      },
                    },
                    [_vm._v(">")]
                  ),
                  _vm._v("%\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "书记员报酬", prop: "clerkPay" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.clerkPay,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "clerkPay", $$v)
                        },
                        expression: "ruleForm.clerkPay",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "按仲裁费百分比" } }),
                      _vm._v(" "),
                      _c("el-radio", {
                        attrs: { label: "按单个案件固定金额" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "100px" },
                      model: {
                        value: _vm.ruleForm.clerkPayNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "clerkPayNum", $$v)
                        },
                        expression: "ruleForm.clerkPayNum",
                      },
                    },
                    [_vm._v(">")]
                  ),
                  _vm._v("%\n         "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { "margin-top": "100px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }