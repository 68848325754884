var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "global-page-base-layout",
    },
    [
      _c("Caseconditons", {
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inquiry-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n             " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n           "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }