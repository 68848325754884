var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage global-page-base-layout" },
    [
      _c("Caseconditons", {
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-all-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.batchUpload },
            },
            [_vm._v("批量上传")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "manage-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.selectable,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sendState", label: "取证状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.evidenceStatus[scope.row.sendState]) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sendState == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.supple(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("上传材料")]
                            )
                          : scope.row.sendState == 4 || scope.row.sendState == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.check(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.suppleAgain(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("重新上传")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "supple-material",
          attrs: {
            title: "上传材料",
            visible: _vm.suppleMaterialVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.suppleMaterialVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _vm.multipleSelection.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "案号" } },
                    _vm._l(_vm.multipleSelection, function (item, index) {
                      return _c(
                        "span",
                        { staticStyle: { "margin-right": "10px" } },
                        [_vm._v(_vm._s(item.caseId))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "取证内容", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.ruleForm.content,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "content",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "附件" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-50px",
                        color: "#F56C6C",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleSuccess,
                        "before-remove": _vm.beforeRemove,
                        headers: _vm.headers,
                        multiple: false,
                        limit: 10,
                        accept: ".png,.jpg,.pdf",
                        "on-exceed": _vm.handleExceed,
                        "before-upload": _vm.beforeUpload,
                        "file-list": _vm.materFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("单个文件不可以超过5M，支持PNG/JPG/PDF格式")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.materUploadTip
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#F56C6C",
                            "font-size": "10px",
                          },
                        },
                        [_vm._v("请完善必要信息和上传符合规范文件")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.supplyBtnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "supple-material",
          attrs: {
            title: "查看材料",
            visible: _vm.checkSuppleMaterialVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkSuppleMaterialVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "demo-ruleForm", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "取证内容", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: { disabled: true, type: "textarea", rows: 2 },
                    model: {
                      value: _vm.materContent,
                      callback: function ($$v) {
                        _vm.materContent = $$v
                      },
                      expression: "materContent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "附件" } },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "el-link",
                    {
                      staticStyle: { "margin-right": "30px" },
                      attrs: {
                        underline: false,
                        href: item.href,
                        target: "_blank",
                        type: "primary",
                      },
                    },
                    [_vm._v(_vm._s(item.fileName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.sendCaseVisible
        ? _c("send-case", {
            ref: "sendCase",
            on: {
              handleShowing: _vm.updateShow,
              initList: _vm.refreshPage,
              handleErrorShowing: _vm.updateErrorShow,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }