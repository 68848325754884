"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'caseReview',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      caseBriefVisible: false,
      //案由弹窗
      reasonList: [],
      //案由数组
      form: {
        reasonId: ''
      },
      loadingProcess: false,
      sumbiting: false,
      //批量审核中
      optionArr: [],
      processVisible: false,
      opinionform: {
        reviewId: "",
        reviewName: "",
        opinion: ""
      },
      refuseRules: {
        reviewId: [{
          required: true,
          message: '请选择人员',
          trigger: 'change'
        }],
        opinion: [{
          required: true,
          message: '请输入审核意见',
          trigger: 'blur'
        }]
      }
    };
  },
  activated: function activated() {
    this.currentPage = 1;
    this.init();
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    handleProcess: function handleProcess() {
      var _this = this;
      this.$refs.opinionform.validate(function (valid) {
        if (valid) {
          var params = _this.opinionform;
          var optAr = _this.optionArr.find(function (item) {
            return item.id == _this.opinionform.reviewId;
          });
          params.reviewName = optAr.name;
          _this.loadingProcess = true;
          (0, _inquiry.batchTask)({
            caseIds: _this.onGetBatchIds(),
            caseState: 15,
            content: JSON.stringify(params)
          }).then(function (res) {
            if (res.code == 1) {
              _this.sumbiting = false;
              _this.$message({
                message: '批量操作成功',
                type: 'success'
              });
              _this.caseBriefVisible = false;
              _this.init();
              _this.processVisible = false;
            } else {
              _this.$message.error(res.msg);
              _this.processVisible = false;
            }
          });

          // onBatchSendRegisterPayBook(params).then(res=>{
          //   this.loadingProcess=false;
          //   if(res.code=='1'){
          //     this.init();
          //     this.processVisible=false;
          //      this.$message({ message: "操作成功", type: "success" });
          //   }else{
          //      this.$message({ message: "操作失败", type: "error" });
          //   }
          // }).catch(error=>{
          //   this.loadingProcess=false;
          // });
        } else {
          return;
        }
      });
    },
    onGetBatchIds: function onGetBatchIds() {
      var ids = [];
      for (var i = 0; i < this.multipleSelection.length; i++) {
        var item = this.multipleSelection[i];
        ids.push(item.caseId);
      }
      return ids;
    },
    onloadProcessMan: function onloadProcessMan() {
      var _this2 = this;
      this.caseId = this.multipleSelection[0].caseId;
      (0, _inquiry.getSecretaryList)({
        id: this.caseId
      }).then(function (res) {
        if (res.code == 1) {
          var data = res.data.length ? res.data[0].allReviews : [];
          _this2.optionArr = data;
        }
      });
    },
    init: function init() {
      var _this3 = this;
      this.loading = true;
      (0, _inquiry.getList)({
        state: 15,
        limit: this.pageLimit,
        page: this.currentPage,
        sort: "caseId",
        order: "asc"
      }).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
          _this3.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格审核事件
    handleReview: function handleReview(val) {
      this.$router.push({
        name: 'handingDetail',
        params: {
          caseId: val,
          typeDetail: 'caseReview'
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this4 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        state: 15,
        limit: this.pageLimit,
        page: 1,
        sort: "caseId",
        order: "asc"
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this4.tableData = res.rows;
          _this4.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    //批量审核
    batchReview: function batchReview() {
      if (!this.multipleSelection || this.multipleSelection.length <= 0) {
        this.$message({
          message: "请勾选案件",
          type: "waring"
        });
        return;
      }
      this.opinionform.reviewId = '';
      this.opinionform.reviewName = '';
      this.opinionform.opinion = '通过';
      this.processVisible = true;
      this.onloadProcessMan();

      // this.$confirm('确定批量审核？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //   getReasonList().then(res => {
      //       this.reasonList = res.data;
      //       this.caseBriefVisible = true;
      //       if(this.$refs['form']){
      //          this.$refs['form'].resetFields();
      //       }
      //   })
      // })
    },
    // 案由弹窗确定按钮
    sumbitCaseBrief: function sumbitCaseBrief(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.sumbiting = true;
          _this5.multipleSelection.forEach(function (item) {
            _this5.multipleSelectionId.push(item.caseId);
          });
          var reasonItem = _this5.reasonList.find(function (ele) {
            return ele.id == _this5.form.reasonId;
          });
          (0, _inquiry.batchFiling)({
            caseIds: _this5.multipleSelectionId,
            reasonId: _this5.form.reasonId,
            reasonName: reasonItem.reasonName
          }).then(function (res) {
            if (res.code == 1) {
              _this5.sumbiting = false;
              _this5.$message({
                message: '批量审核成功',
                type: 'success'
              });
              _this5.caseBriefVisible = false;
              _this5.init();
            } else {
              _this5.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.state = 15;
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: 15,
          limit: val,
          page: this.currentPage,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this7 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: 15,
          limit: this.pageLimit,
          page: val,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;