"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'register',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      caseBriefVisible: false,
      //案由弹窗
      reasonList: [],
      //案由数组
      form: {
        reasonId: ''
      },
      sumbiting: false //批量审核中
    };
  },
  activated: function activated() {
    this.currentPage = 1;
    this.init();
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      this.loading = true;
      (0, _inquiry.getList)({
        states: [1, 13],
        limit: this.pageLimit,
        page: this.currentPage,
        sort: "caseId",
        order: "asc"
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格审核事件
    handleReview: function handleReview(val) {
      this.$router.push({
        name: 'handingDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        states: [1, 13],
        limit: this.pageLimit,
        page: 1,
        sort: "caseId",
        order: "asc"
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    //批量审核
    batchReview: function batchReview() {
      var _this3 = this;
      this.sumbiting = true;
      this.$confirm('提交后30分钟内自动审批，是否确定批量审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.multipleSelectionId = [];
        _this3.multipleSelection.forEach(function (item) {
          _this3.multipleSelectionId.push(item.caseId);
        });
        (0, _inquiry.batchTask)({
          caseIds: _this3.multipleSelectionId,
          caseState: 1,
          content: ''
        }).then(function (res) {
          if (res.code == 1) {
            _this3.sumbiting = false;
            _this3.$message({
              message: '批量操作成功',
              type: 'success'
            });
            _this3.caseBriefVisible = false;
            _this3.init();
          } else {
            _this3.$message.error(res.msg);
          }
        });
      });
    },
    // // 案由弹窗确定按钮
    // sumbitCaseBrief(formName){
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.sumbiting = true;
    //       this.multipleSelection.forEach(item => {
    //           this.multipleSelectionId.push(item.caseId);
    //       })
    //       let  reasonItem = this.reasonList.find((ele)=>{
    //         return ele.id == this.form.reasonId;
    //       })
    //       batchFiling({caseIds: this.multipleSelectionId,reasonId:this.form.reasonId,reasonName:reasonItem.reasonName}).then(res => {
    //           if (res.code == 1) {
    //               this.sumbiting=false;
    //               this.$message({message: '批量审核成功', type: 'success'});
    //               this.caseBriefVisible = false;
    //               this.init();
    //           } else {
    //               this.$message.error(res.msg);
    //           }
    //       })
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.states = [1, 13];
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          states: [1, 13],
          limit: val,
          page: this.currentPage,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          states: [1, 13],
          limit: this.pageLimit,
          page: val,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;