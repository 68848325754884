var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exerciseRight global-page-base-layout" },
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { prop: "date", label: "仲裁机构", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "启用数量", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "address", label: "未开启数量" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "address", label: "最后编辑时间" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      { attrs: { type: "text", size: "medium" } },
                      [_vm._v("停用")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function ($event) {
                            return _vm.handleClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": _vm.pageSizes,
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }