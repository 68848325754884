"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _survey = require("@/api/survey.js");
var _dictionary = require("@/config/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Survey',
  data: function data() {
    return {
      loading: false,
      upcomingData: '',
      caseState: _dictionary.dictionary.caseState,
      handing: 0,
      group: 0,
      holdCourt: 0,
      judge: 0,
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 5,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      total: 0,
      //总页数
      all: 0,
      overDays: 0,
      toDays: 0,
      threeDays: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'token'])),
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _survey.findCaseRemindDoneTotal)().then(function (res) {
        if (res.code === 1) {
          _this.threeDays = res.data.threeDays;
          _this.all = res.data.all;
          _this.toDays = res.data.toDays;
          _this.overDays = res.data.overDays;
        } else {
          _this.threeDays = 0;
          _this.all = 0;
          _this.overDays = 0;
          _this.overDays = 0;
        }
      });
      (0, _survey.getCaseRemindList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code === 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
        }
        _this.loading = false;
      });
      (0, _survey.findCurrentTaskRemind)().then(function (res) {
        if (res.code === 1) {
          _this.handing = res.data.register;
          _this.group = res.data.compose;
          _this.holdCourt = res.data.court;
          _this.judge = res.data.rule;
        } else {
          _this.handing = 0;
          _this.group = 0;
          _this.holdCourt = 0;
          _this.judge = 0;
        }
      });
    },
    handleReview: function handleReview(val) {
      this.$router.push({
        name: _dictionary.dictionary.caseStateRouter[val.caseState]
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      var _this2 = this;
      (0, _survey.getCaseRemindList)({
        limit: val,
        page: this.currentPage
      }).then(function (res) {
        if (res.code === 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
        _this2.loading = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      //没有筛选条件的情况下
      (0, _survey.getCaseRemindList)({
        limit: this.pageLimit,
        page: val
      }).then(function (res) {
        if (res.code === 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
          _this3.currentPage = val;
        }
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;