var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handing global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: { caseReplyCondition: false },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "handing-all-btn" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 13
                          ? _c("span", { staticStyle: { color: "crimson" } }, [
                              _vm._v(_vm._s(_vm.caseState[scope.row.state])),
                            ])
                          : scope.row.state != 13
                          ? _c("span", { staticStyle: { color: "#46a6ff" } }, [
                              _vm._v(_vm._s(_vm.caseState[scope.row.state])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSecretaryName", label: "立案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "reviewName", label: "当前处理人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleReview(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("【缴费记录】")]
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.againSendPayNotice(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("【缴费送达】")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "case-deal-box",
              attrs: {
                title: "缴费信息记录",
                visible: _vm.dealFormShow,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dealFormShow = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.dealform, "label-width": "150px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "仲裁费：" } }, [
                    _c("div", { staticClass: "case-txt" }, [
                      _vm._v(
                        "\n            ￥:" +
                          _vm._s(_vm.dealform.caseAmount) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "实缴仲裁费：" } }, [
                    _c("div", { staticClass: "case-txt" }, [
                      _vm._v(
                        "\n            ￥:" +
                          _vm._s(_vm.dealform.amount) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "缴费时间：" } }, [
                    _c("div", { staticClass: "case-txt" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dealform.payTime) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "缴费备注：" } }, [
                    _c("div", { staticClass: "case-txt" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.dealform.remarks) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "缴费凭证：" } }, [
                    _c(
                      "div",
                      { staticClass: "case-txt" },
                      [
                        _c(
                          "el-link",
                          {
                            staticClass: "margin-right20",
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: _vm.dealform.href,
                              target: "_blank",
                            },
                          },
                          [_vm._v("缴费凭证\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dealFormShow = false
                        },
                      },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }