var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "case-detail" },
        [
          _c("el-col", { staticClass: "detail", attrs: { span: 20 } }, [
            _c("div", { staticClass: "anchor", attrs: { id: "caseStatus" } }, [
              _c("span", { staticClass: "case-detail-state" }, [
                _vm._v(_vm._s(_vm.caseState[_vm.detailData.state])),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseAbout" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("案件概况")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案件受理号：" + _vm._s(_vm.detailData.caseId)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("提交时间：" + _vm._s(_vm.detailData.ctime)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案由：" + _vm._s(_vm.detailData.reason)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "办案秘书：" +
                            _vm._s(_vm.detailData.handlingSecretaryName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "仲裁费：" + _vm._s(_vm.detailData.arbitrationAmount)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案号：" + _vm._s(_vm.detailData.caseNo)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "立案时间：" + _vm._s(_vm.detailData.registerTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "适用程序：" +
                            _vm._s(_vm.detailData.applicableProcedure)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "标的额：" + _vm._s(_vm.detailData.controversyAmount)
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "casePerson" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("当事人信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("申请人：\n              "),
                          _vm.detailData.appPartyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.appPartyList,
                                function (app) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(app.partyId)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(app.name) + "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("申请人代理人：\n              "),
                          _vm.detailData.appProxyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.appProxyList,
                                function (appProxy) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(
                                            appProxy.partyId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(appProxy.name) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("被申请人：\n              "),
                          _vm.detailData.resPartyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.resPartyList,
                                function (res) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(res.partyId)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(res.name) + "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("被申请人代理人：\n              "),
                          _vm.detailData.resProxyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.resProxyList,
                                function (resProxy) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(
                                            resProxy.partyId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(resProxy.name) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseItem" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁事项")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("仲裁请求：\n              "),
                          _c("span", { staticClass: "text-ellipsis" }, [
                            _vm._v(_vm._s(_vm.detailData.arbitrationRequest)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detailData.arbitrationRequest)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.arbitrationRequestFlag,
                                      expression: "arbitrationRequestFlag",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                },
                                [_vm._v("显示全部")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("事实和理由：\n              "),
                          _c("span", { staticClass: "text-ellipsis" }, [
                            _vm._v(_vm._s(_vm.detailData.arbitrationAruth)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detailData.arbitrationAruth)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.arbitrationAruthFlag,
                                      expression: "arbitrationAruthFlag",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                },
                                [_vm._v("显示全部")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseMaterial" } },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._v("申请人案件材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-left": "10px",
                            },
                            attrs: { data: _vm.MaterialData1 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "partyName", label: "提交人" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileName", label: "名称" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "primary",
                                                underline: false,
                                                href: scope.row.aliyunOssHref,
                                                target: "_blank",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v(_vm._s(scope.row.fileName))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "discernState",
                                label: "识别状态",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.discernState == "-1"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.identiyState[
                                                    scope.row.discernState
                                                  ]
                                                )
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.identiyState[
                                                  scope.row.discernState
                                                ]
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [
                  _vm._v("被申请人案件材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-left": "10px",
                            },
                            attrs: { data: _vm.MaterialData2 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "submitUserName",
                                label: "提交人",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileName", label: "名称" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("iframe", {
                                            attrs: {
                                              src: scope.row.aliyunOssHref,
                                              frameborder: "0",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "primary",
                                                underline: false,
                                                href: scope.row.aliyunOssHref,
                                                target: "_blank",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v(_vm._s(scope.row.fileName))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "discernState",
                                label: "识别状态",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.discernState == "-1"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.identiyState[
                                                    scope.row.discernState
                                                  ]
                                                )
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.identiyState[
                                                  scope.row.discernState
                                                ]
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "resInformation" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("答辩信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("答辩状：\n              "),
                        _vm.caseReply.content == ""
                          ? _c("span", [_vm._v("暂无")])
                          : _c("div", { staticClass: "caseItem-txt" }, [
                              _vm._v(_vm._s(_vm.caseReply.content)),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("答辩材料：\n              "),
                          _vm.caseReply.replyName == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: _vm.caseReply.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.caseReply.replyName) +
                                      "\n              "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "holdInformation" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁庭信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "仲裁委指定仲裁员：" +
                            _vm._s(
                              _vm.courtInfo.arbitratorName
                                ? _vm.courtInfo.arbitratorName
                                : "未指定仲裁员"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "被申请人选择仲裁员：" +
                            _vm._s(
                              _vm.courtInfo.resArbitratorName
                                ? _vm.courtInfo.resArbitratorName
                                : "未选择仲裁员"
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "申请人选择仲裁员：" +
                            _vm._s(
                              _vm.courtInfo.appArbitratorName
                                ? _vm.courtInfo.appArbitratorName
                                : "未选择仲裁员"
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.courtInfo.secondArbitratorName
                        ? _c("div", { staticClass: "case-txt" }, [
                            _vm._v(
                              "\n              仲裁员2名字（适用于合议庭）：" +
                                _vm._s(_vm.courtInfo.secondArbitratorName) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.courtInfo.thirdArbitratorName
                        ? _c("div", { staticClass: "case-txt" }, [
                            _vm._v(
                              "\n              仲裁员3名字（适用于合议庭）：" +
                                _vm._s(_vm.courtInfo.thirdArbitratorName) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm.courtInfo.courtTime
                        ? _c("div", { staticClass: "case-txt" }, [
                            _vm._v(
                              "开庭时间：" + _vm._s(_vm.courtInfo.courtTime)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.courtInfo.courtAddress
                        ? _c("div", { staticClass: "case-txt" }, [
                            _vm._v(
                              "开庭地点：" + _vm._s(_vm.courtInfo.courtAddress)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "组庭时间：" +
                            _vm._s(
                              _vm.courtInfo.composeTime
                                ? _vm.courtInfo.composeTime
                                : "暂无"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "审理方式：" +
                            _vm._s(
                              _vm.courtInfo.trialWay
                                ? _vm.courtInfo.trialWay
                                : "暂无"
                            )
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "EvidentMaterial" } },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._v("仲裁庭取证材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-left": "10px",
                            },
                            attrs: { data: _vm.EvidentMaterialData },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "content", label: "内容" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "files", label: "材料" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      scope.row.files,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  trigger: "hover",
                                                },
                                              },
                                              [
                                                _c("iframe", {
                                                  attrs: {
                                                    src: item.aliyunOssHref,
                                                    frameborder: "0",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      slot: "reference",
                                                      type: "primary",
                                                      underline: false,
                                                      href: item.aliyunOssHref,
                                                      target: "_blank",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.fileName)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "courtEvidence" } },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._v("仲裁庭取证质证"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-left": "10px",
                            },
                            attrs: { data: _vm.courtEvidence },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "partyName", label: "质证人" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "partyType", label: "质证人类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.partyIdType[scope.row.partyType]
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "content", label: "内容" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileList", label: "材料" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      scope.row.fileList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          [
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  trigger: "hover",
                                                },
                                              },
                                              [
                                                _c("iframe", {
                                                  attrs: {
                                                    src: item.aliyunOssHref,
                                                    frameborder: "0",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      slot: "reference",
                                                      type: "primary",
                                                      underline: false,
                                                      href: item.aliyunOssHref,
                                                      target: "_blank",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.fileName)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "trialRecord" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("开庭笔录")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _vm.trialRecordDocument.fileName == ""
                      ? _c("span", [_vm._v("暂无")])
                      : _c(
                          "el-link",
                          {
                            staticClass: "margin-right20",
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: _vm.trialRecordDocument.aliyunOssHref,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.trialRecordDocument.fileName) +
                                "\n          "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "arbitrDoc" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁文书")]),
                _vm._v(" "),
                _c("el-main", [
                  _c(
                    "div",
                    {
                      staticClass: "application-doc",
                      staticStyle: {
                        "border-bottom": "1px dotted gray",
                        "padding-bottom": "10px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _vm._v("\n            申请人文书：\n            "),
                      _vm.detailData.appFileInstrumentList == ""
                        ? _c("span", [_vm._v("暂无")])
                        : _vm._l(
                            _vm.detailData.appFileInstrumentList,
                            function (item) {
                              return _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: item.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.fileName) + "\n            "
                                  ),
                                ]
                              )
                            }
                          ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "respontent-doc" },
                    [
                      _vm._v("\n            被申请人文书：\n            "),
                      _vm.detailData.resFileInstrumentList == ""
                        ? _c("span", [_vm._v("暂无")])
                        : _vm._l(
                            _vm.detailData.resFileInstrumentList,
                            function (item) {
                              return _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: item.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.fileName) + "\n            "
                                  ),
                                ]
                              )
                            }
                          ),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "colseDoc" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("结案文书")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _vm.endCaseDocument.fileName == ""
                      ? _c("span", [_vm._v("暂无")])
                      : _c(
                          "el-link",
                          {
                            staticClass: "margin-right20",
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: _vm.endCaseDocument.aliyunOssHref,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.endCaseDocument.fileName) +
                                "\n          "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "ExerciseRight" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("行使权利")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-left": "10px" },
                    attrs: { data: _vm.rightTableData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "registerTime", label: "提交时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "appName", label: "提交人" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "procedureType", label: "程序类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.caseExamineType[scope.row.procedureType]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "caseState", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.checkState == 0
                                ? _c("span", [_vm._v("未审核")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.checkState == 1
                                ? _c("span", [_vm._v("通过")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.checkState == -1
                                ? _c("span", [_vm._v("拒绝")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseMove" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("案件流转记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-left": "10px" },
                    attrs: {
                      data: _vm.caseMoveData,
                      "header-cell-style": { background: "#fff" },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "currentCaseState", label: "流程阶段" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.caseState[scope.row.currentCaseState]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "recordName", label: "事项/流转内容" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "opinion", label: "理由" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "recordTime", label: "流转时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "备注" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.MoveCurrentPage,
                        "page-sizes": [5, 10, 30],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.MoveTotal,
                      },
                      on: {
                        "size-change": _vm.handleMoveSizeChange,
                        "current-change": _vm.handleMoveCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseAudting" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("审核记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-left": "10px" },
                    attrs: { data: _vm.auditRecordData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "auditName", label: "审核阶段" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "opinionName", label: "审核人" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "auditState", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.caseExamineState[scope.row.auditState]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "备注" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "opinion", label: "经办意见" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "auditTime", label: "审核时间" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.AudtingCurrentPage,
                        "page-sizes": [5, 10, 30],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.AudtingTotal,
                      },
                      on: {
                        "size-change": _vm.handleAudtingSizeChange,
                        "current-change": _vm.handleAudtingCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseService" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("送达记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-left": "10px" },
                    attrs: { data: _vm.caseServiceData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "deliveryAddress", label: "送达地址" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "partyType", label: "当事人类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.partyIdType[scope.row.partyType]) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "type", label: "送达方式" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.listSendType[scope.row.type]) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "serviceType", label: "案件送达状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.serviceType[scope.row.serviceType]
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.state == 0
                                ? _c("span", [_vm._v("第一次发送失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 1
                                ? _c("span", [_vm._v("第二次发送失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 2
                                ? _c("span", [_vm._v("通知成功")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 3
                                ? _c("span", [_vm._v("通知失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 4
                                ? _c("span", [_vm._v("发送成功")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 5
                                ? _c("span", [_vm._v("发送中")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "ctime", label: "发送时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "fromAddress", label: "发送地址" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "errMsg", label: "失败详情" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.ServiceCurrentPage,
                        "page-sizes": [5, 10, 30],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.ServiceTotal,
                      },
                      on: {
                        "size-change": _vm.handleServiceSizeChange,
                        "current-change": _vm.handleServiceCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "paymentProof" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("缴费凭证")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-left": "10px" },
                    attrs: { data: _vm.paymentProofData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", width: "50", label: "序号" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "payableAmount", label: "应缴费用（元）" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "paidAmount", label: "已缴费用（元）" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "缴费凭证" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: scope.row.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "备注" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "dash", attrs: { span: 4 } }, [
            _c("ul", { staticClass: "anchor-link" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseStatus" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseStatus")
                      },
                    },
                  },
                  [_vm._v("案件状态")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseAbout" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseAbout")
                      },
                    },
                  },
                  [_vm._v("案件概况")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "casePerson" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("casePerson")
                      },
                    },
                  },
                  [_vm._v("当事人信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseItem" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseItem")
                      },
                    },
                  },
                  [_vm._v("仲裁事项")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseMaterial" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseMaterial")
                      },
                    },
                  },
                  [_vm._v("案件材料")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "resInformation" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("resInformation")
                      },
                    },
                  },
                  [_vm._v("答辩信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "holdInformation" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("holdInformation")
                      },
                    },
                  },
                  [_vm._v("仲裁庭信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "EvidentMaterial" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("EvidentMaterial")
                      },
                    },
                  },
                  [_vm._v("仲裁庭取证材料")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "resInformation" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("courtEvidence")
                      },
                    },
                  },
                  [_vm._v("仲裁庭取证质证")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "trialRecord" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("trialRecord")
                      },
                    },
                  },
                  [_vm._v("开庭笔录")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "arbitrDoc" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("arbitrDoc")
                      },
                    },
                  },
                  [_vm._v("仲裁文书")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "colseDoc" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("colseDoc")
                      },
                    },
                  },
                  [_vm._v("结案文书")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "ExerciseRight" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("ExerciseRight")
                      },
                    },
                  },
                  [_vm._v("行使权力")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseMove" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseMove")
                      },
                    },
                  },
                  [_vm._v("案件流转记录")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseAudting" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseAudting")
                      },
                    },
                  },
                  [_vm._v("审核记录")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseService" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseService")
                      },
                    },
                  },
                  [_vm._v("送达记录")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("party-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.partyInfoVisible,
            expression: "partyInfoVisible",
          },
        ],
        ref: "partyInfo",
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "detail-btn",
          staticStyle: {
            position: "fixed",
            bottom: "0px",
            right: "20px",
            left: "230px",
            "line-height": "60px",
            "text-align": "right",
            background: "white",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "30px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "电子签章验证信息",
            visible: _vm.electricsignVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.electricsignVisible = $event
            },
          },
        },
        [
          _vm.electricsignDataFlag
            ? _c(
                "el-form",
                {
                  ref: "addAddressForm",
                  attrs: { "label-position": "right", "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否有效：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.electricsignData.verify,
                            callback: function ($$v) {
                              _vm.$set(_vm.electricsignData, "verify", $$v)
                            },
                            expression: "electricsignData.verify",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是 ", value: "true" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "否", value: "false" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签署日期：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.electricsignData.signDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.electricsignData, "signDate", $$v)
                          },
                          expression: "electricsignData.signDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期开始：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.electricsignData.beforeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.electricsignData, "beforeDate", $$v)
                          },
                          expression: "electricsignData.beforeDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期结束：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.electricsignData.afterDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.electricsignData, "afterDate", $$v)
                          },
                          expression: "electricsignData.afterDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "证书拥有者：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.electricsignData.certOwner,
                          callback: function ($$v) {
                            _vm.$set(_vm.electricsignData, "certOwner", $$v)
                          },
                          expression: "electricsignData.certOwner",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "证书颁发者：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.electricsignData.certAward,
                          callback: function ($$v) {
                            _vm.$set(_vm.electricsignData, "certAward", $$v)
                          },
                          expression: "electricsignData.certAward",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form",
                {
                  ref: "addAddressForm",
                  attrs: { "label-position": "right", "label-width": "150px" },
                },
                [_c("el-form-item", { attrs: { label: "暂无电子签章信息" } })],
                1
              ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleElectricsignSubmit()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "apply-arbitrate",
          attrs: {
            title: "申请撤案",
            visible: _vm.applyWithdrawalVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.applyWithdrawalVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "applyWithdrawalForm",
              attrs: {
                model: _vm.applyWithdrawalForm,
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "撤案理由",
                    prop: "reason",
                    rules: { required: true, message: "请填写撤案理由" },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "70%" },
                    attrs: { placeholder: "", type: "textarea", rows: 5 },
                    model: {
                      value: _vm.applyWithdrawalForm.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.applyWithdrawalForm, "reason", $$v)
                      },
                      expression: "applyWithdrawalForm.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.applyWithdrawalVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.materilExamLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitWithdrawal("applyWithdrawalForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }