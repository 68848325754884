var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        staticStyle: { width: "300px" },
        attrs: { placeholder: "请输入内容" },
        model: {
          value: _vm.iptValue,
          callback: function ($$v) {
            _vm.iptValue = $$v
          },
          expression: "iptValue",
        },
      }),
      _c("br"),
      _vm._v(" "),
      _c("el-input", {
        staticStyle: { width: "300px" },
        model: {
          value: _vm.resValue,
          callback: function ($$v) {
            _vm.resValue = $$v
          },
          expression: "resValue",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.onEncryption } },
        [_vm._v("加密")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.onDecrypt } },
        [_vm._v("解密")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }