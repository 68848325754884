"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// Any plugins you want to use has to be imported
// Detail plugins list see https://www.tinymce.com/docs/plugins/
// Custom builds see https://www.tinymce.com/download/custom-builds/

// const plugins = ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount']
// const plugins = ['advlist anchor autolink autosave   colorpicker contextmenu directionality  fullscreen hr image  link lists  nonbreaking noneditable pagebreak paste  print save searchreplace spellchecker tabfocus  textcolor textpattern visualblocks visualchars wordcount']
var plugins = ['advlist anchor autolink autosave   colorpicker contextmenu directionality  fullscreen hr image  link lists  nonbreaking noneditable pagebreak paste  print save searchreplace spellchecker tabfocus  textcolor textpattern visualblocks visualchars wordcount'];
var _default = plugins;
exports.default = _default;