"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _auth = require("@/utils/auth");
var _security = require("@/utils/security");
var _encryptKey = require("@/config/encryptKey");
var _arbitrator = require("@/api/arbitrator");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Editor from '@/components/editor/index.vue'
var _default = {
  name: 'recordCourt',
  data: function data() {
    return {
      partyInfos: {
        title: '',
        content: ''
      },
      partyContents: {
        title: '',
        content: ''
      },
      caseContents: {
        title: '',
        content: ''
      },
      arbitrationContents: {
        title: '',
        content: ''
      },
      ruleContents: {
        title: '',
        content: ''
      },
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      caseId: '',
      id: '',
      upcomingVisible: false,
      //
      refuseVisible: false,
      refuseForm: {
        opinion: '',
        caseId: ''
      },
      refuseRules: {
        opinion: [{
          required: true,
          message: '请填写拒绝理由',
          trigger: 'blur'
        }]
      },
      rule: '',
      detailData: '',
      //总数据
      identiyState: _dictionary.dictionary.identiyState,
      //案件识别类型
      caseReply: '',
      //答辩状数据   ==>因为返回detailData数据里面，它自成一个对象，必须要声明
      courtInfo: "",
      //仲裁庭信息
      arbitrationRequestFlag: false,
      arbitrationAruthFlag: false,
      MaterialData1: [],
      //申请人材料列表
      MaterialData2: [],
      //被申请人材料列表
      //申请人案件材料分页
      MaterialTotal1: 0,
      MaterialCurrentPage1: 1,
      // 被申请人案件材料分页
      MaterialTotal2: 0,
      MaterialCurrentPage2: 1,
      partyInfoVisible: false,
      //当事人信息
      fileType: _dictionary.dictionary.fileType,
      materialState: _dictionary.dictionary.materialState,
      sendMsgType: _dictionary.dictionary.sendMsgType,
      batchUploadVisible: false,
      //批量上传裁决书
      batchUploadApi: '',
      token: (0, _auth.getToken)(),
      uploadVisible: false,
      uploadDecisionVisible: false,
      uploadRule: {},
      uploadApi: '',
      loadingSave: false,
      loadingShow: false,
      loadingPass: false,
      singleUploading: false,
      caseAwardContentDto: ''
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    // Editor,
    Tinymce: _Tinymce.default
  },
  methods: {
    handleDelete1: function handleDelete1(val) {
      if (this.partyInfos.content.length == 1) {
        this.$message.error("当事人信息不能为空");
      } else {
        this.partyInfos.content.splice(val, 1);
      }
    },
    handleDelete2: function handleDelete2(val) {
      this.partyContents.content.splice(val, 1);
    },
    handleDelete3: function handleDelete3(val) {
      this.caseContents.content.splice(val, 1);
    },
    handleDelete4: function handleDelete4(val) {
      this.arbitrationContents.content.splice(val, 1);
    },
    handleDelete5: function handleDelete5(val) {
      this.ruleContents.content.splice(val, 1);
    },
    handleAdd1: function handleAdd1(val) {
      this.partyInfos.content.splice(val + 1, 0, {
        text: ''
      });
    },
    handleAdd2: function handleAdd2(val) {
      this.partyContents.content.splice(val + 1, 0, {
        text: ''
      });
    },
    handleAdd3: function handleAdd3(val) {
      this.caseContents.content.splice(val + 1, 0, {
        text: ''
      });
    },
    handleAdd4: function handleAdd4(val) {
      this.arbitrationContents.content.splice(val + 1, 0, {
        text: ''
      });
    },
    handleAdd5: function handleAdd5(val) {
      this.ruleContents.content.splice(val + 1, 0, {
        text: ''
      });
    },
    handleGetRule: function handleGetRule() {
      var textareas1 = document.getElementById("rule1").getElementsByTagName("textarea");
      var newText1 = [];
      if (textareas1.length > 0) {
        for (var i = 0; i < textareas1.length; i++) {
          newText1.push({
            text: textareas1[i].value
          });
        }
      }
      var textareas2 = document.getElementById("rule2").getElementsByTagName("textarea");
      var newText2 = [];
      if (textareas2.length > 0) {
        for (var i = 0; i < textareas2.length; i++) {
          newText2.push({
            text: textareas2[i].value
          });
        }
      }
      var textareas3 = document.getElementById("rule3").getElementsByTagName("textarea");
      var newText3 = [];
      if (textareas3.length > 0) {
        for (var i = 0; i < textareas3.length; i++) {
          newText3.push({
            text: textareas3[i].value
          });
        }
      }
      var textareas4 = document.getElementById("rule4").getElementsByTagName("textarea");
      var newText4 = [];
      if (textareas4.length > 0) {
        for (var i = 0; i < textareas4.length; i++) {
          newText4.push({
            text: textareas4[i].value
          });
        }
      }
      var textareas5 = document.getElementById("rule5").getElementsByTagName("textarea");
      var newText5 = [];
      if (textareas5.length > 0) {
        for (var i = 0; i < textareas5.length; i++) {
          newText5.push({
            text: textareas5[i].value
          });
        }
      }
      var title1 = '';
      var title2 = '';
      var title3 = '';
      var title4 = '';
      var title5 = '';
      if (this.partyInfos.title) {
        title1 = document.getElementById("title1").value;
      }
      if (this.partyContents.title) {
        title2 = document.getElementById("title2").value;
      }
      if (this.caseContents.title) {
        title3 = document.getElementById("title3").value;
      }
      if (this.arbitrationContents.title) {
        title4 = document.getElementById("title4").value;
      }
      if (this.ruleContents.title) {
        title5 = document.getElementById("title5").value;
      }
      if (newText1.length === 1 && newText1[0].text === '') {
        this.$message.error("当事人信息不能为空");
        return false;
      } else {
        var ruleObj = {};
        return ruleObj = {
          partyInfo: JSON.stringify({
            title: title1,
            content: newText1
          }),
          partyContent: JSON.stringify({
            title: title2,
            content: newText2
          }),
          caseContent: JSON.stringify({
            title: title3,
            content: newText3
          }),
          arbitrationContent: JSON.stringify({
            title: title4,
            content: newText4
          }),
          ruleContent: JSON.stringify({
            title: title5,
            content: newText5
          })
        };
      }
    },
    handleSave: function handleSave(type) {
      var _this = this;
      if (!document.getElementById("subject").value) {
        this.$message.error("裁决书主题不能为空");
        return;
      }
      if (!document.getElementById("title").value) {
        this.$message.error("裁决书标题不能为空");
        return;
      }
      if (!document.getElementById("caseNo").value) {
        this.$message.error("裁决书编号不能为空");
        return;
      }
      var ruleData = this.handleGetRule();
      if (ruleData) {
        var ruleStr = ruleData.partyInfo + ruleData.partyContent + ruleData.caseContent + ruleData.arbitrationContent + ruleData.ruleContent;
        if (!ruleStr) {
          this.$message.error("裁决书内容不能为空");
          return;
        }
        var params = {
          caseId: this.caseId,
          id: this.id,
          subject: document.getElementById("subject").value,
          title: document.getElementById("title").value,
          caseNo: document.getElementById("caseNo").value,
          partyInfo: ruleData.partyInfo,
          partyContent: ruleData.partyContent,
          caseContent: ruleData.caseContent,
          arbitrationContent: ruleData.arbitrationContent,
          ruleContent: ruleData.ruleContent,
          caseBatchRule: {
            rule: ruleStr
          }
        };
        if (type === 1) {
          this.loadingSave = true;
          this.loadingPass = false;
          this.loadingShow = false;
        }
        if (type === 2) {
          this.loadingSave = false;
          this.loadingPass = false;
          this.loadingShow = true;
        }
        if (type === 3) {
          this.loadingSave = false;
          this.loadingPass = true;
          this.loadingShow = false;
        }
        (0, _arbitrator.saveCaseRule)(params).then(function (res) {
          if (res.code === 1 && type === 1) {
            _this.$message({
              message: '保存成功',
              type: 'success'
            });
            _this.loadingSave = false;
            _this.loadingPass = false;
            _this.loadingShow = false;
            _this.upcomingVisible = false;
            _this.init();
          } else if (res.code === 1 && type === 2) {
            //保存预览
            _this.$message({
              message: '保存成功',
              type: 'success'
            });
            _this.loadingSave = false;
            _this.loadingSave = false;
            _this.loadingPass = false;
            _this.loadingShow = false;
            window.open(res.data);
          } else if (res.code === 1 && type === 3) {
            //保存通过
            _this.$message({
              message: '保存成功',
              type: 'success'
            });
            (0, _arbitrator.casesProcessAdopt)({
              caseId: _this.caseId
            }).then(function (res0) {
              if (res0.code === 1) {
                (0, _arbitrator.createRuleFile1)(_this.caseId).then(function (res1) {
                  if (res1.code === 1) {
                    _this.$message({
                      message: '审核成功',
                      type: 'success'
                    });
                    _this.upcomingVisible = false;
                    _this.loadingSave = false;
                    _this.loadingPass = false;
                    _this.loadingShow = false;
                    _this.init();
                  }
                });
              } else {
                _this.loadingPass = false;
                _this.$message.error(res0.msg);
              }
            });
          } else {
            _this.loadingSave = false;
            _this.loadingPass = false;
            _this.loadingShow = false;
            _this.$message.error(res.msg);
          }
        });
      }
    },
    init: function init() {
      var _this2 = this;
      this.loading = true;
      (0, _inquiry.findCaseCourtListByPage)({
        states: [3, 4],
        trialWay: 2,
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code === 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
          _this2.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    uploadDecision: function uploadDecision(data) {
      this.caseId = data.caseId;
      this.uploadDecisionVisible = true;
      this.uploadApi = process.env.VUE_APP_BASE_API + (0, _inquiry.uploadRuleFile)();
      this.uploadRule = '';
    },
    // 表格上传裁决书
    handleUpload: function handleUpload(data) {
      this.caseId = data.caseId;
      this.uploadVisible = true;
      this.uploadApi = process.env.VUE_APP_BASE_API + (0, _inquiry.uploadRuleFile)();
      this.uploadRule = '';
      // getRuleFile(data.caseId).then((res)=>{
      //     if(res.code == 1 && res.data){
      //         this.uploadRule = res.data;
      //     }
      // })
    },
    handleAvatarSuccess: function handleAvatarSuccess(res1, file) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));
      if (res.code === 1) {
        this.uploadRule = res.data;
      } else {
        // location.reload()
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      testmsg = testmsg.toLowerCase();
      var extension = testmsg === 'pdf';
      var extension2 = testmsg === 'docx';
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (!extension && !extension2) {
        this.$message.error('上传附件支持pdf或者docx格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传附件大小不能超过10MB!');
      }
      return (extension || extension2) && isLt2M;
    },
    // 上传笔录提交
    handleUploadSubmit: function handleUploadSubmit() {
      var _this3 = this;
      if (this.uploadRule) {
        var data = {
          caseId: this.caseId,
          filePath: this.uploadRule.filePath
        };
        this.singleUploading = true;
        (0, _inquiry.addTrialRecord)(data).then(function (res) {
          if (res.code === 1) {
            _this3.$message({
              message: '上传成功',
              type: 'success'
            });
            _this3.uploadVisible = false;
            _this3.singleUploading = false;
            _this3.init();
          } else {
            _this3.singleUploading = false;
            _this3.$message.error(res.msg);
          }
        }).catch(function (err) {
          _this3.singleUploading = false;
          _this3.$message.error(res.msg);
        });
      } else {
        this.singleUploading = false;
        this.$message.error('请先上传笔录');
      }
    },
    //表格审核事件
    handleReview: function handleReview(data) {
      var _this4 = this;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.caseId = data.caseId;
      (0, _arbitrator.getAwardDetail)(data.caseId).then(function (res) {
        if (res.code === 1) {
          _this4.caseAwardContentDto = res.data;
          _this4.id = res.data.id;
          if (res.data.partyInfos) {
            _this4.partyInfos = res.data.partyInfos;
          }
          if (res.data.partyContents) {
            _this4.partyContents = res.data.partyContents;
          }
          if (res.data.ruleContents) {
            _this4.ruleContents = res.data.ruleContents;
          }
          if (res.data.caseContents) {
            _this4.caseContents = res.data.caseContents;
          }
          if (res.data.arbitrationContents) {
            _this4.arbitrationContents = res.data.arbitrationContents;
          }
          _this4.upcomingVisible = true;
          loading.close();
        } else {
          _this4.$message.error(res.msg);
          loading.close();
        }
      });
    },
    handleRefuse: function handleRefuse() {
      this.refuseVisible = true;
    },
    handleRefuseSubmit: function handleRefuseSubmit(refuseForm) {
      var _this5 = this;
      this.$refs[refuseForm].validate(function (valid) {
        if (valid) {
          _this5.refuseForm.caseId = _this5.caseId;
          _this5.loading = true;
          (0, _arbitrator.arbitratorRuleRefuse)(_this5.refuseForm).then(function (res) {
            _this5.loading = false;
            if (res.code === 1) {
              _this5.$message({
                message: '提交成功',
                type: 'success'
              });
              _this5.refuseVisible = false;
              _this5.upcomingVisible = false;
              _this5.init();
            } else {
              _this5.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handlePass: function handlePass() {
      var _this6 = this;
      if (!this.rule) {
        this.$message.error("裁决书内容不能为空");
        return;
      }
      this.loadingPass = true;
      (0, _arbitrator.saveRuleText)({
        id: this.id,
        rule: this.rule
      }).then(function (res) {
        if (res.code === 1) {
          (0, _arbitrator.casesProcessAdopt)({
            caseId: _this6.caseId
          }).then(function (res0) {
            if (res0.code === 1) {
              (0, _arbitrator.createRuleFile1)(_this6.caseId).then(function (res1) {
                if (res1.code === 1) {
                  _this6.$message({
                    message: '审核成功',
                    type: 'success'
                  });
                  _this6.upcomingVisible = false;
                  _this6.loadingPass = false;
                  _this6.init();
                }
              });
            } else {
              _this6.loadingPass = false;
              _this6.$message.error(res0.msg);
            }
          });
        } else {
          _this6.$message.error(res.msg);
          _this6.loadingPass = false;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this7 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        states: [3, 4],
        limit: this.pageLimit,
        page: 1,
        trialWay: 2
      };
      this.conditionData = postData;
      (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
        if (res.code === 1) {
          _this7.tableData = res.rows;
          _this7.total = res.total;
        } else {
          _this7.$message.error(res.msg);
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this8 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 2;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 2,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code === 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this9 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 2;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 2,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code === 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;