"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'acceptAnalysis',
  data: function data() {
    return {
      searchDate: '',
      //搜索日期
      loading: false,
      tableData: [{
        data: '合同纠纷',
        name: '547',
        address1: '47',
        address2: '50%',
        address3: '54',
        address4: '50',
        address5: '99%',
        address6: '200',
        address7: '2',
        address8: '1'
      }],
      currentPage4: 2
    };
  },
  mounted: function mounted() {
    // 饼图
    this.myChart1 = this.$echarts.init(document.getElementById('causeAction'));
    var option1 = {
      tooltip: {
        trigger: 'item',
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      legend: {
        orient: 'vertical',
        x: 'left',
        data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
      },
      color: ['#E8684A', '#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16'],
      series: [{
        name: '访问来源',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: 'center'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [{
          value: 335,
          name: '直接访问'
        }, {
          value: 310,
          name: '邮件营销'
        }, {
          value: 234,
          name: '联盟广告'
        }, {
          value: 135,
          name: '视频广告'
        }, {
          value: 1548,
          name: '搜索引擎'
        }]
      }]
    };
    this.myChart1.setOption(option1);
    // 柱状图
    this.myChart = this.$echarts.init(document.getElementById('source'));
    var option = {
      title: {
        text: '案件来源分析',
        x: 'left'
      },
      xAxis: [{
        type: 'category',
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        axisLabel: {
          color: 'rgba(97, 165, 232, 1)'
        }
      }],
      yAxis: [{
        type: 'value',
        name: '##',
        axisLabel: {
          formatter: '{value}'
        }
      }, {
        type: 'value',
        name: '##',
        axisLabel: {
          formatter: '{value}'
        }
      }],
      series: [{
        name: '案件处理1',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
        itemStyle: {
          color: '#5B8FF9'
        }
      }, {
        name: '案件处理2',
        type: 'line',
        yAxisIndex: 1,
        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3],
        itemStyle: {
          color: '#5B8FF9'
        }
      }, {
        name: '案件处理2',
        type: 'line',
        yAxisIndex: 1,
        data: [4.5, 6.3, 2.0, 2.2, 3.3, 10.2, 20.3],
        itemStyle: {
          color: '#5AD8A6'
        }
      }]
    };
    this.myChart.setOption(option);
  },
  methods: {
    //日期改变
    dateChange: function dateChange() {},
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {}
  }
};
exports.default = _default;