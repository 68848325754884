var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "添加用户",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "180px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.dataForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.dataForm.id
            ? _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "account" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.span } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "账号" },
                        model: {
                          value: _vm.dataForm.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "account", $$v)
                          },
                          expression: "dataForm.account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataForm.id
            ? _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "account" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.span } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "账号", disabled: "" },
                        model: {
                          value: _vm.dataForm.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "account", $$v)
                          },
                          expression: "dataForm.account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机", prop: "mobile" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "手机" },
                    model: {
                      value: _vm.dataForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "mobile", $$v)
                      },
                      expression: "dataForm.mobile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "password" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "密码", "show-password": "" },
                    model: {
                      value: _vm.dataForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "password", $$v)
                      },
                      expression: "dataForm.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效时间", prop: "validityDay" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "有效时间" },
                    model: {
                      value: _vm.dataForm.validityDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "validityDay", $$v)
                      },
                      expression: "dataForm.validityDay",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "仲裁委", prop: "commId" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.dataForm.commId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "commId", $$v)
                        },
                        expression: "dataForm.commId",
                      },
                    },
                    _vm._l(_vm.commList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "角色", prop: "roleIds" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.dataForm.roleIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "roleIds", $$v)
                        },
                        expression: "dataForm.roleIds",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "state" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.dataForm.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "state", $$v)
                        },
                        expression: "dataForm.state",
                      },
                    },
                    _vm._l(_vm.stateList, function (item, name) {
                      return _c("el-option", {
                        attrs: { label: item, value: name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.cancel("dataForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAddAdmin()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }