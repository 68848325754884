var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-detail global-page-base-layout" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("span", [_vm._v("申请撤案")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("span", { staticClass: "tip" }, [
            _vm._v(
              "同角色有多个人员，并已被分配案件人员，被分配的人员负责审核。"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "promoters" },
            [
              _vm._v("可发起人："),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "申请人" } }, [
                    _vm._v("申请人"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "被申请人" } }, [
                    _vm._v("被申请人"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData1 } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "可发起状态" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "一审" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.name == "已提交"
                          ? _c(
                              "span",
                              [
                                _c("el-switch", {
                                  model: {
                                    value: scope.row.value1,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "value1", $$v)
                                    },
                                    expression: "scope.row.value1",
                                  },
                                }),
                                _c(
                                  "span",
                                  { class: scope.row.value1 ? "editFont" : "" },
                                  [_vm._v("无需审核")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.name == "已立案"
                          ? _c(
                              "span",
                              [
                                _c("el-switch", {
                                  model: {
                                    value: scope.row.value2,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "value2", $$v)
                                    },
                                    expression: "scope.row.value2",
                                  },
                                }),
                                scope.row.value2
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "editFont",
                                        on: { click: _vm.edit },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                !scope.row.value2
                                  ? _c("span", [_vm._v("编辑")])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow(
                                  scope.$index,
                                  _vm.tableData1
                                )
                              },
                            },
                          },
                          [_vm._v("移除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "add-row", on: { click: _vm.addRow } }, [
            _vm._v("添加行"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "edit-popup",
          attrs: { title: "编辑审核", visible: _vm.editVisible },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("span", { staticClass: "tip" }, [_vm._v("*")]),
              _vm._v("审核人员：\n      "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "70%" },
                  attrs: { placeholder: "请选择" },
                  on: {
                    change: function ($event) {
                      return _vm.transferAuditorChange(_vm.transferForm.auditor)
                    },
                  },
                  model: {
                    value: _vm.auditor,
                    callback: function ($$v) {
                      _vm.auditor = $$v
                    },
                    expression: "auditor",
                  },
                },
                _vm._l(_vm.auditorList, function (item, index) {
                  return _c("el-option", {
                    attrs: { label: item.name, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "font-title" }, [_vm._v("附加字段")]),
          _vm._v(" "),
          _c("span", { staticClass: "font-tip" }, [
            _vm._v("审核时需要额外填写的字段，可以设置是否必填和默认值。"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.fontTableData },
            },
            [
              _c("el-table-column", {
                attrs: { label: "字段名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { type: "text" },
                          model: {
                            value: scope.row.fontName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "fontName", $$v)
                            },
                            expression: "scope.row.fontName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "字段类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "60%" },
                            model: {
                              value: scope.row.fontType,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "fontType", $$v)
                              },
                              expression: "scope.row.fontType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "按百分比(%)",
                                value: "按百分比(%)",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "固定金额(元)",
                                value: "固定金额(元)",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否必填" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: scope.row.radio,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "radio", $$v)
                              },
                              expression: "scope.row.radio",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 6 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow(
                                  scope.$index,
                                  _vm.fontTableData
                                )
                              },
                            },
                          },
                          [_vm._v("\n              移除\n            ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "add-row" }, [_vm._v("添加字段")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sumbitTransferForm()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }