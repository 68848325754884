"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppMain", {
  enumerable: true,
  get: function get() {
    return _AppMain.default;
  }
});
Object.defineProperty(exports, "Navbar", {
  enumerable: true,
  get: function get() {
    return _Navbar.default;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _Sidebar.default;
  }
});
Object.defineProperty(exports, "TagsView", {
  enumerable: true,
  get: function get() {
    return _index.default;
  }
});
var _Navbar = _interopRequireDefault(require("./Navbar"));
var _index = _interopRequireDefault(require("./TagsView/index.vue"));
var _Sidebar = _interopRequireDefault(require("./Sidebar"));
var _AppMain = _interopRequireDefault(require("./AppMain"));