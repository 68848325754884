var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-container" },
    [
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.deviceType))]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "select",
          attrs: { placeholder: _vm.deviceType },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.activeDeviceId,
            callback: function ($$v) {
              _vm.activeDeviceId = $$v
            },
            expression: "activeDeviceId",
          },
        },
        _vm._l(_vm.deviceList, function (item) {
          return _c("el-option", {
            key: item.deviceId,
            attrs: { label: item.label, value: item.deviceId },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }