var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "arbitrator-main global-page-base-layout" },
    [
      _c(
        "div",
        { staticClass: "add-people" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoCreate } },
            [_vm._v("添加人员")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n              所属仲裁委：\n              "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          return _vm.roleChange(_vm.rolevalue)
                        },
                      },
                      model: {
                        value: _vm.rolevalue,
                        callback: function ($$v) {
                          _vm.rolevalue = $$v
                        },
                        expression: "rolevalue",
                      },
                    },
                    _vm._l(_vm.commiteeArr, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            false ? _c("el-col", { attrs: { span: 12 } }) : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _vm._v("\n              姓   名：\n               "),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n                 状态：\n                 "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择状态" },
                      on: {
                        change: function ($event) {
                          return _vm.stateChange(_vm.statevalue)
                        },
                      },
                      model: {
                        value: _vm.statevalue,
                        callback: function ($$v) {
                          _vm.statevalue = $$v
                        },
                        expression: "statevalue",
                      },
                    },
                    _vm._l(_vm.stateArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em", "margin-top": "20px" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "clear-all", on: { click: _vm.clearAllConditon } },
              [_vm._v("清空筛选条件")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "仲裁员名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "commName", label: "归属仲裁委名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "expertise", label: "专长" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "jobs", label: "职务" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "手机" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(
                              scope.row.status == 1 ? "已启用" : "已禁用"
                            ) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.deletePerson(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.checkPerson(scope.row, "修改人员")
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.checkPerson(scope.row, "查看人员")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm._v(" "),
                        !scope.row.adminId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.foundAccount(
                                      scope.row,
                                      "创建账号"
                                    )
                                  },
                                },
                              },
                              [_vm._v("创建账号")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.adminId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.foundAccount(
                                      scope.row,
                                      "查看账号"
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看账号")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "addperson",
          attrs: {
            title: _vm.addtitle,
            visible: _vm.addPersonVisible,
            top: "5vh",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addPersonVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addPersonForm",
              attrs: {
                model: _vm.addPersonForm,
                "label-position": "left",
                "label-width": "120px",
                rules: _vm.addPersonRules,
              },
            },
            [
              _c("span", [_vm._v("个人信息")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请使用真实姓名",
                    },
                    model: {
                      value: _vm.addPersonForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPersonForm, "name", $$v)
                      },
                      expression: "addPersonForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "phone" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addPersonForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPersonForm, "phone", _vm._n($$v))
                      },
                      expression: "addPersonForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("仲裁员专属信息")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属仲裁机构", prop: "commitee" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "addpersonform-item",
                      attrs: { size: "medium", placeholder: "请选择" },
                      model: {
                        value: _vm.addPersonForm.commitee,
                        callback: function ($$v) {
                          _vm.$set(_vm.addPersonForm, "commitee", $$v)
                        },
                        expression: "addPersonForm.commitee",
                      },
                    },
                    _vm._l(_vm.commiteeArr, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专长", prop: "speciality" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { autocomplete: "off", placeholder: "专长仲裁领域" },
                    model: {
                      value: _vm.addPersonForm.speciality,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPersonForm, "speciality", $$v)
                      },
                      expression: "addPersonForm.speciality",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "address" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { placeholder: "详细地址", autocomplete: "off" },
                    model: {
                      value: _vm.addPersonForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPersonForm, "address", $$v)
                      },
                      expression: "addPersonForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "职务", prop: "post" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { autocomplete: "off", placeholder: "所属职务" },
                    model: {
                      value: _vm.addPersonForm.post,
                      callback: function ($$v) {
                        _vm.$set(_vm.addPersonForm, "post", $$v)
                      },
                      expression: "addPersonForm.post",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仲裁员状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "addpersonform-item",
                      model: {
                        value: _vm.addPersonForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addPersonForm, "status", $$v)
                        },
                        expression: "addPersonForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.addtitle != "查看人员"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitPartyForm("addPersonForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "addaccount",
          attrs: {
            title: _vm.addAccountTitle,
            visible: _vm.addAccountVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addAccountVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addAccountForm",
              attrs: {
                model: _vm.addAccountForm,
                "label-position": "left",
                "label-width": "120px",
                rules: _vm.addAccountFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录账号", prop: "account" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { autocomplete: "off", placeholder: "" },
                    model: {
                      value: _vm.addAccountForm.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.addAccountForm, "account", _vm._n($$v))
                      },
                      expression: "addAccountForm.account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addAccountTitle != "查看账号"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "初始密码", prop: "password" } },
                    [
                      _c("el-input", {
                        staticClass: "addpersonform-item",
                        attrs: {
                          autocomplete: "off",
                          placeholder: "员工第一次登录时会强制修改",
                          "show-password": true,
                        },
                        model: {
                          value: _vm.addAccountForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.addAccountForm, "password", $$v)
                          },
                          expression: "addAccountForm.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "phone" } },
                [
                  _c("el-input", {
                    staticClass: "addpersonform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addAccountForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.addAccountForm, "phone", _vm._n($$v))
                      },
                      expression: "addAccountForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addAccountTitle != "查看账号"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上传仲裁员签名" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            left: "-125px",
                            top: "0",
                            color: "#F56C6C",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.action,
                            "on-preview": _vm.handlePreview,
                            "on-remove": _vm.handleRemove,
                            "before-remove": _vm.beforeRemove,
                            "on-success": _vm.handleSuccess,
                            headers: _vm.headers,
                            accept: ".jpg,.png",
                            multiple: "",
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "medium", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("只能上传jpg/png文件，且不超过500kb")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.uploadTip
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#F56C6C",
                                "font-size": "10px",
                              },
                            },
                            [_vm._v("请完善必要信息和上传符合规范文件")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addAccountTitle != "查看账号"
                ? _c("el-form-item", { attrs: { label: "查看签名文件" } }, [
                    _c("a", { attrs: { href: _vm.sign } }, [
                      _vm._v(_vm._s(_vm.CommonPath)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.addAccountTitle != "查看账号"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAccountForm("addAccountForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }