"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inquiry = require("@/api/inquiry");
var _dictionary = require("@/config/dictionary");
var _partyInfo = _interopRequireDefault(require("@/views/case/handing/partyInfo"));
var _showImg = require("@/utils/showImg.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "inquiryDetail",
  components: {
    PartyInfo: _partyInfo.default
  },
  data: function data() {
    return {
      moveLimit: 5,
      auditLimit: 5,
      handleLimit: 5,
      caseState: _dictionary.dictionary.caseState,
      //案件状态
      caseExamineState: _dictionary.dictionary.caseExamineState,
      //案件审核状态
      caseExamineType: _dictionary.dictionary.caseExamineType,
      //案件审核类型
      identiyState: _dictionary.dictionary.identiyState,
      //案件识别类型
      partyIdType: _dictionary.dictionary.partyIdType,
      //申请人类型
      listSendType: {
        '1': '短信',
        '2': '邮件',
        '3': '服务平台'
      },
      serviceType: {
        '1': '立案',
        '2': '组庭',
        '3': '开庭',
        '4': '裁决',
        '5': '撤案',
        '6': '仲裁庭调查取证',
        '7': "仲裁行使权利通知",
        '8': "不予立案通知",
        '9': "缴费通知书送达"
      },
      clicked: 'caseStatus',
      casedetailid: '',
      detailData: '',
      //总数据
      caseReply: '',
      //答辩状数据   ==>因为返回detailData数据里面，它自成一个对象，必须要声明
      endCaseDocument: '',
      //结案文书数据  ==>因为返回detailData数据里面，它自成一个对象，必须要声明
      trialRecordDocument: {
        fileName: '',
        aliyunOssHref: ''
      },
      //开庭笔录数据
      courtInfo: "",
      //仲裁庭信息
      courtEvidence: [],
      //仲裁庭取证质证
      MaterialData1: [],
      //申请人材料列表
      MaterialData2: [],
      //被申请人材料列表
      caseMoveData: [],
      //案件流转记录
      EvidentMaterialData: [],
      //仲裁庭取证材料
      caseServiceData: [],
      //案件送达记录
      rightTableData: [],
      //权利行使记录
      auditRecordData: [],
      //审核记录
      paymentProofData: [],
      //缴费凭证
      cancelFormShow: false,
      //作废弹窗显示
      cancelform: {
        //作废拒绝弹框的内容
        name: ''
      },
      //申请人案件材料分页
      MaterialTotal1: 0,
      MaterialCurrentPage1: 1,
      // 被申请人案件材料分页
      MaterialTotal2: 0,
      MaterialCurrentPage2: 1,
      // 仲裁庭取证材料分页
      EvidentMaterialTotal: 0,
      EvidentMaterialCurrentPage: 1,
      //行使权利分页
      RightTotal: 0,
      RightCurrentPage: 1,
      //案件流转分页
      MoveTotal: 0,
      MoveCurrentPage: 1,
      //案件审核
      AudtingTotal: 0,
      AudtingCurrentPage: 1,
      //案件送达
      ServiceTotal: 0,
      ServiceCurrentPage: 1,
      partyInfoVisible: false,
      //当事人信息
      fileType: _dictionary.dictionary.fileType,
      materialState: _dictionary.dictionary.materialState,
      sendMsgType: _dictionary.dictionary.sendMsgType,
      arbitrationRequestFlag: false,
      arbitrationAruthFlag: false,
      electricsignVisible: false,
      //电签弹窗
      electricsignFlag: false,
      //是否pdf电签
      electricsignData: '',
      //电签验证信息
      electricsignDataFlag: true,
      //是否有电签信息
      applyWithdrawalVisible: false,
      //申请撤案
      applyWithdrawalForm: {
        reason: "",
        caseId: ''
      },
      materilExamLoading: false,
      formLabelWidth: '100px'
    };
  },
  created: function created() {
    var _this = this;
    this.casedetailid = this.$route.params.caseId;
    //请求详情数据
    (0, _inquiry.getDetail)(this.casedetailid).then(function (res) {
      if (res.code == 1) {
        _this.detailData = res.data;
        _this.caseReply = res.data.caseReply;
        _this.endCaseDocument = res.data.endCaseDocument;
        _this.courtInfo = res.data.courtInfo;
        _this.EvidentMaterialData = res.data.caseArbitralFilesDtoList;
        _this.MaterialData1 = res.data.appProofFileList;
        _this.courtEvidence = res.data.caseArbitralExamDtoList;
        _this.paymentProofData = res.data.casePaymentProofDtoList;
        if (_this.detailData.arbitrationRequest.length > 50) {
          _this.arbitrationRequestFlag = true;
        }
        if (_this.detailData.arbitrationAruth.length > 50) {
          _this.arbitrationAruthFlag = true;
        }
        //判断是否pdf电签
        //   this.MaterialData1.forEach(item=>{
        //       console.log(item.filePath) //y
        //       console.log(ifShowImg(item.filePath)) //y
        //       // this.electricsignFlag = ifShowImg(item.filePath);
        //       if(ifShowImg(item.filePath)==false){ //是pdf
        //           item.electricsignFlag = "1";
        //       }else {
        //           item.electricsignFlag = "0";
        //
        //       }
        //   })
      }
    });
    //请求申请人案件材料  --丢弃
    // getMaterial({type:1,caseId:this.casedetailid,limit:10000,page:1}).then((res)=>{
    //   if(res.code==1){

    //     this.MaterialData1 = res.rows;
    //     this.MaterialTotal1 = res.total;
    //   }
    // })
    //请求被申请人案件材料
    (0, _inquiry.getMaterial)({
      type: 2,
      caseId: this.casedetailid,
      limit: 10000,
      page: 1
    }).then(function (res) {
      if (res.code == 1) {
        _this.MaterialData2 = res.rows;
        _this.MaterialTotal2 = res.total;
      }
    });
    // 请求案件流转列表
    (0, _inquiry.getCasemoveRecord)({
      caseId: this.casedetailid,
      limit: 5,
      page: 1
    }).then(function (res) {
      if (res.code == 1) {
        _this.caseMoveData = res.rows;
        _this.MoveTotal = res.total;
      }
    });
    //请求案件送达记录列表
    (0, _inquiry.getCaseService)({
      caseId: this.casedetailid,
      limit: 5,
      page: 1
    }).then(function (res) {
      if (res.code == 1) {
        _this.caseServiceData = res.rows;
        _this.ServiceTotal = res.total;
      }
    });
    // 请求行使权利列表
    (0, _inquiry.getCasePower)({
      caseId: this.casedetailid,
      limit: 10000,
      page: 1
    }).then(function (res) {
      _this.rightTableData = res.rows;
      _this.RightTotal = res.total;
    });
    //审核记录列表
    (0, _inquiry.getCaseExmainRecord)({
      caseId: this.casedetailid,
      limit: 5,
      page: 1
    }).then(function (res) {
      if (res.code == 1) {
        _this.auditRecordData = res.rows;
        _this.AudtingTotal = res.total;
      }
    });
    //查询庭审笔录
    (0, _inquiry.findTrialRecord)(this.casedetailid).then(function (res) {
      if (res.code == 1 && res.data) {
        _this.trialRecordDocument = res.data;
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    dealPdfSrc: function dealPdfSrc(src) {
      return pdf.createLoadingTask(src);
    },
    // 右侧菜单栏点击
    anchorTo: function anchorTo(data) {
      document.getElementById(data).scrollIntoView({
        block: "center"
      });
      this.clicked = data;
    },
    //查看当事人信息
    getPartyInfo: function getPartyInfo(data) {
      var _this2 = this;
      this.partyInfoVisible = true;
      this.$nextTick(function () {
        _this2.$refs.partyInfo.init(data);
      });
    },
    // 点击表格作废按钮
    handleClicktoCancel: function handleClicktoCancel(data) {
      this.cancelFormShow = true;
    },
    //申请人案件材料分页
    handleMaterialSizeChange1: function handleMaterialSizeChange1(val) {},
    handleMaterialCurrentChange1: function handleMaterialCurrentChange1(val) {
      var _this3 = this;
      (0, _inquiry.getMaterial)({
        type: 1,
        caseId: this.casedetailid,
        limit: 5,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this3.MaterialData1 = res.rows;
          _this3.MaterialTotal1 = res.total;
        }
      });
    },
    //被申请人案件材料分页
    handleMaterialSizeChange2: function handleMaterialSizeChange2(val) {},
    handleMaterialCurrentChange2: function handleMaterialCurrentChange2(val) {
      var _this4 = this;
      (0, _inquiry.getMaterial)({
        type: 2,
        caseId: this.casedetailid,
        limit: 5,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this4.MaterialData1 = res.rows;
          _this4.MaterialTotal1 = res.total;
        }
      });
    },
    //行使权利分页
    handleRightSizeChange: function handleRightSizeChange(val) {},
    handleRightCurrentChange: function handleRightCurrentChange(val) {
      var _this5 = this;
      (0, _inquiry.getCasePower)({
        caseId: this.casedetailid,
        limit: 5,
        page: val
      }).then(function (res) {
        _this5.rightTableData = res.rows;
        _this5.RightTotal = res.total;
      });
    },
    //案件流转
    handleMoveSizeChange: function handleMoveSizeChange(val) {
      this.moveLimit = val;
      this.handleMoveCurrentChange(1);
    },
    handleMoveCurrentChange: function handleMoveCurrentChange(val) {
      var _this6 = this;
      (0, _inquiry.getCasemoveRecord)({
        caseId: this.casedetailid,
        limit: this.moveLimit,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this6.caseMoveData = res.rows;
          _this6.MoveTotal = res.total;
        }
      });
    },
    //案件审核
    handleAudtingSizeChange: function handleAudtingSizeChange(val) {
      this.auditLimit = val;
      this.handleAudtingCurrentChange(1);
    },
    handleAudtingCurrentChange: function handleAudtingCurrentChange(val) {
      var _this7 = this;
      (0, _inquiry.getCaseExmainRecord)({
        caseId: this.casedetailid,
        limit: this.auditLimit,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this7.auditRecordData = res.rows;
          _this7.AudtingTotal = res.total;
        }
      });
    },
    //案件送达
    handleServiceSizeChange: function handleServiceSizeChange(val) {
      this.handleLimit = val;
      this.handleServiceCurrentChange(1);
    },
    handleServiceCurrentChange: function handleServiceCurrentChange(val) {
      var _this8 = this;
      (0, _inquiry.getCaseService)({
        caseId: this.casedetailid,
        limit: this.handleLimit,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this8.caseServiceData = res.rows;
          _this8.ServiceTotal = res.total;
        }
      });
    },
    //电签验证
    handleElectricsign: function handleElectricsign(val) {
      var _this9 = this;
      this.electricsignVisible = true;
      (0, _inquiry.signatureVerify)(val.id).then(function (res) {
        if (res.code == 1) {
          _this9.electricsignDataFlag = true;
          _this9.electricsignData = res.data;
          _this9.electricsignData.verify += '';
        } else {
          _this9.electricsignDataFlag = false;
        }
      });
    },
    handleElectricsignSubmit: function handleElectricsignSubmit() {
      this.electricsignVisible = false;
    },
    //申请撤案
    applyWithdrawal: function applyWithdrawal() {
      this.applyWithdrawalVisible = true;
      if (this.$refs['applyWithdrawalForm']) {
        this.$refs['applyWithdrawalForm'].resetFields();
      }
    },
    //申请撤案 提交
    submitWithdrawal: function submitWithdrawal(formName) {
      var _this10 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this10.materilExamLoading = true;
          var params = {
            caseId: _this10.casedetailid,
            reason: _this10.applyWithdrawalForm.reason
          };
          (0, _inquiry.submitRevoke)(params).then(function (res) {
            _this10.materilExamLoading = false;
            if (res.code == 1) {
              _this10.$message({
                message: '提交成功',
                type: 'success'
              });
              _this10.applyWithdrawalVisible = false;
              _this10.$router.go(-1);
            } else {
              _this10.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;