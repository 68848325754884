var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-page-base-layout" },
    [
      _c("el-main", [
        _c(
          "div",
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "item" },
              [
                _c("el-col", { attrs: { span: 20 } }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭时间：" + _vm._s(item.time)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭地点：" + _vm._s(item.address)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("仲裁员：" + _vm._s(item.peo1)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("书记员：" + _vm._s(item.peo2)),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c(
                    "div",
                    { staticClass: "item-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.gotoCourtSign(item)
                            },
                          },
                        },
                        [_vm._v("进入庭审")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.caseName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v(
                      "\n            开庭时间：" +
                        _vm._s(item.day) +
                        " " +
                        _vm._s(item.timer) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭地点：" + _vm._s(item.aisleName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("庭审状态：" + _vm._s(_vm.videoState[item.state])),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("仲裁员：" + _vm._s(item.arbitratorName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("书记员：" + _vm._s(item.handingSecretaryName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c(
                    "div",
                    { staticClass: "item-btn" },
                    [
                      item.state == 0 || item.state == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoCourtSign(item)
                                },
                              },
                            },
                            [_vm._v("进入庭审\n            ")]
                          )
                        : _c("span", { attrs: { type: "primary" } }, [
                            _vm._v("庭审已完结"),
                          ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "global-page-base-layout",
        },
        [
          _c("Caseconditons", {
            attrs: { caseReplyCondition: false },
            on: {
              choose: _vm.checkList,
              cleanCondition: _vm.cleanConditionData,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "handing-table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    "header-cell-style": { background: _vm.tableHeaderColor },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "案号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.caseNo
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "link-style",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(
                                          scope.row.caseId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.caseNo))]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "link-style",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(
                                          scope.row.caseId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("暂无案号")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "caseId", label: "受理号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "registerApplyTime", label: "申请日期" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "appName", label: "申请人" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "resName", label: "被申请人" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "controversyAmount", label: "争议金额(元)" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "state", label: "案件状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.caseState[scope.row.state]) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "handingSecretaryName", label: "办案秘书" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "arbitratorName", label: "仲裁员" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "registerSendTime", label: "立案时间" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "composeTime", label: "组庭时间" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("[编辑笔录]")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpload(scope.row)
                                  },
                                },
                              },
                              [_vm._v("[上传笔录]")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleInVideo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("[进入庭审]")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": _vm.pageSizes,
                      "page-size": 10,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }