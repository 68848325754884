var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-avoid global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: {
          rowSecondName: "立案日期",
          rowThirdName: "开庭日期",
          caseReplyCondition: false,
        },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "case-avoid-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n             " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n           "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("申请回避")]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("已申请回避")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请回避",
            visible: _vm.avoidFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.avoidFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "回避事由",
                    prop: "reason",
                    rules: [{ required: true, message: "请填写回避事由" }],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.avoidFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }