var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "wrap", staticClass: "wrap" }, [
    _c(
      "div",
      {
        ref: "content",
        staticClass: "content",
        class: _vm.animationClass,
        style: _vm.contentStyle,
        on: {
          animationend: _vm.onAnimationEnd,
          webkitAnimationEnd: _vm.onAnimationEnd,
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }