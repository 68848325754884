"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      value: '2019-09-12',
      arr: [{
        date: '2019-09-05',
        num: '5个案件'
      }, {
        date: '2019-09-06',
        num: '5个案件'
      }, {
        date: '2019-10-02',
        num: '5个案件'
      }, {
        date: '2019-09-07',
        num: '5个案件'
      }],
      dialogTableVisible: false,
      gridData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    initDate: function initDate(val) {
      var result;
      this.arr.forEach(function (item, index) {
        if (item.date == val) {
          result = item.num;
        }
      });
      return result;
    },
    come: function come(val) {
      this.dialogTableVisible = true;
    }
  }
};
exports.default = _default;