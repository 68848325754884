var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "case-deal-box",
      attrs: {
        title: "不予受理",
        visible: _vm.show,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "不受理理由" } },
            [
              _c("el-input", {
                staticStyle: { width: "70%" },
                attrs: {
                  type: "textarea",
                  rows: "5",
                  placeholder: "请填写不受理理由",
                  resize: "none",
                },
                model: {
                  value: _vm.reason,
                  callback: function ($$v) {
                    _vm.reason = $$v
                  },
                  expression: "reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer text-right dealform-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHide } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确定\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }