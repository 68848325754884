var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "case-detail" },
        [
          _c("el-col", { staticClass: "detail", attrs: { span: 20 } }, [
            _c("div", { staticClass: "anchor", attrs: { id: "caseStatus" } }, [
              _c("span", { staticClass: "case-detail-state" }, [
                _vm._v(_vm._s(_vm.caseState[_vm.detailData.state])),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "ai" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("AI识别信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm._v("当事人识别状态：未识别"),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm._v("备注：暂无"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseAbout" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("案件概况")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案件受理号：" + _vm._s(_vm.detailData.caseId)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("提交时间：" + _vm._s(_vm.detailData.ctime)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案由：" + _vm._s(_vm.detailData.reason)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "办案秘书：" +
                            _vm._s(_vm.detailData.handlingSecretaryName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "仲裁费：" + _vm._s(_vm.detailData.arbitrationAmount)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案号：" + _vm._s(_vm.detailData.caseNo)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "立案时间：" + _vm._s(_vm.detailData.registerTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "适用程序：" +
                            _vm._s(_vm.detailData.applicableProcedure)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "标的额：" + _vm._s(_vm.detailData.controversyAmount)
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "casePerson" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("申请人信息")]),
                _vm._v(" "),
                _vm.detailData.appPartyList == ""
                  ? _c("el-main", [_vm._v("\n          暂无\n        ")])
                  : _vm._l(_vm.detailData.appPartyList, function (app) {
                      return _c(
                        "el-main",
                        { staticClass: "multiple-party" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "case-txt" },
                              [
                                _vm._v("申请人名称：\n              "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPartyInfo(app.partyId)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(app.name))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            app.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "统一社会信用代码：" + _vm._s(app.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            app.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v("身份证件号：" + _vm._s(app.idcard)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            app.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "法人名称：" + _vm._s(app.companyChargeName)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("手机号：" + _vm._s(app.phone)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v(
                                "企业/个人：" + _vm._s(_vm.partyType[app.type])
                              ),
                            ]),
                            _vm._v(" "),
                            app.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "公司地址：" + _vm._s(app.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            app.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v("地址：" + _vm._s(app.certAddress)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("邮箱地址：" + _vm._s(app.email)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [
                  _vm._v("申请人代理人信息"),
                ]),
                _vm._v(" "),
                _vm.detailData.appProxyList == ""
                  ? _c("el-main", [_vm._v("\n          暂无\n        ")])
                  : _vm._l(_vm.detailData.appProxyList, function (appProxy) {
                      return _c(
                        "el-main",
                        { staticClass: "multiple-party" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "case-txt" },
                              [
                                _vm._v("代理人名称：\n              "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPartyInfo(
                                          appProxy.partyId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(appProxy.name) + "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            appProxy.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "统一社会信用代码：" +
                                      _vm._s(appProxy.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            appProxy.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "代理人证件号：" + _vm._s(appProxy.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("手机号：" + _vm._s(appProxy.phone)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v(
                                "企业/个人：" +
                                  _vm._s(_vm.partyType[appProxy.type])
                              ),
                            ]),
                            _vm._v(" "),
                            appProxy.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "公司地址：" + _vm._s(appProxy.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            appProxy.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "地址：" + _vm._s(appProxy.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("邮箱地址：" + _vm._s(appProxy.email)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [_vm._v("被申请人信息")]),
                _vm._v(" "),
                _vm.detailData.resPartyList == ""
                  ? _c("el-main", [_vm._v("\n          暂无\n        ")])
                  : _vm._l(_vm.detailData.resPartyList, function (res) {
                      return _c(
                        "el-main",
                        { staticClass: "multiple-party" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "case-txt" },
                              [
                                _vm._v("被申请人名称：\n              "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPartyInfo(res.partyId)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(res.name))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            res.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "统一社会信用代码：" + _vm._s(res.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            res.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "被申请人证件号：" + _vm._s(res.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("手机号：" + _vm._s(res.phone)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v(
                                "企业/个人：" + _vm._s(_vm.partyType[res.type])
                              ),
                            ]),
                            _vm._v(" "),
                            res.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "公司地址：" + _vm._s(res.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            res.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v("地址：" + _vm._s(res.certAddress)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("邮箱地址：" + _vm._s(res.email)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [
                  _vm._v("被申请人代理人信息"),
                ]),
                _vm._v(" "),
                _vm.detailData.resProxyList == ""
                  ? _c("el-main", [_vm._v("\n          暂无\n        ")])
                  : _vm._l(_vm.detailData.resProxyList, function (resProxy) {
                      return _c(
                        "el-main",
                        { staticClass: "multiple-party" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "case-txt" },
                              [
                                _vm._v("代理人名称：\n              "),
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getPartyInfo(
                                          resProxy.partyId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(resProxy.name) + "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            resProxy.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "统一社会信用代码：" +
                                      _vm._s(resProxy.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            resProxy.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "代理人证件号：" + _vm._s(resProxy.idcard)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("手机号：" + _vm._s(resProxy.phone)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v(
                                "企业/个人：" +
                                  _vm._s(_vm.partyType[resProxy.type])
                              ),
                            ]),
                            _vm._v(" "),
                            resProxy.type == 0
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "公司地址：" + _vm._s(resProxy.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            resProxy.type == 1
                              ? _c("div", { staticClass: "case-txt" }, [
                                  _vm._v(
                                    "地址：" + _vm._s(resProxy.certAddress)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-txt" }, [
                              _vm._v("邮箱地址：" + _vm._s(resProxy.email)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseItem" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁事项")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "18px",
                            },
                          },
                          [_vm._v("仲裁请求")]
                        ),
                        _vm._v("："),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailData.arbitrationRequest)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "18px",
                            },
                          },
                          [_vm._v("事实和理由")]
                        ),
                        _vm._v("："),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailData.arbitrationAruth)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseMaterial" } },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._v("申请人案件材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.appMaterialData },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "partyName", label: "提交人" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileName", label: "名称" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            underline: false,
                                            type: "primary",
                                            href: scope.row.aliyunOssHref,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(scope.row.fileName) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "discernState",
                                label: "识别状态",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.discernState == "-1"
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.identiyState[
                                                    scope.row.discernState
                                                  ]
                                                )
                                              ),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.identiyState[
                                                  scope.row.discernState
                                                ]
                                              )
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "dash", attrs: { span: 4 } }, [
            _c("ul", { staticClass: "anchor-link" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseStatus" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseStatus")
                      },
                    },
                  },
                  [_vm._v("案件状态")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseAbout" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseAbout")
                      },
                    },
                  },
                  [_vm._v("案件概况")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "casePerson" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("casePerson")
                      },
                    },
                  },
                  [_vm._v("当事人信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseItem" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseItem")
                      },
                    },
                  },
                  [_vm._v("仲裁事项")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseMaterial" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseMaterial")
                      },
                    },
                  },
                  [_vm._v("案件材料")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "作废理由",
            visible: _vm.cancelFormShow,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cancelFormShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "cancelform",
              attrs: { model: _vm.cancelform, rules: _vm.cancelRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请输入作废理由",
                      resize: "none",
                    },
                    model: {
                      value: _vm.cancelform.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.cancelform, "reason", $$v)
                      },
                      expression: "cancelform.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleInvalid("cancelform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "case-deal-box",
          attrs: {
            title: "缴费送达",
            visible: _vm.casePayFormShow,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.casePayFormShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dealform",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dealform,
                rules: _vm.dealPayRules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "案号", prop: "caseNo" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { span: _vm.span },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "选填，不填写案号系统会自动分配",
                        },
                        model: {
                          value: _vm.dealform.caseNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.dealform, "caseNo", $$v)
                          },
                          expression: "dealform.caseNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "案由", prop: "reasonId" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { span: _vm.span },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.dealform.reasonId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dealform, "reasonId", $$v)
                            },
                            expression: "dealform.reasonId",
                          },
                        },
                        _vm._l(_vm.reasonList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.reasonName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仲裁费", prop: "arbitryFree" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { data: _vm.arbitryData },
                    },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            prop: "controversyAmount",
                            label: "争议金额",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.dealform.controversyAmount) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "zcFee", label: "仲裁费" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { type: "input" },
                                  model: {
                                    value: _vm.dealform.zcFee,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dealform, "zcFee", $$v)
                                    },
                                    expression: "dealform.zcFee",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "slFee", label: "受理费" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { type: "input" },
                                  model: {
                                    value: _vm.dealform.slFee,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dealform, "slFee", $$v)
                                    },
                                    expression: "dealform.slFee",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "clFee", label: "处理费" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { type: "input" },
                                  model: {
                                    value: _vm.dealform.clFee,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dealform, "clFee", $$v)
                                    },
                                    expression: "dealform.clFee",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "缴费通知书" } },
                [
                  _c(
                    "el-link",
                    {
                      staticClass: "margin-right",
                      attrs: {
                        underline: false,
                        type: "primary",
                        href: _vm.dealform.href,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dealform.fileName) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下一级审核人", prop: "secretaryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.dealform.secretaryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealform, "secretaryId", $$v)
                        },
                        expression: "dealform.secretaryId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经办意见", prop: "opinion" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请填写经办意见",
                      resize: "none",
                    },
                    model: {
                      value: _vm.dealform.opinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealform, "opinion", $$v)
                      },
                      expression: "dealform.opinion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("img", {
            staticClass: "case-icon",
            attrs: { src: require("@/assets/case/compute.png"), alt: "" },
            on: {
              click: function ($event) {
                _vm.innerVisible = true
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.casePayFormShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.dealFormPayLoading },
                  on: {
                    click: function ($event) {
                      return _vm.reCreatePayBook("dealform")
                    },
                  },
                },
                [_vm._v("重新生成缴费通知书\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.dealFormShowLoading },
                  on: {
                    click: function ($event) {
                      return _vm.nextTodealFormShow2("dealform")
                    },
                  },
                },
                [_vm._v("送达缴费\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "case-deal-box",
          attrs: {
            title: "案件受理",
            visible: _vm.dealFormShow,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dealFormShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dealform",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dealform,
                rules: _vm.dealrules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "案号", prop: "caseNo" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { span: _vm.span },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "选填，不填写案号系统会自动分配",
                        },
                        model: {
                          value: _vm.dealform.caseNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.dealform, "caseNo", $$v)
                          },
                          expression: "dealform.caseNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "案由", prop: "reasonId" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { span: _vm.span },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.dealform.reasonId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dealform, "reasonId", $$v)
                            },
                            expression: "dealform.reasonId",
                          },
                        },
                        _vm._l(_vm.reasonList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.reasonName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "缴费信息", prop: "payInfo" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { data: _vm.arbitryData },
                    },
                    [
                      _c("el-table-column", { attrs: { label: "仲裁费" } }, [
                        _vm._v(
                          "\n            ￥：" +
                            _vm._s(_vm.dealform.caseAmount) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "实缴仲裁费" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.dealform.amount
                                  ? _c("span", [
                                      _vm._v(
                                        "￥：" + _vm._s(_vm.dealform.amount)
                                      ),
                                    ])
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("未缴仲裁费")]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "slFee", label: "缴费凭证" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.dealform.href
                                  ? _c(
                                      "el-link",
                                      {
                                        staticClass: "margin-right",
                                        attrs: {
                                          underline: false,
                                          type: "primary",
                                          href: _vm.dealform.href,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                缴费凭证\n              "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("-")]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "缴费时间" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm.dealform.payTime
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.dealform.payTime)),
                                    ])
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("-")]
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", { attrs: { label: "缴费备注" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.dealform.remarks) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下一级审核人", prop: "secretaryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.dealform.secretaryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealform, "secretaryId", $$v)
                        },
                        expression: "dealform.secretaryId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经办意见", prop: "opinion" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请填写经办意见",
                      resize: "none",
                    },
                    model: {
                      value: _vm.dealform.opinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealform, "opinion", $$v)
                      },
                      expression: "dealform.opinion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dealFormShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReject },
                },
                [_vm._v("不予受理 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.dealFormShowLoading },
                  on: {
                    click: function ($event) {
                      return _vm.confimPayToCaseShow("dealform")
                    },
                  },
                },
                [_vm._v("保存&转交办案\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "费用快算",
            visible: _vm.innerVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "compute-content" }, [
            _c(
              "div",
              { staticClass: "claim-count" },
              [
                _vm._v("\n        案件争议额：\n        "),
                _c("el-input", {
                  attrs: { placeholder: "请填写争议金额" },
                  model: {
                    value: _vm.dealform.controversyAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.dealform, "controversyAmount", $$v)
                    },
                    expression: "dealform.controversyAmount",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("元")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "claim-count" },
              [
                _vm._v("\n        案件受理费：\n        "),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.computePriceForm.slFee,
                    callback: function ($$v) {
                      _vm.$set(_vm.computePriceForm, "slFee", $$v)
                    },
                    expression: "computePriceForm.slFee",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("元")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "claim-count" },
              [
                _vm._v("\n        案件处理费：\n        "),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.computePriceForm.clFee,
                    callback: function ($$v) {
                      _vm.$set(_vm.computePriceForm, "clFee", $$v)
                    },
                    expression: "computePriceForm.clFee",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("元")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "claim-count" },
              [
                _vm._v("\n        总计仲裁费：\n        "),
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.computePriceForm.zcFee,
                    callback: function ($$v) {
                      _vm.$set(_vm.computePriceForm, "zcFee", $$v)
                    },
                    expression: "computePriceForm.zcFee",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("元")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.computePrice(_vm.dealform.controversyAmount)
                    },
                  },
                },
                [_vm._v("计算")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "案件受理",
            visible: _vm.dealFormShow2,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dealFormShow2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.dealform2, "label-width": "150px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "受理结果", prop: "source" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dealform2.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealform2, "source", $$v)
                        },
                        expression: "dealform2.source",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("通过审核"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("补充材料"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("不予受理"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dealform2.source == "1"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "dealformPass",
                      attrs: {
                        model: _vm.dealformPass,
                        rules: _vm.dealrulesPass,
                        "label-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "下一级审核人", prop: "secretaryId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "60%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.dealformPass.secretaryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dealformPass, "secretaryId", $$v)
                                },
                                expression: "dealformPass.secretaryId",
                              },
                            },
                            _vm._l(_vm.secretaryList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "经办意见", prop: "opinion" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "60%" },
                            attrs: {
                              type: "textarea",
                              rows: "5",
                              placeholder: "请填写经办意见",
                              resize: "none",
                            },
                            model: {
                              value: _vm.dealformPass.opinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.dealformPass, "opinion", $$v)
                              },
                              expression: "dealformPass.opinion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer text-right dealform-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dealFormShow2 = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.FilingPassLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilingPass("dealformPass")
                            },
                          },
                        },
                        [_vm._v("确定&通过\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dealform2.source == "2"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "dealformMaterial",
                      attrs: {
                        model: _vm.dealformMaterial,
                        rules: _vm.dealrulesMaterial,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "补充类型", prop: "recordContent" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.dealformMaterial.recordContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dealformMaterial,
                                    "recordContent",
                                    $$v
                                  )
                                },
                                expression: "dealformMaterial.recordContent",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "身份证明", value: "身份证明" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "借款关系", value: "借款关系" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "打款凭证", value: "打款凭证" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "质押关系", value: "质押关系" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "其他", value: "其他" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "补充意见", prop: "opinion" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              rows: "5",
                              placeholder: "请选择补充意见",
                              resize: "none",
                            },
                            model: {
                              value: _vm.dealformMaterial.opinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.dealformMaterial, "opinion", $$v)
                              },
                              expression: "dealformMaterial.opinion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer text-right dealform-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dealFormShow2 = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.FilingPassLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilingAddMaterial(
                                "dealformMaterial"
                              )
                            },
                          },
                        },
                        [_vm._v("\n          确定&补充材料\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dealform2.source == "3"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "dealformRefuse",
                      attrs: {
                        model: _vm.dealformRefuse,
                        rules: _vm.dealrulesRefuse,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          attrs: { label: "补充意见" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "text",
                              placeholder: "请选择补充意见",
                              resize: "none",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "不受理说明", prop: "opinion" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              rows: "5",
                              placeholder: "请填写不受理理由",
                              resize: "none",
                            },
                            model: {
                              value: _vm.dealformRefuse.opinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.dealformRefuse, "opinion", $$v)
                              },
                              expression: "dealformRefuse.opinion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer text-right dealform-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dealFormShow2 = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.FilingPassLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilingRefuse("dealformRefuse")
                            },
                          },
                        },
                        [_vm._v("确定&不予立案\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: "添加常用语",
                visible: _vm.languageinnerVisible,
                "close-on-click-modal": false,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.languageinnerVisible = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  resize: "none",
                  placeholder: "请添加常用语",
                  rows: "5",
                },
                model: {
                  value: _vm.newLanguage,
                  callback: function ($$v) {
                    _vm.newLanguage = $$v
                  },
                  expression: "newLanguage",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addLanguage },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("send-case", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sendCaseVisible,
            expression: "sendCaseVisible",
          },
        ],
        ref: "sendCase",
        on: {
          handleShowing: _vm.updateShow,
          handleErrorShowing: _vm.updateErrorShow,
          handleCloseLoading: _vm.closePassLoading,
        },
      }),
      _vm._v(" "),
      _c("party-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.partyInfoVisible,
            expression: "partyInfoVisible",
          },
        ],
        ref: "partyInfo",
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "btn",
          staticStyle: {
            position: "fixed",
            bottom: "0px",
            right: "20px",
            left: "230px",
            "line-height": "60px",
            "text-align": "right",
            background: "white",
          },
        },
        [
          _vm.detailData.state == 15 || _vm.detailData.state == 14
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary", loading: _vm.showRegisterLoading },
                  on: { click: _vm.showRegisterFiles },
                },
                [_vm._v("查看立案文书\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.state == 1 || _vm.detailData.state == 13
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary", loading: _vm.secretOpinionLoading },
                  on: { click: _vm.handleFilingReview },
                },
                [_vm._v("立案审核\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.state == 14
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary", loading: _vm.sendCasePayLoading },
                  on: { click: _vm.sendFilingReview },
                },
                [_vm._v("审核&通知缴费\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.state == 15
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary", loading: _vm.finalPassLoading },
                  on: { click: _vm.handleIsPayCaseAmount },
                },
                [_vm._v("审核&移交办案\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.state == 12
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary", loading: _vm.finalPassLoading },
                  on: { click: _vm.handleFilingFinalPass },
                },
                [_vm._v("立案送达\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("rejectCase", { ref: "rejectCase", on: { onrefresh: _vm.onrefresh } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }