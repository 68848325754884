var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "param-container" },
        [
          _c("comp-info-input", {
            attrs: { label: "sdkAppId", type: "number" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "sdkAppId")
              },
            },
          }),
          _vm._v(" "),
          _c("comp-info-input", {
            attrs: { label: "secretKey" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "secretKey")
              },
            },
          }),
          _vm._v(" "),
          _c("comp-info-input", {
            attrs: { label: "userId" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "userId")
              },
            },
          }),
          _vm._v(" "),
          _c("comp-info-input", {
            attrs: { label: "roomId", type: "number" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "roomId")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "label" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "param-container" },
        [
          _c("comp-device-select", {
            attrs: { deviceType: "camera" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "cameraId")
              },
            },
          }),
          _vm._v(" "),
          _c("comp-device-select", {
            attrs: { deviceType: "microphone" },
            on: {
              change: function ($event) {
                return _vm.handleValueChange($event, "microphoneId")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("comp-room", {
        attrs: {
          sdkAppId: Number(_vm.sdkAppId),
          secretKey: _vm.secretKey,
          userId: _vm.userId,
          roomId: Number(_vm.roomId),
          cameraId: _vm.cameraId,
          microphoneId: _vm.microphoneId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }