"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry.js");
var _dictionary = require("@/config/dictionary");
var _send = _interopRequireDefault(require("../service/send"));
var _index2 = require("@/utils/index.js");
var _settings = _interopRequireDefault(require("@/settings"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'holdcourt',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      wayList: [{
        name: '书面审理',
        id: '1'
      }, {
        name: '线下开庭',
        id: '2'
      }, {
        name: '视频开庭',
        id: '3'
      }],
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: '',
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      holdcourtLotVisible: false,
      //案件组庭批量弹窗显示
      holdcourtLotForm: {
        //案件组庭批量数据
        caseIds: '',
        courtAddress: "",
        courtTime: '',
        date: '',
        moment: ''
      },
      holdcourtLotRules: {
        courtTime: [{
          required: true,
          message: '请选择开庭时间',
          trigger: 'change'
        }],
        courtAddress: [{
          required: true,
          message: '请选择开庭地址',
          trigger: 'change'
        }]
      },
      holdcourtVisible: false,
      //案件组庭弹窗显示
      holdcourtForm: {
        //案件组庭数据
        caseId: '',
        courtAddress: "",
        courtTime: '',
        date: '',
        moment: '',
        courtType: '2'
      },
      holdcourtRules: {
        courtType: [{
          required: true,
          message: '请选择开庭方式',
          trigger: 'change'
        }],
        courtTime: [{
          required: true,
          message: '请选择开庭时间',
          trigger: 'change'
        }],
        courtAddress: [{
          required: true,
          message: '请选择开庭地址',
          trigger: 'change'
        }]
      },
      holdcourtInnerVisible: false,
      //添加开庭地址弹窗显示
      caeAddress: '',
      //添加开庭地址数据
      courtList: [],
      addAddressForm: {
        name: '',
        address: '',
        tel: ''
      },
      addAddressRules: {
        name: [{
          required: true,
          message: '请填写开庭名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '开庭名称长度1-20位数',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请填写开庭地址',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '开庭地址长度1-255位数',
          trigger: 'blur'
        }],
        tel: [{
          required: true,
          message: '请填写联系方式',
          trigger: 'blur'
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // return time.getTime() < Date.now();
        }
      },
      sendCaseVisible: false,
      //案件送达
      gotoServicing: false,
      //案件开庭弹窗  按钮loading
      caseNum: ''
    };
  },
  created: function created() {
    this.init();
    console.log(_settings.default.commId);
  },
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _inquiry.getList)({
        state: '3',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginComposeTime: data.rowThirdDate[0],
        endComposeTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1,
        state: 3
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //仲裁庭列表
    getCourtList: function getCourtList() {
      var _this3 = this;
      (0, _inquiry.getCourtList)().then(function (res) {
        if (res.code == 1) {
          _this3.courtList = res.data;
        }
      });
    },
    //表格 审核按钮
    handleReview: function handleReview(data) {
      this.caseId = data.caseId;
      this.holdcourtVisible = true;
      this.getCourtList();
    },
    //添加开庭地址
    handleAddAddress: function handleAddAddress(addAddressForm) {
      var _this4 = this;
      this.$refs[addAddressForm].validate(function (valid) {
        if (valid) {
          (0, _inquiry.insertCourtInfo)(_this4.addAddressForm).then(function (res) {
            if (res.code == 1) {
              _this4.$message({
                message: '添加开庭地址成功',
                type: 'success'
              });
              _this4.holdcourtInnerVisible = false;
              _this4.getCourtList();
            } else {
              _this4.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    updateShow: function updateShow() {
      this.holdcourtVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //弹窗确认按钮
    gotoService: function gotoService(divisionForm) {
      var _this5 = this;
      this.$refs[divisionForm].validate(function (valid) {
        if (valid) {
          _this5.gotoServicing = true;
          _this5.holdcourtForm.caseId = _this5.caseId;
          _this5.holdcourtForm.conferenceAisle = {
            id: '',
            name: '',
            webUrl: '',
            commId: ''
          };
          if (_this5.holdcourtForm.date == '') {
            _this5.$message.error('请选择开庭日期');
          } else if (_this5.holdcourtForm.moment == '') {
            _this5.$message.error('请选择开庭时间');
          } else {
            _this5.holdcourtForm.courtTime = _this5.holdcourtForm.date + " " + _this5.holdcourtForm.moment;
            // this.loading = true;
            (0, _inquiry.choiceCourtAddress)(_this5.holdcourtForm).then(function (res) {
              _this5.loading = false;
              if (res.code == 1) {
                _this5.sendCaseVisible = true;
                _this5.$nextTick(function () {
                  _this5.$refs.sendCase.init(_this5.caseId, 3);
                });
              } else {
                _this5.gotoServicing = false;
                _this5.$message.error(res.msg);
              }
            }).catch(function (error) {
              _this5.gotoServicing = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this6 = this;
      this.multipleSelection.forEach(function (item) {
        _this6.multipleSelectionId.push(item.caseId);
      });
      this.caseNum = this.multipleSelectionId.length;
      this.holdcourtLotVisible = true;
      this.getCourtList();
    },
    batchReviewSubmit: function batchReviewSubmit(holdcourtLotForm) {
      var _this7 = this;
      this.$refs[holdcourtLotForm].validate(function (valid) {
        if (valid) {
          if (_this7.holdcourtLotForm.date == '') {
            _this7.$message.error('请选择开庭日期');
          } else if (_this7.holdcourtLotForm.moment == '') {
            _this7.$message.error('请选择开庭时间');
          } else {
            _this7.holdcourtLotForm.courtTime = _this7.holdcourtLotForm.date + " " + _this7.holdcourtLotForm.moment;
            _this7.holdcourtLotForm.caseIds = _this7.multipleSelectionId;
            _this7.loading = true;
            (0, _inquiry.batchSendCourt)(_this7.holdcourtLotForm).then(function (res) {
              _this7.loading = false;
              if (res.code == 1) {
                _this7.$message({
                  message: '批量审核成功',
                  type: 'success'
                });
                _this7.holdcourtLotVisible = false;
                _this7.init();
              } else {
                _this7.$message.error(res.msg);
              }
            }).catch(function (error) {});
          }
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this8 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '3',
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this9 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '3',
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;