var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("el-button", { attrs: { type: "success", plain: "" } }, [
            _vm._v("待发放"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "basic-message" },
        [
          _c(
            "el-main",
            [
              _c("div", { staticClass: "basic-message-title" }, [
                _vm._v("基本信息"),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单人：张科")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("对账备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("收款备注：")]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "summary" },
        [
          _c("div", { staticClass: "summary-title" }, [_vm._v("结算汇总")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "summary-tip" }, [
            _vm._v("共 3000 个案件，合计金额 300000 元"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: "rgba(242, 243, 245, 1)" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "link-style" }, [
                          _vm._v(_vm._s(scope.row.reference)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "结案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "标的额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "application", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "respondent", label: "鉴定费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "operate", label: "结算总金额(元)" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detail-settle-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: 4,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("el-button", { staticClass: "btn", attrs: { size: "medium" } }, [
            _vm._v("作废"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  _vm.noteMakingVisible = true
                },
              },
            },
            [_vm._v("备注")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.grantVisible = true
                },
              },
            },
            [_vm._v("确认发放")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "备注", visible: _vm.noteMakingVisible },
          on: {
            "update:visible": function ($event) {
              _vm.noteMakingVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              resize: "none",
              rows: "5",
              autocomplete: "off",
              placeholder: "请输入备注",
            },
            model: {
              value: _vm.note,
              callback: function ($$v) {
                _vm.note = $$v
              },
              expression: "note",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发放确认", visible: _vm.grantVisible },
          on: {
            "update:visible": function ($event) {
              _vm.grantVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发放方式",
                    "label-width": _vm.formLabelWidth,
                    rules: [{ required: true, message: "请选择发放方式" }],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      rows: "4",
                      autocomplete: "off",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.grantVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.grantVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }