"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _StreamButton = _interopRequireDefault(require("@/views/trial/videoTrial/components/StreamButton.vue"));
var _Notice = _interopRequireDefault(require("@/views/trial/videoTrial/components/Notice.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    StreamButton: _StreamButton.default,
    Notice: _Notice.default
  },
  props: {
    // dataId: {
    //   //视频流id
    //   default: ""
    // },
    dataValue: {
      default: null
    },
    dataSize: {
      //用户id
      default: "medium"
    }
    // dataTitle: {
    //   default: ""
    // }
  },
  data: function data() {
    return {
      hasBtnRtc: false,
      hasBtnVideo: true,
      hasBtnAudio: true
    };
  },
  methods: {
    handleClick: function handleClick(type, action) {
      if (type == "rtc") {
        this.hasBtnRtc = action;
      } else if (type == "video") {
        this.hasBtnVideo = action;
      } else if (type == "audio") {
        this.hasBtnAudio = action;
      }
      this.$emit("handle-click", type, action, this.dataValue);
      // console.log(type, action);
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;