var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "courtVedio global-page-base-layout" }, [
    _c("div", { staticClass: "title" }, [_vm._v("庭审纪律设置")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("el-input", {
          attrs: { type: "textarea", rows: 30, placeholder: "请输入内容" },
          model: {
            value: _vm.textarea,
            callback: function ($$v) {
              _vm.textarea = $$v
            },
            expression: "textarea",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("保存")])],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }