"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry");
var _dictionary = require("@/config/dictionary");
var _index2 = _interopRequireDefault(require("@/components/editor/index"));
var _vuex = require("vuex");
var _index3 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'auditInCase',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      loading: true,
      caseState: _dictionary.dictionary.caseState,
      //案件状态
      caseExamineState: _dictionary.dictionary.caseExamineState,
      //案件审核状态
      caseExamineType: _dictionary.dictionary.caseExamineType,
      //案件审核类型
      total: 0,
      //总页数
      detailData: '',
      //详情后台返回的数据

      checkVisible: false,
      //查看弹窗显示

      examineDetailVisible: false,
      //审核详情弹窗显示
      examineType: '',
      //审核类型

      resolutionVisible: false,
      //处理意见显示
      resolutionform: {
        region: '',
        regionInput: ''
      },
      courtVisible: false,
      //提交法院显示
      courtTextarea: '',
      //提交法院意见内容
      conditionData: '',
      //筛选条件的数据
      tableData: [],
      //表格数据
      activities: [],
      editVisible: false,
      //编辑器显示
      article: {
        //编辑器的内容
        content: ''
      },
      editorTitle: '',
      //编辑器的标题
      currentOperate: '',
      //1 通过审核， 2 拒绝
      alreadyChooseData: '' //批量审核勾选的数据
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['token'])),
  components: {
    Caseconditons: _index.default,
    Editor: _index2.default
  },
  methods: {
    //初始化
    init: function init() {
      var _this = this;
      this.loading = true;
      (0, _inquiry.getCasePower)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.loading = false;
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    //批量审核
    batchExamine: function batchExamine() {},
    // 筛选按钮  请求数据
    checkAuditInList: function checkAuditInList(data) {
      var _this2 = this;
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        caseBeginTime: data.rowSecondDate[0],
        caseEndTime: data.rowSecondDate[1],
        checkBeginTime: data.rowThirdDate[0],
        checkEndTime: data.rowThirdDate[1],
        procedureType: data.caseTypevalue,
        defenceState: data.replyvalue,
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getCasePower)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    // 清空筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    resetTableList: function resetTableList() {
      var _this3 = this;
      if (this.conditionData.length > 0) {
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getCasePower)(this.conditionData).then(function (res) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        });
      } else {
        (0, _inquiry.getCasePower)({
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        });
      }
    },
    //表格  案号点击
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.alreadyChooseData = val;
    },
    //表格 == 审核/查看按钮
    gotoExamineDetail: function gotoExamineDetail(val) {
      var _this4 = this;
      //获取详情
      this.detailData = '';
      (0, _inquiry.getCasePowerDetail)({
        id: val.id,
        procedureType: val.procedureType
      }).then(function (res) {
        if (res.code == 1) {
          _this4.detailData = res.data;
          _this4.activities = res.data.caseBatchAudits;
          _this4.examineDetailVisible = true;
          _this4.examineType = val.procedureName;
        }
      });
      // 根据当前审核类型 定义编辑器标题
      if (this.examineType == '撤案申请') {
        this.editorTitle = "撤案决定书";
      } else if (this.examineType == '申请调解') {
        this.editorTitle = "调解书";
      } else if (this.examineType == '仲裁员回避') {
        this.editorTitle = '仲裁庭组庭通知书';
      }
    },
    //表格  仅仅的查看按钮
    gotoCheck: function gotoCheck(val) {
      var _this5 = this;
      this.detailData = "";
      //获取查看详情
      (0, _inquiry.getCasePowerDetail)({
        id: val.id,
        procedureType: val.procedureType
      }).then(function (res) {
        _this5.detailData = res.data;
        _this5.checkVisible = true;
      });
    },
    // 拒绝或者通过请求发送
    passOrRefuse: function passOrRefuse(state) {
      var _this6 = this;
      (0, _inquiry.passAudit)({
        checkState: state,
        reason: this.resolutionform.regionInput,
        id: this.detailData.id,
        procedureType: this.detailData.procedureType
      }).then(function (res) {
        if (res.code == 1) {
          if (_this6.conditionData) {
            (0, _inquiry.getCasePower)(_this6.conditionData).then(function (res) {
              if (res.code == 1) {
                _this6.tableData = res.rows;
                _this6.total = res.total;
              }
            });
          } else {
            // this.init()
            _this6.resetTableList();
          }
          _this6.resolutionVisible = false;
          _this6.examineDetailVisible = false;
        }
      });
    },
    //详情 ==>拒绝 按钮
    examineRefuse: function examineRefuse() {
      this.resolutionVisible = true;
      this.currentOperate = 2;
      this.$refs['resolutionForm'].resetFields();
    },
    //详情 ==> 审核通过
    examineAdopt: function examineAdopt() {
      this.resolutionVisible = true;
      this.currentOperate = 1;
      this.$refs['resolutionForm'].resetFields();
    },
    //拒绝理由  确认按钮
    refuseConfirm: function refuseConfirm() {
      this.passOrRefuse('-1');
      this.refuseVisible = false;
    },
    //处理意见 按钮
    resolutionConfirm: function resolutionConfirm(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this7.currentOperate == 1) {
            //通过审核
            _this7.passOrRefuse('1');
          } else if (_this7.currentOperate == 2) {
            //拒绝
            _this7.passOrRefuse('-1');
          }
        } else {
          return false;
        }
      });
    },
    //提交法院
    sumbitToCourt: function sumbitToCourt() {
      this.examineDetailVisible = false;
      this.courtVisible = true;
    },
    //编辑内容变化
    contentChange: function contentChange(val) {},
    //出具通知书/决定书发送按钮
    sendNotice: function sendNotice() {
      this.editVisible = false;
    },
    //提交法院  弹窗里面的确认按钮
    courtConfirm: function courtConfirm() {
      this.courtVisible = false;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this8 = this;
      this.pageLimit = val;
      if ((0, _index3.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getCasePower)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCasePower)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this9 = this;
      if ((0, _index3.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getCasePower)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCasePower)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;