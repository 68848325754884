"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'acceptAnalysis',
  data: function data() {
    return {
      searchDate: '',
      //搜索日期
      tableData: [{
        data: '合同纠纷',
        name: '547',
        address1: '47',
        address2: '50%',
        address3: '54',
        address4: '50',
        address5: '99%',
        address6: '200',
        address7: '2',
        address8: '1'
      }],
      currentPage4: 2
    };
  },
  mounted: function mounted() {
    // 柱状图
    this.myChart = this.$echarts.init(document.getElementById('groupCourt'));
    var option = {
      title: {
        text: '案件来源分析',
        x: 'left'
      },
      xAxis: [{
        type: 'category',
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        axisLabel: {
          color: 'rgba(97, 165, 232, 1)'
        }
      }],
      yAxis: [{
        type: 'value',
        name: '##',
        axisLabel: {
          formatter: '{value}'
        }
      }, {
        type: 'value',
        name: '##',
        axisLabel: {
          formatter: '{value}'
        }
      }],
      series: [{
        name: '案件处理1',
        type: 'bar',
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
        itemStyle: {
          color: 'rgba(97, 165, 232, 1)'
        }
      }, {
        name: '案件处理2',
        type: 'line',
        yAxisIndex: 1,
        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3],
        itemStyle: {
          color: '#7ECF51'
        }
      }]
    };
    this.myChart.setOption(option);

    // 柱状图2
    this.myChart1 = this.$echarts.init(document.getElementById('CourtSchedule'));
    var option1 = {
      title: {
        text: '开庭排期分析',
        x: 'left'
      },
      xAxis: [{
        type: 'category',
        data: ['1月', '2月', '3月', '4月', '5月']
      }],
      yAxis: [{
        type: 'value'
      }],
      series: [{
        name: '蒸发量',
        type: 'bar',
        data: [2.0, 4.9, 7.0, 23.2, 25.6],
        markPoint: {
          data: [{
            type: 'max',
            name: '最大值'
          }, {
            type: 'min',
            name: '最小值'
          }]
        },
        markLine: {
          data: [{
            type: 'average',
            name: '平均值'
          }]
        }
      }]
    };
    this.myChart1.setOption(option1);

    // 柱状图3
    this.myChart2 = this.$echarts.init(document.getElementById('caseNum'));
    var option2 = {
      title: {
        text: '仲裁员案件量分析',
        x: 'left'
      },
      xAxis: [{
        type: 'value',
        boundaryGap: [0, 0.01]
      }],
      yAxis: [{
        type: 'category',
        data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
      }],
      series: [{
        name: '2011年',
        type: 'bar',
        data: [18203, 23489, 29034, 104970, 131744, 630230],
        itemStyle: {
          color: 'rgba(97, 165, 232, 1)'
        }
      }]
    };
    this.myChart2.setOption(option2);

    // 柱状图4
    this.myChart3 = this.$echarts.init(document.getElementById('caseWeek'));
    var option3 = {
      title: {
        text: '案件周办理分析',
        x: 'left'
      },
      grid: {
        y: 70,
        y2: 30,
        x2: 20
      },
      xAxis: [{
        type: 'category',
        data: ['Line', 'Bar', 'Scatter', 'K', 'Map']
      }, {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        },
        data: ['Line', 'Bar', 'Scatter', 'K', 'Map']
      }],
      yAxis: [{
        type: 'value',
        axisLabel: {
          formatter: '{value} ms'
        }
      }],
      series: [{
        name: 'ECharts2 - 20w数据',
        type: 'bar',
        itemStyle: {
          normal: {
            color: 'rgba(252,206,16,1)',
            label: {
              show: true,
              textStyle: {
                color: '#E87C25'
              }
            }
          }
        },
        data: [906, 911, 908, 778, 0]
      }, {
        name: 'ECharts1 - 20w数据',
        type: 'bar',
        xAxisIndex: 1,
        itemStyle: {
          normal: {
            color: 'rgba(252,206,16,0.5)',
            label: {
              show: true,
              formatter: function formatter(p) {
                return p.value > 0 ? p.value + '+' : '';
              }
            }
          }
        },
        data: [3000, 3000, 2817, 3000, 0]
      }]
    };
    this.myChart3.setOption(option3);
  },
  method: {
    //日期改变
    dateChange: function dateChange() {} // handleSizeChange(val) {
    //  },
    //  handleCurrentChange(val) {
    //  }
  }
};
exports.default = _default;