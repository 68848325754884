var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block-chain-detail" },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "txt" }, [_vm._v("互仲链·区块链查询")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "el-form",
              {
                ref: "numberValidateForm",
                staticClass: "demo-ruleForm",
                attrs: { model: _vm.numberValidateForm },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "value",
                      rules: [
                        { required: true, message: "备案号不能为空" },
                        { type: "number", message: "备案号必须为数字值" },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "input-with-select",
                        attrs: { type: "text", placeholder: "请输入内容" },
                        on: {
                          input: function ($event) {
                            return _vm.input(_vm.numberValidateForm.value)
                          },
                        },
                        model: {
                          value: _vm.numberValidateForm.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.numberValidateForm,
                              "value",
                              _vm._n($$v)
                            )
                          },
                          expression: "numberValidateForm.value",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("numberValidateForm")
                            },
                          },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block-chain-content" },
        [
          _vm.result == 2
            ? _c("div", { staticClass: "tip" }, [_vm._v("这个备案号没有记录")])
            : _vm._e(),
          _vm._v(" "),
          _c("el-main", { staticClass: "content" }, [
            _vm.result == 1
              ? _c(
                  "div",
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "content-title" }, [
                        _vm._v("保全主体"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("保全实体名称：" + _vm._s(_vm.list.entityName)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("保全主体ID：" + _vm._s(_vm.list.entityId)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "保全操作人实体名称：" +
                            _vm._s(_vm.list.operatorEntityName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "保全操作人ID：" + _vm._s(_vm.list.operatorEntityId)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "content-title" }, [
                        _vm._v("编号"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("备案号：" + _vm._s(_vm.list.preservationId)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("256哈希值：" + _vm._s(_vm.list.sha256Hash)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("512哈希值：" + _vm._s(_vm.list.sha512Hash)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "广州互联网法院证据编号：" + _vm._s(_vm.list.gznetId)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "杭州互联网法院证据编号：" + _vm._s(_vm.list.antId)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "易保全ID：" + _vm._s(_vm.list.ebqChainTransHash)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "content-title" }, [
                        _vm._v("其他"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "保全时间：" + _vm._s(_vm.list.preservationTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "保全设备信息：" +
                            _vm._s(_vm.list.preservationDeceive)
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "btn",
          attrs: { round: "" },
          on: { click: _vm.gotoIndex },
        },
        [_vm._v("返回首页")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "btn1",
          attrs: { round: "" },
          on: { click: _vm.gotoBlockIndex },
        },
        [_vm._v("返回区块链首页")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }