var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "global-page-base-layout",
    },
    [
      _c("h4", [_vm._v("仲裁员配置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { model: _vm.deployForm, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "仲裁员签名：", prop: "imgOssHref" } },
            [
              _vm.deployForm.imgOssHref
                ? _c(
                    "a",
                    {
                      staticClass: "a-preview",
                      attrs: {
                        href: _vm.deployForm.imgOssHref,
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.deployForm.imgOssHref, alt: "" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        accept: "accept",
                        action: _vm.uploadUrl,
                        "show-file-list": false,
                        headers: _vm.headers,
                        "on-success": _vm.handleImgSuccess,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSave()
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }