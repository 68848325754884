import request from '@/utils/request';

// 全局图片上传
export function uploadCommonURL() {
  return '/back/manager/uploadCommonFile';
}

// 仲裁员列表请求  -peng
export function getArbitratorList(params) {
  return request({
    url: '/back/manager/arbitratorInfo/page',
    method: 'get',
    params: params
  });
}
// 查看单个仲裁员
export function checkArbitrator(params) {
  return request({
    url: '/back/manager/arbitratorInfo/' + params,
    method: 'get'
  });
}
//仲裁员删除
export function deleteArbitrator(data) {
  return request({
    url: '/back/manager/arbitratorInfo/deleteById',
    method: 'post',
    data: data
  });
}

//添加仲裁员
export function addArbitrator(data) {
  return request({
    url: '/back/manager/arbitratorInfo',
    method: 'post',
    data: data
  });
}
// 修改仲裁员
export function updateArbitrator(data) {
  return request({
    url: '/back/manager/arbitratorInfo',
    method: 'put',
    data: data
  });
}

// 查询仲裁委列表
export function getAllCommission(params) {
  return request({
    url: '/back/commissionInfo/getAllCommission',
    method: 'get',
    params: params
  });
}

// 查看仲裁员账号
export function getArbitratorAccount(params) {
  return request({
    url: '/back/admin/getUserById/' + params,
    method: 'get'
  });
}

// 开通仲裁员账号
export function addArbitratorAccount(data) {
  return request({
    url: '/back/manager/arbitratorInfo/openAccount',
    method: 'post',
    data: data
  });
}
// 开通签名之后返回的paf请求
export function addArbitratoSign(data) {
  return request({
    url: 'batchCase/caseFileInstrument/createArbitratorBook',
    method: 'post',
    data: data
  });
}

// 上传的仲裁员签名
export function uploadArbitratoSign() {
  return '/back/manager/uploadSignFile';
}

// 仲裁委员会列表
export function getArbitralBody(params) {
  return request({
    url: '/back/commissionInfo/page',
    method: 'get',
    params: params
  });
}
//仲裁机构删除
export function deleteArbitralBody(data) {
  return request({
    url: '/back/commissionInfo/deleteById',
    method: 'post',
    data: data
  });
}

// 仲裁机构文件上传地址
export function uploadArbitralBodyFile() {
  return '/back/manager/uploadCommSignFile';
}

//添加仲裁机构
export function addArbitralBody(data) {
  return request({
    url: '/back/commissionInfo/',
    method: 'post',
    data: data
  });
}

// 查看单个仲裁机构
export function checkArbitralBody(params) {
  return request({
    url: '/back/commissionInfo/' + params,
    method: 'get'
  });
}

// 修改单个仲裁机构
export function updateArbitralBody(data) {
  return request({
    url: '/back/commissionInfo',
    method: 'put',
    data: data
  });
}

// 查询文书模板列表
export function getcaseBookTemplateList(params) {
  return request({
    url: '/batchCase/caseBookTemplate/page',
    method: 'get',
    params: params
  });
}
// 查询文书类型
export function getcaseBookTemplateType(data) {
  return request({
    url: '/batchCase/caseBookTemplate/getTemplateType',
    method: 'post',
    data: data
  });
}
//文书模板删除
export function deleteCaseBookTemplate(data) {
  return request({
    url: '/batchCase/caseBookTemplate/deleteById',
    method: 'post',
    data: data
  });
}

// 添加文书
export function addCaseBookTemplate(data) {
  return request({
    url: '/batchCase/caseBookTemplate',
    method: 'post',
    data: data
  });
}

// 查询单个模板文书
export function checkCaseBookTemplate(params) {
  return request({
    url: '/batchCase/caseBookTemplate/' + params,
    method: 'get'
  });
}
// 修改单个模板文书
export function updateCaseBookTemplate(data) {
  return request({
    url: '/batchCase/caseBookTemplate',
    method: 'put',
    data: data
  });
}

// 送达模板管理    表格列表
export function getcaseSendTemplateList(params) {
  return request({
    url: '/batchCase/caseSendTemplate/page',
    method: 'get',
    params: params
  });
}
// 案件状态列表
export function getProcesscaseStateList(data) {
  return request({
    url: '/activiti/tProcessInfo/getProcessCaseStateList',
    method: 'post',
    data: data
  });
}

// 关联文书列表
export function getcaseRelatedBookList(params) {
  return request({
    url: '/batchCase/caseBookTemplate/list',
    method: 'get',
    params: params
  });
}
//送达模板删除
export function deleteCaseSendTemplate(data) {
  return request({
    url: '/batchCase/caseSendTemplate/deleteById',
    method: 'post',
    data: data
  });
}
// 添加送达模板
export function addCaseSendTemplate(data) {
  return request({
    url: '/batchCase/caseSendTemplate',
    method: 'post',
    data: data
  });
}
// 查询单个送达模板
export function checkCaseSendTemplate(data) {
  return request({
    url: '/batchCase/caseSendTemplate/getInfoById',
    method: 'post',
    data: data
  });
}
// 修改单个送达模板
export function updateCaseSendTemplate(data) {
  return request({
    url: '/batchCase/caseSendTemplate',
    method: 'put',
    data: data
  });
}

// 单个文书上传
export function uploadFileCommonURL(data) {
  return '/back/manager/uploadFileByCommInfo';
}

// 仲裁费列表
export function getChargeList(params) {
  return request({
    url: '/back/tChargeTemplate/page',
    method: 'get',
    params: params
  });
}
// 新增仲裁费
export function addChargeTemplate(data) {
  return request({
    url: '/back/tChargeTemplate/addChargeTemplate',
    method: 'post',
    data: data
  });
}
// 查询仲裁费模板
export function checkChargeTemplate(data) {
  return request({
    url: '/back/tChargeTemplate/getChargeTemplate',
    method: 'post',
    data: data
  });
}
// 修改单个仲裁费模板
export function updateChargeTemplate(data) {
  return request({
    url: '/back/tChargeTemplate/updateChargeTemplate',
    method: 'post',
    data: data
  });
}
// 获取操作日志
export function getOperateLogPage(data) {
  return request({
    url: "/back/operateLog/page?limit=".concat(data.limit, "&offset=").concat((data.page - 1) * 10),
    method: 'get',
    data: data
  });
}

// 定时任务列表
export function getTimeTaskList(params) {
  return request({
    url: '/task/manager/scheduleJob/list',
    method: 'get',
    params: params
  });
}
// 定时任务 ----查看执行日志
export function getTimeTaskLogList(data) {
  return request({
    url: '/task/manager/schedulelog/queryScheduleLogByPage',
    method: 'post',
    data: data
  });
}
//开启定时任务
export function startTimeTask(data) {
  return request({
    url: '/task/manager/scheduleJob/start',
    method: 'post',
    data: data
  });
}
//停止定时任务
export function stopTimeTask(data) {
  return request({
    url: '/task/manager/scheduleJob/stop',
    method: 'post',
    data: data
  });
}
//立即运行定时任务
export function runTimeTask(data) {
  return request({
    url: '/task/manager/scheduleJob/run',
    method: 'post',
    data: data
  });
}

// 证据清单每个仲裁委对应的列表数据
export function getEvidenceList(data) {
  return request({
    url: 'back/tEvidenceList/selectEvidenceList',
    method: 'post',
    data: data
  });
}
// 移除证据清单规则
export function deleteEvidenceList(params) {
  return request({
    url: 'back/tEvidenceList/' + params,
    method: 'delete'
  });
}

// 添加证据清单规则
export function addEvidenceList(data) {
  return request({
    url: 'back/tEvidenceList',
    method: 'post',
    data: data
  });
}
// 修改证据清单规则
export function editEvidenceList(data) {
  return request({
    url: 'back/tEvidenceList',
    method: 'put',
    data: data
  });
}

// 证据清单查询所有企业
export function getCompayList(data) {
  return request({
    url: '/batchCase/litigant/selectAllEnterpriseList',
    method: 'post',
    data: data
  });
}

//查询所有案件类型列表
export function getallCaseList(params) {
  return request({
    url: '/back/caseTypeConfig/selectCaseTypeList/' + params,
    method: 'get'
  });
}
// 查询庭审源列表
export function getConferenceAisleList(params) {
  return request({
    url: '/trialapi/conferenceAisle/page',
    method: 'get',
    params: params
  });
}

//保存通道saveAisle
export function saveAisle(data) {
  return request({
    url: '/trialapi/conferenceAisle/saveAisle',
    method: 'post',
    data: data
  });
}

//保存通道saveAisle-亿联
export function saveAisleYl(data) {
  return request({
    url: '/trialapi/conferenceAisle/saveAisleYl',
    method: 'post',
    data: data
  });
}
//通道删除
export function deleteAisleById(params) {
  return request({
    url: 'trialapi/conferenceAisle/' + params,
    method: 'delete'
  });
}
//查询通道信息
export function getAisleInfoById(params) {
  return request({
    url: '/trialapi/conferenceAisle/' + params,
    method: 'get'
  });
}

// 查询用户组列表
export function getTrialYlGroupList(params) {
  return request({
    url: '/trialapi/trialYlGroup/page',
    method: 'get',
    params: params
  });
}

//保存用户组
export function saveTrialYlGroup(data) {
  return request({
    url: 'trialapi/trialYlGroup/createGroup',
    method: 'post',
    data: data
  });
}

//修改用户组
export function editDiscipline(data) {
  return request({
    url: 'trialapi/trialYlGroup/editDiscipline',
    method: 'post',
    data: data
  });
}
//通道删除
export function deleteTrialYlGroupById(params) {
  return request({
    url: 'trialapi/trialYlGroup/' + params,
    method: 'delete'
  });
}
//查询通道信息
export function getTrialYlGroupById(params) {
  return request({
    url: '/trialapi/trialYlGroup/' + params,
    method: 'get'
  });
}

//查询仲裁员信息
export function selectArbitratorInfo(data) {
  return request({
    url: 'back/admin/selectArbitratorInfo',
    method: 'post',
    data: data
  });
}

//添加会议用户
export function groupAddUser(data) {
  return request({
    url: 'trialapi/trialYlUser/addUser',
    method: 'post',
    data: data
  });
}

//查询会议用户列表
export function selectGroupUserList(params) {
  return request({
    url: 'trialapi/trialYlUser/userList/' + params,
    method: 'get'
  });
}
//用户删除
export function deleteGroupUserById(params) {
  return request({
    url: 'trialapi/trialYlUser/' + params,
    method: 'delete'
  });
}

// 查询视频设备表
export function getTrialEquipmentList(params) {
  return request({
    url: 'trialapi/trialEquipment/page',
    method: 'get',
    params: params
  });
}

//保存设备
export function saveEquipment(data) {
  return request({
    url: 'trialapi/trialEquipment/saveEquipment',
    method: 'post',
    data: data
  });
}
//通道删除
export function deleteEquipment(params) {
  return request({
    url: 'trialapi/trialEquipment/' + params,
    method: 'delete'
  });
}
//查询通道信息
export function getEquipmentInfoById(params) {
  return request({
    url: 'trialapi/trialEquipment/' + params,
    method: 'get'
  });
}

//查询设备
export function getEquipmentList(data) {
  return request({
    url: 'trialapi/trialEquipment/getEquipmentList',
    method: 'post',
    data: data
  });
}