var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-page-base-layout" },
    [
      _c("el-main", [
        _c(
          "div",
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "item" },
              [
                _c("el-col", { attrs: { span: 20 } }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭时间：" + _vm._s(item.time)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭地点：" + _vm._s(item.address)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("仲裁员：" + _vm._s(item.peo1)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("书记员：" + _vm._s(item.peo2)),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c(
                    "div",
                    { staticClass: "item-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.gotoCourtSign(item)
                            },
                          },
                        },
                        [_vm._v("进入庭审")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.caseName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v(
                      "\n            开庭时间：" +
                        _vm._s(item.day) +
                        " " +
                        _vm._s(item.timer) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("开庭地点：" + _vm._s(item.aisleName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("庭审状态：" + _vm._s(_vm.videoState[item.state])),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("仲裁员：" + _vm._s(item.arbitratorName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-row" }, [
                    _vm._v("书记员：" + _vm._s(item.handingSecretaryName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 4 } }, [
                  _c(
                    "div",
                    { staticClass: "item-btn" },
                    [
                      item.state == 0 || item.state == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.gotoCourtSign(item)
                                },
                              },
                            },
                            [_vm._v("进入庭审\n            ")]
                          )
                        : _c("span", { attrs: { type: "primary" } }, [
                            _vm._v("庭审已完结"),
                          ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "global-page-base-layout",
        },
        [
          _c("Caseconditons", {
            attrs: { caseReplyCondition: false },
            on: {
              choose: _vm.checkList,
              cleanCondition: _vm.cleanConditionData,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "handing-table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    "header-cell-style": { background: _vm.tableHeaderColor },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "案号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.caseNo
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "link-style",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(
                                          scope.row.caseId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.caseNo))]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "link-style",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetail(
                                          scope.row.caseId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("暂无案号")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "caseId", label: "受理号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "registerApplyTime", label: "申请日期" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "appName", label: "申请人" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "resName", label: "被申请人" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "controversyAmount", label: "争议金额(元)" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "state", label: "案件状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.caseState[scope.row.state]) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "handingSecretaryName", label: "办案秘书" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "arbitratorName", label: "仲裁员" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "registerSendTime", label: "立案时间" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "composeTime", label: "组庭时间" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("[编辑笔录]")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpload(scope.row)
                                  },
                                },
                              },
                              [_vm._v("[上传笔录]")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": _vm.pageSizes,
                      "page-size": 10,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "上传笔录",
                visible: _vm.uploadVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件：" } },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            type: "primary",
                            underline: false,
                            href: _vm.uploadRule.aliyunOssHref,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.uploadRule.fileName) + "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: ".pdf,.PDF,.docx,.DOCX,.doc,.DOC",
                                action: _vm.uploadApi,
                                "show-file-list": false,
                                headers: { Authorization: _vm.token },
                                "on-success": _vm.handleAvatarSuccess,
                                "before-upload": _vm.beforeAvatarUpload,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "medium", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.uploadVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.singleUploading },
                      on: { click: _vm.handleUploadSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "笔录",
                visible: _vm.upcomingVisible,
                top: "0vh",
                fullscreen: "",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.upcomingVisible = $event
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "case-detail",
                  staticStyle: { "padding-right": "10px" },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "left-content", attrs: { id: "rule" } },
                      [
                        _c("div", [
                          _c(
                            "textarea",
                            {
                              staticClass: "rule-header",
                              staticStyle: { resize: "none" },
                              attrs: {
                                id: "subject",
                                rows: "1",
                                placeholder: "请输入裁决书主题",
                              },
                            },
                            [_vm._v(_vm._s(_vm.caseAwardContentDto.subject))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "textarea",
                            {
                              staticClass: "rule-header",
                              staticStyle: { resize: "none" },
                              attrs: {
                                id: "title",
                                rows: "1",
                                placeholder: "请输入裁决书标题",
                              },
                            },
                            [_vm._v(_vm._s(_vm.caseAwardContentDto.title))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "textarea",
                            {
                              staticClass: "rule-caseno",
                              staticStyle: { resize: "none" },
                              attrs: {
                                id: "caseNo",
                                rows: "1",
                                placeholder: "请输入裁决书编号",
                              },
                            },
                            [_vm._v(_vm._s(_vm.caseAwardContentDto.caseNo))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm.partyInfos.title
                              ? _c(
                                  "textarea",
                                  {
                                    staticClass: "rule-title",
                                    attrs: {
                                      id: "title1",
                                      placeholder: "请输入小标题",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.partyInfos.title))]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "rule1" } },
                            _vm._l(
                              _vm.partyInfos.content,
                              function (item, index) {
                                return _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      staticClass: "rule-input",
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: item.text,
                                        callback: function ($$v) {
                                          _vm.$set(item, "text", $$v)
                                        },
                                        expression: "item.text",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "p-btn" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "red" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete1(index)
                                              },
                                            },
                                          },
                                          [_vm._v("[删除]")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdd1(index)
                                              },
                                            },
                                          },
                                          [_vm._v("[新增]")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm.partyContents.title
                              ? _c(
                                  "textarea",
                                  {
                                    staticClass:
                                      "rule-title el-textarea__inner",
                                    attrs: {
                                      id: "title2",
                                      placeholder: "请输入小标题",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.partyContents.title))]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "rule2" } },
                            _vm._l(
                              _vm.partyContents.content,
                              function (item, index) {
                                return _vm.partyContents.content.length !== 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "rule-input",
                                          attrs: {
                                            type: "textarea",
                                            autosize: "",
                                            placeholder: "请输入内容",
                                          },
                                          model: {
                                            value: item.text,
                                            callback: function ($$v) {
                                              _vm.$set(item, "text", $$v)
                                            },
                                            expression: "item.text",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-btn" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete2(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("[删除]")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdd2(index)
                                                  },
                                                },
                                              },
                                              [_vm._v("[新增]")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm.caseContents.title
                              ? _c(
                                  "textarea",
                                  {
                                    staticClass: "rule-title",
                                    attrs: {
                                      id: "title3",
                                      placeholder: "请输入小标题",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.caseContents.title))]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "rule3" } },
                            _vm._l(
                              _vm.caseContents.content,
                              function (item, index) {
                                return _vm.caseContents.content.length !== 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "rule-input",
                                          attrs: {
                                            type: "textarea",
                                            autosize: "",
                                            placeholder: "请输入内容",
                                          },
                                          model: {
                                            value: item.text,
                                            callback: function ($$v) {
                                              _vm.$set(item, "text", $$v)
                                            },
                                            expression: "item.text",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-btn" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete3(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("[删除]")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdd3(index)
                                                  },
                                                },
                                              },
                                              [_vm._v("[新增]")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm.arbitrationContents.title
                              ? _c(
                                  "textarea",
                                  {
                                    staticClass: "rule-title",
                                    attrs: {
                                      id: "title4",
                                      placeholder: "请输入小标题",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.arbitrationContents.title)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "rule4" } },
                            _vm._l(
                              _vm.arbitrationContents.content,
                              function (item, index) {
                                return _vm.arbitrationContents.content
                                  .length !== 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "rule-input",
                                          attrs: {
                                            type: "textarea",
                                            autosize: "",
                                            placeholder: "请输入内容",
                                          },
                                          model: {
                                            value: item.text,
                                            callback: function ($$v) {
                                              _vm.$set(item, "text", $$v)
                                            },
                                            expression: "item.text",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-btn" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete4(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("[删除]")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdd4(index)
                                                  },
                                                },
                                              },
                                              [_vm._v("[新增]")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm.ruleContents.title
                              ? _c(
                                  "textarea",
                                  {
                                    staticClass: "rule-title",
                                    attrs: {
                                      id: "title5",
                                      placeholder: "请输入小标题",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.ruleContents.title))]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "rule5" } },
                            _vm._l(
                              _vm.ruleContents.content,
                              function (item, index) {
                                return _vm.ruleContents.content.length !== 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "rule-input",
                                          attrs: {
                                            type: "textarea",
                                            autosize: "",
                                            placeholder: "请输入内容",
                                          },
                                          model: {
                                            value: item.text,
                                            callback: function ($$v) {
                                              _vm.$set(item, "text", $$v)
                                            },
                                            expression: "item.text",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-btn" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete5(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("[删除]")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdd5(index)
                                                  },
                                                },
                                              },
                                              [_vm._v("[新增]")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer text-right",
                  staticStyle: { "padding-top": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.upcomingVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingSave },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave("1")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.loadingShow },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave("2")
                        },
                      },
                    },
                    [_vm._v("保存&预览")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.loadingPass },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave("3")
                        },
                      },
                    },
                    [_vm._v("保存&通过")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }