"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSingleProcessBasic = addSingleProcessBasic;
exports.checkProcessLog = checkProcessLog;
exports.checkSingleProcessBasic = checkSingleProcessBasic;
exports.deleteProcess = deleteProcess;
exports.getAllCommission = getAllCommission;
exports.getProcessDefineStatusList = getProcessDefineStatusList;
exports.getProcessList = getProcessList;
exports.getProcessSetAuditor = getProcessSetAuditor;
exports.getProcessSetAuditorList = getProcessSetAuditorList;
exports.getProcessSetAuditorRoleList = getProcessSetAuditorRoleList;
exports.getProcessSetList = getProcessSetList;
exports.getProcessStateAll = getProcessStateAll;
exports.updateProcessNodeList = updateProcessNodeList;
exports.updateProcessSetAuditor = updateProcessSetAuditor;
exports.updateProcessStateList = updateProcessStateList;
exports.updateSingleProcessBasic = updateSingleProcessBasic;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询仲裁委列表
function getAllCommission(params) {
  return (0, _request.default)({
    url: '/back/commissionInfo/getAllCommission',
    method: 'get',
    params: params
  });
}

// 流程信息列表
function getProcessList(params) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/page',
    method: 'get',
    params: params
  });
}
//删除某个流程
function deleteProcess(data) {
  return (0, _request.default)({
    url: 'activiti/tProcessInfo/deleteById',
    method: 'post',
    data: data
  });
}
// 查询单个流程基础信息
function checkSingleProcessBasic(params) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/' + params,
    method: 'get'
  });
}
//新增单个流程基础信息、
function addSingleProcessBasic(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/addProcessInfo',
    method: 'post',
    data: data
  });
}
//修改单个流程基础信息、
function updateSingleProcessBasic(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/updateProcessInfo',
    method: 'post',
    data: data
  });
}

// 获取流程状态列表
function getProcessDefineStatusList(params) {
  return (0, _request.default)({
    url: '/activiti/tProcessNode/listBySort',
    method: 'get',
    params: params
  });
}

// 获取全部原始状态列表
function getProcessStateAll(params) {
  return (0, _request.default)({
    url: '/activiti/tProcessField/all',
    method: 'get',
    params: params
  });
}
// 保存状态  (参数为数组)
function updateProcessStateList(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessNode/editProcessNodes',
    method: 'post',
    data: data
  });
}
// 获取流程设置列表
function getProcessSetList(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessFlow/getProcessFlows',
    method: 'post',
    data: data
  });
}
// 保存流程节点  (参数为数组)
function updateProcessNodeList(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessFlow/editProcessFlows',
    method: 'post',
    data: data
  });
}

// 获取流转设置审核人员列表
function getProcessSetAuditorList(data) {
  return (0, _request.default)({
    url: '/back/admin/getAdminInfoByCommId',
    method: 'post',
    data: data
  });
}
// 获取流转设置审核角色列表
function getProcessSetAuditorRoleList() {
  return (0, _request.default)({
    url: '/back/role/all',
    method: 'get'
  });
}
// 查询已经保存的处理人信息
function getProcessSetAuditor(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessPerson/getProcessPerson',
    method: 'post',
    data: data
  });
}
//编辑节点处理人
function updateProcessSetAuditor(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessPerson/editProcessPersons',
    method: 'post',
    data: data
  });
}

// 查询日志
function checkProcessLog(params) {
  return (0, _request.default)({
    url: '/activiti/tProcessLog/page',
    method: 'get',
    params: params
  });
}