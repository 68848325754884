"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.hideFullScreenLoading = hideFullScreenLoading;
exports.showFullScreenLoading = showFullScreenLoading;
var _vue = _interopRequireDefault(require("vue"));
// loading框设置局部刷新，且所有请求完成后关闭loading框
var loading;
var needLoadingRequestCount = 0; // 声明一个对象用于存储请求个数
function startLoading(targetdq) {
  loading = _vue.default.prototype.$loading({
    lock: true,
    text: '努力加载中...',
    background: 'rgba(255,255,255,.4)',
    target: document.querySelector(targetdq) // 设置加载动画区域
  });
}

function endLoading() {
  loading.close();
}
function showFullScreenLoading(targetdq) {
  if (needLoadingRequestCount === 0) {
    startLoading(targetdq);
  }
  needLoadingRequestCount++;
}
function hideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}
var _default = {
  showFullScreenLoading: showFullScreenLoading,
  hideFullScreenLoading: hideFullScreenLoading
};
exports.default = _default;