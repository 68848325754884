var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-page-base-layout" },
    [
      _c("h4", [_vm._v("自动分配规则")]),
      _vm._v(" "),
      _c("el-divider", [_vm._v("设置自动分配秘书比例")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-all-btn" },
        [
          _c("el-button", { on: { click: _vm.handleAdd } }, [
            _vm._v("添加可分配秘书"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rule-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userName", label: "秘书名称", width: "200" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ratio", label: "案件分配比例", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticStyle: { width: "90%" },
                          attrs: { type: "number" },
                          on: {
                            blur: function ($event) {
                              return _vm.saveRatio(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.ratio,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "ratio", $$v)
                            },
                            expression: "scope.row.ratio",
                          },
                        }),
                        _vm._v("\n          %\n        "),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加可分配秘书",
            visible: _vm.addVisble,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addVisble = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addForm,
                rules: _vm.addRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "秘书", prop: "userId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "70%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addForm.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "userId", $$v)
                        },
                        expression: "addForm.userId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addVisble = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAddSubmit("addForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }