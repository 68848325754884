var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c("div", { staticStyle: { width: "100%", margin: "0 auto" } }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "30%",
              display: "inline-block",
              "vertical-align": "top",
              "padding-right": "100px",
            },
          },
          [
            _c("el-input", {
              attrs: { placeholder: "输入关键字进行过滤" },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
            _vm._v(" "),
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.data,
                "node-key": "id",
                draggable: false,
                "expand-on-click-node": false,
                "filter-node-method": _vm.filterNode,
              },
              on: { "node-click": _vm.handleNodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("span", { staticClass: "custom-tree-node" }, [
                      _c("span", [_vm._v(_vm._s(data.title))]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c("el-button", {
                            staticClass: "el-icon-plus",
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (function () {
                                  return _vm.append(node, data)
                                })($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-button", {
                            staticClass: "el-icon-delete",
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (function () {
                                  return _vm.remove(node, data)
                                })($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.menuVisible
          ? _c(
              "div",
              {
                staticStyle: {
                  width: "40%",
                  display: "inline-block",
                  "border-left": "1px solid #DCDFE6",
                  "padding-left": "20px",
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "editingItem",
                    staticClass: "demo-editingItem",
                    attrs: {
                      model: _vm.editingItem,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _vm.editFlag
                      ? _c("h2", [_vm._v("修改菜单")])
                      : _c("h2", [_vm._v("添加菜单")]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "标题", prop: "title" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "title", $$v)
                            },
                            expression: "editingItem.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "name" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "name", $$v)
                            },
                            expression: "editingItem.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "路径", prop: "path" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.path,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "path", $$v)
                            },
                            expression: "editingItem.path",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "菜单", prop: "url" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.url,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "url", $$v)
                            },
                            expression: "editingItem.url",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "图标", prop: "icon" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.icon,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "icon", $$v)
                            },
                            expression: "editingItem.icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方法", prop: "method" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.editingItem.method,
                              callback: function ($$v) {
                                _vm.$set(_vm.editingItem, "method", $$v)
                              },
                              expression: "editingItem.method",
                            },
                          },
                          _vm._l(_vm.methodData, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "排序", prop: "orderline" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.editingItem.orderline,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "orderline", $$v)
                            },
                            expression: "editingItem.orderline",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态", prop: "state" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.editingItem.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "state", $$v)
                            },
                            expression: "editingItem.state",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否显示", prop: "showState" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.editingItem.showState,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingItem, "showState", $$v)
                            },
                            expression: "editingItem.showState",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                !_vm.editingItem.id
                                  ? _vm.handleAddMenu("editingItem")
                                  : _vm.handleUpdateMenu("editingItem")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }