var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handing global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: { caseReplyCondition: false },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-all-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.batchReview },
            },
            [_vm._v("批量审核")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 13
                          ? _c("span", { staticStyle: { color: "crimson" } }, [
                              _vm._v(_vm._s(_vm.caseState[scope.row.state])),
                            ])
                          : scope.row.state != 13
                          ? _c("span", { staticStyle: { color: "#46a6ff" } }, [
                              _vm._v(_vm._s(_vm.caseState[scope.row.state])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSecretaryName", label: "立案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == "0" ||
                        scope.row.state == "1" ||
                        scope.row.state == "12" ||
                        scope.row.state == "13"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _c("el-button", {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleReview(scope.row)
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择案由",
            visible: _vm.caseBriefVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.caseBriefVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "案由",
                    prop: "reasonId",
                    rules: { required: true, message: "请选择案由" },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.reasonId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reasonId", $$v)
                        },
                        expression: "form.reasonId",
                      },
                    },
                    _vm._l(_vm.reasonList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.reasonName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.caseBriefVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.sumbiting },
                  on: {
                    click: function ($event) {
                      return _vm.sumbitCaseBrief("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }