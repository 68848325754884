var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-container global-page-base-layout" },
    [
      _c(
        "el-row",
        {
          staticClass: "survey-row",
          staticStyle: { "padding-bottom": "35px" },
        },
        [
          _c("div", { staticClass: "row-title" }, [_vm._v("今日数据")]),
          _vm._v(" "),
          _c("el-col", { staticClass: "survey-row-item", attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("img", {
                staticClass: "survey-icon",
                attrs: { src: require("@/assets/case/handing1.svg"), alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "todayData-item" }, [
                _c("p", [_vm._v("受理案件数")]),
                _vm._v(" "),
                _c("p", { staticClass: "num" }, [_vm._v(_vm._s(_vm.handing))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "survey-row-item", attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("img", {
                staticClass: "survey-icon",
                attrs: { src: require("@/assets/case/group1.svg"), alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "todayData-item" }, [
                _c("p", [_vm._v("组庭案件数")]),
                _vm._v(" "),
                _c("p", { staticClass: "num" }, [_vm._v(_vm._s(_vm.group))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "survey-row-item", attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("img", {
                staticClass: "survey-icon",
                attrs: {
                  src: require("@/assets/case/holdCourt1.svg"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "todayData-item" }, [
                _c("p", [_vm._v("开庭案件数")]),
                _vm._v(" "),
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.holdCourt)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "survey-row-item", attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c("img", {
                staticClass: "survey-icon",
                attrs: { src: require("@/assets/case/judge1.svg"), alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "todayData-item" }, [
                _c("p", [_vm._v("裁决案件数")]),
                _vm._v(" "),
                _c("p", { staticClass: "num" }, [_vm._v(_vm._s(_vm.judge))]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-row", { staticClass: "survey-row" }, [
        _c("div", { staticClass: "row-title" }, [_vm._v("待办提醒")]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "handing-table-left" },
            [
              _c(
                "el-col",
                { staticClass: "survey-row-item", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", { staticClass: "case-wait-item" }, [
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.all)),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("全部待办")]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "survey-row-item", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", { staticClass: "case-wait-item" }, [
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.overDays)),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("已逾期")]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "survey-row-item", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", { staticClass: "case-wait-item" }, [
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.toDays)),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("今日内办结")]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "survey-row-item", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", { staticClass: "case-wait-item" }, [
                      _c("p", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.threeDays)),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("3日内办结")]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "handing-table-right" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    "header-cell-style": { background: _vm.tableHeaderColor },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "案号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.caseNo
                              ? _c("a", { staticClass: "link-style" }, [
                                  _vm._v(_vm._s(scope.row.caseNo)),
                                ])
                              : _c("a", { staticClass: "link-style" }, [
                                  _vm._v("暂无案号"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "caseId", label: "案件编号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "caseMsg", label: "待办类型" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "state", label: "办结期限" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.state === 1 && scope.row.day > 0
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.day) +
                                      "天\n            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.state === 1 && scope.row.day === 0
                              ? _c("span", { staticStyle: { color: "blue" } }, [
                                  _vm._v(
                                    "\n                今日\n            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.state === 2
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n              逾期" +
                                      _vm._s(0 - scope.row.day) +
                                      "天\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "reviewTime", label: "最后处理时间" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                【去处理】\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": _vm.pageSizes,
                      "page-size": 10,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }