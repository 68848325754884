"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      ruleForm: {
        SeparateAccountsNum: '',
        SeparateAccounts: '按仲裁费百分比',
        arbitratorPay: '按仲裁费百分比',
        arbitratorPayNum: '',
        clerkPay: '按仲裁费百分比',
        clerkPayNum: ''
      },
      rules: {
        SeparateAccountsNum: [{
          required: true,
          message: '请选择'
        }],
        SeparateAccounts: [{
          required: true,
          message: '请输入'
        }],
        arbitratorPay: [{
          required: true,
          message: '请选择'
        }],
        arbitratorPayNum: [{
          required: true,
          message: '请输入'
        }],
        clerkPay: [{
          required: true,
          message: '请选择'
        }],
        clerkPayNum: [{
          required: true,
          message: '请输入'
        }]
      }
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert('submit!');
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
exports.default = _default;