var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "suppleMaterial global-page-base-layout" },
    [
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _vm._v("\n            案件搜索：\n            "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "medium", placeholder: "当事人" },
                      model: {
                        value: _vm.searchvalue,
                        callback: function ($$v) {
                          _vm.searchvalue = $$v
                        },
                        expression: "searchvalue",
                      },
                    },
                    _vm._l(_vm.searchArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { width: "300px", display: "inline-block" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "medium", placeholder: "请输入内容" },
                        model: {
                          value: _vm.input,
                          callback: function ($$v) {
                            _vm.input = $$v
                          },
                          expression: "input",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n            状态：\n            "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "60%" },
                      attrs: { size: "medium", placeholder: "请选择状态" },
                      model: {
                        value: _vm.state,
                        callback: function ($$v) {
                          _vm.state = $$v
                        },
                        expression: "state",
                      },
                    },
                    _vm._l(_vm.stateArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em", "margin-top": "20px" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "clear-all", on: { click: _vm.clearAllConditon } },
              [_vm._v("清空筛选条件")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "suppleMaterial-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { width: "200", label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", width: "90", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "申请时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeState", label: "组庭前/组庭后发起" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.composeState == 1
                          ? _c("span", [_vm._v("组庭前")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.composeState == 2
                          ? _c("span", [_vm._v("组庭后")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseState", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.caseState[scope.row.caseState]) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "撤案状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("span", [_vm._v("申请")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 1
                          ? _c("span", [_vm._v("待审核")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 2
                          ? _c("span", [_vm._v("待送达")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 3
                          ? _c("span", [_vm._v("已送达")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 4
                          ? _c("span", [_vm._v("审核拒绝")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == "-1"
                          ? _c("span", [_vm._v("秘书拒绝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "reason", width: "200", label: "拒绝原因" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reason.length > 7
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  content: scope.row.reason,
                                  placement: "top-start",
                                  title: "详情",
                                  width: "250",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.reason.substr(0, 8) + "..."
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(scope.row.reason))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == "0" || scope.row.state == 4
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("处理\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == "3"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看\n            ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "撤案处理",
            visible: _vm.reviewVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.reviewVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "reviewForm",
              attrs: { "label-position": "right", "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "撤案理由：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "70%" },
                    attrs: { type: "textarea", disabled: "" },
                    model: {
                      value: _vm.reviewForm.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.reviewForm, "reason", $$v)
                      },
                      expression: "reviewForm.reason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.reviewForm.revokePath
                ? _c(
                    "el-form-item",
                    { attrs: { label: "撤案文件：" } },
                    _vm._l(
                      JSON.parse(_vm.reviewForm.revokePath),
                      function (item, i) {
                        return _c(
                          "div",
                          { key: i },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  href: item.ossHref,
                                  target: "_blank",
                                  type: "primary",
                                  underline: false,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.fileName) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-divider"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.reviewVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleReject("reviewForm")
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAgree("reviewForm")
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝撤案",
            visible: _vm.withdrawRejectVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.withdrawRejectVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "withdrawRejectForm",
              attrs: {
                model: _vm.withdrawRejectForm,
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "拒绝理由：",
                    prop: "reason",
                    rules: { required: true, message: "请填写拒绝理由" },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "70%" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.withdrawRejectForm.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.withdrawRejectForm, "reason", $$v)
                      },
                      expression: "withdrawRejectForm.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.withdrawRejectVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading1, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleNewReject()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同意撤案",
            visible: _vm.withdrawAgreeVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.withdrawAgreeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "withdrawAgreeForm",
              attrs: {
                model: _vm.withdrawAgreeForm,
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "退费金额：",
                    prop: "refund",
                    rules: { required: true, message: "请填写退费金额" },
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "70%" },
                      attrs: { type: "number" },
                      model: {
                        value: _vm.withdrawAgreeForm.refund,
                        callback: function ($$v) {
                          _vm.$set(_vm.withdrawAgreeForm, "refund", $$v)
                        },
                        expression: "withdrawAgreeForm.refund",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "退费处理状态：",
                    prop: "refundState",
                    rules: { required: true, message: "请选择" },
                  },
                },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.withdrawAgreeForm.refundState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.withdrawAgreeForm,
                                "refundState",
                                $$v
                              )
                            },
                            expression: "withdrawAgreeForm.refundState",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "无需退费", value: "0" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已处理", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "未处理", value: "-1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.withdrawAgreeVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.selectVisible = true
                    },
                  },
                },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "送达",
            visible: _vm.sendVisble,
            "show-close": false,
            top: "5vh",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sendVisble = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "sendForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.sendForm, "label-width": "120px" },
                },
                [
                  _c("div", { staticClass: "party" }, [_vm._v("申请方")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送达内容:" } },
                    _vm._l(_vm.fileData.appFileList, function (file) {
                      return _c(
                        "el-link",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            underline: false,
                            type: "primary",
                            href: file.aliyunOssHref,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(file.fileName) + "\n            ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "短信通知:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { type: "textarea", row: "8" },
                        model: {
                          value: _vm.fileData.appSms,
                          callback: function ($$v) {
                            _vm.$set(_vm.fileData, "appSms", $$v)
                          },
                          expression: "fileData.appSms",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "party" }, [_vm._v("被申请方")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送达内容:" } },
                    _vm._l(_vm.fileData.resFileList, function (file) {
                      return _c(
                        "el-link",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            underline: false,
                            type: "primary",
                            href: file.aliyunOssHref,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(file.fileName) + "\n            ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "短信通知:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80%" },
                        attrs: { type: "textarea", row: "8" },
                        model: {
                          value: _vm.fileData.resSms,
                          callback: function ($$v) {
                            _vm.$set(_vm.fileData, "resSms", $$v)
                          },
                          expression: "fileData.resSms",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sendVisble = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.sending },
                  on: {
                    click: function ($event) {
                      return _vm.handleSend()
                    },
                  },
                },
                [_vm._v("送达")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "决定书",
            visible: _vm.selectVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.selectType,
                    callback: function ($$v) {
                      _vm.selectType = $$v
                    },
                    expression: "selectType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("编辑决定书"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("上传决定书"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectShow,
                      expression: "selectShow",
                    },
                  ],
                  staticStyle: { padding: "10px" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-50px",
                        color: "#F56C6C",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        "on-preview": _vm.docHandlePreview,
                        "on-remove": _vm.docHandleRemove,
                        "on-success": _vm.docHandleSuccess,
                        "before-remove": _vm.docBeforeRemove,
                        headers: _vm.headers,
                        multiple: false,
                        limit: 1,
                        accept: ".docx,.doc",
                        "on-exceed": _vm.docHandleExceed,
                        "before-upload": _vm.docBeforeUpload,
                        "file-list": _vm.docFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("\n              支持word格式\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-right",
              staticStyle: { "padding-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingUpload },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpload("1")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "决定书编辑",
            visible: _vm.upcomingVisible,
            top: "0vh",
            fullscreen: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.upcomingVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "50%", margin: "0 auto" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "padding-left": "100px",
                    "padding-right": "100px",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-header" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.dataContentForm.subject) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-subject" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.dataContentForm.title) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "rule-caseno" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.dataContentForm.caseNo) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.dataContent, function (item, index) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: 24 } },
                      [
                        _c("el-input", {
                          staticClass: "rule-input",
                          attrs: {
                            type: "textarea",
                            autosize: "",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: item.text,
                            callback: function ($$v) {
                              _vm.$set(item, "text", $$v)
                            },
                            expression: "item.text",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [_vm._v("[删除]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAdd(index)
                                  },
                                },
                              },
                              [_vm._v("[新增]")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-right",
              staticStyle: { "padding-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upcomingVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingSave },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("1")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }