"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _court = require("@/api/court.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    var dateScope = 7 * 24 * 3600 * 1000;
    return {
      pickerStart: {
        disabledDate: function disabledDate(time) {
          var endLen = _this.endDate;
          if (endLen === 0 || endLen == "" || endLen === null) {
            return time.getTime() < Date.now();
          }
          var date = new Date(endLen).getTime();
          // 返回小于7天的，或都返回当前时间减去7天的
          return time.getTime() > date || time.getTime() < date - dateScope;
        }
      },
      pickerEnd: {
        disabledDate: function disabledDate(time) {
          var startLen = _this.startDate;
          if (startLen === 0 || startLen == "" || startLen === null) {
            return time.getTime() < Date.now();
          }
          var date = new Date(startLen).getTime();
          return time.getTime() < date || time.getTime() > date + dateScope;
        }
      },
      search: '',
      startDate: '',
      endDate: '',
      row1: [
        // {
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'date'
        // },
        // {
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'name'
        // },{
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'province'
        // },{
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'city'
        // },{
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'address'
        // },{
        //   time:'周一',
        //   num:"3场庭审",
        //   prop:'zip'
        // },
      ],
      tableData: []
    };
  },
  methods: {
    init: function init() {},
    postSearch: function postSearch() {
      var _this2 = this;
      (0, _court.getSeclList)({
        startDay: this.startDate,
        endDay: this.endDate,
        userName: ''
      }).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.data.data;
          _this2.row1 = [];
          res.data.days.forEach(function (ele) {
            _this2.row1.push({
              weekDay: ele.weekDay,
              sumCase: ele.sumCase + '场庭审',
              prop: 'caseNo'
            });
          });
          console.log(_this2.row1);
        }
      });
    }
  }
};
exports.default = _default;