var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("el-col", { attrs: { span: 16 } }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.conferenceDetail.name)),
            ]),
            _vm._v(" "),
            !_vm.courtVisible
              ? _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v("已到庭人员"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "200px auto" },
                        attrs: { type: "primary" },
                        on: { click: _vm.startCourt },
                      },
                      [_vm._v("立即开庭")]
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "remote" }, [
                    _c("video", {
                      staticClass: "remote-video",
                      attrs: { autoplay: "", loop: "loop" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "remote" }, [
                    _c("video", {
                      staticClass: "share-video",
                      attrs: { autoplay: "", loop: "loop" },
                    }),
                  ]),
                ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "case-content" },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card" },
                    on: { "tab-click": _vm.tabClick },
                  },
                  [
                    _c("el-tab-pane", { attrs: { label: "参与人" } }, [
                      _c(
                        "div",
                        { staticClass: "party" },
                        _vm._l(_vm.party, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "party-item" },
                            [
                              _c(
                                "el-main",
                                [
                                  _c("el-col", { attrs: { span: 20 } }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        "【" +
                                          _vm._s(
                                            _vm.partyIdType[item.partyType]
                                          ) +
                                          "】-" +
                                          _vm._s(item.name)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "card" }, [
                                      _vm._v(_vm._s(item.idcard)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "state" }, [
                                      item.partyType != 6 && item.partyType != 7
                                        ? _c(
                                            "div",
                                            { staticClass: "state-item" },
                                            [
                                              item.realName
                                                ? _c("span", {
                                                    staticClass: "active",
                                                  })
                                                : _c("span", {
                                                    staticClass: "unactive",
                                                  }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("实名认证")]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.partyType != 6 && item.partyType != 7
                                        ? _c(
                                            "div",
                                            { staticClass: "state-item" },
                                            [
                                              item.trialTest
                                                ? _c("span", {
                                                    staticClass: "active",
                                                  })
                                                : _c("span", {
                                                    staticClass: "unactive",
                                                  }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("庭前测试")]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _c("div", { staticClass: "online" }, [
                                      item.onlineState == 1
                                        ? _c("span", { staticClass: "active" })
                                        : _c("span", {
                                            staticClass: "unactive",
                                          }),
                                      _vm._v(" "),
                                      item.onlineState == 0
                                        ? _c("span", [_vm._v("离线")])
                                        : _c("span", [_vm._v("在线")]),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "operat" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "operat-btn",
                                      attrs: {
                                        size: "small",
                                        disabled: item.onlineState == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.controlBtn(
                                            item,
                                            "关闭摄像头"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("关闭摄像头\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "operat-btn",
                                      attrs: {
                                        size: "small",
                                        disabled: item.onlineState == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.controlBtn(
                                            item,
                                            "开启摄像头"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("开启摄像头\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "operat-btn",
                                      attrs: {
                                        size: "small",
                                        disabled: item.onlineState == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.controlBtn(
                                            item,
                                            "开启麦克风"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("开启麦克风\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "operat-btn",
                                      attrs: {
                                        size: "small",
                                        disabled: item.onlineState == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.controlBtn(
                                            item,
                                            "关闭麦克风"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("关闭麦克风\n                  ")]
                                  ),
                                  _vm._v(" "),
                                  item.partyType != 6 && item.partyType != 7
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "operat-btn",
                                          attrs: {
                                            disabled: item.onlineState == 0,
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.controlBtn(
                                                item,
                                                "举手发言"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("举手发言\n                  ")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.partyType != 6 && item.partyType != 7
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "operat-btn",
                                          attrs: {
                                            disabled: item.onlineState == 0,
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.controlVideoMain(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "设为主屏幕\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.partyType == 6
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "operat-btn",
                                          attrs: {
                                            disabled: item.onlineState == 0,
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setEquipmentFocus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "设为主屏幕\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.partyType == 6
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "operat-btn",
                                          attrs: {
                                            disabled: item.onlineState == 0,
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.inviteEquipment()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "设备加入会议\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { label: "案情" } }, [
                      _c("div", { staticClass: "case" }, [
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v("案 号：" + _vm._s(_vm.allDetail.caseNo)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v("案 由：" + _vm._s(_vm.allDetail.reason)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "仲裁金额：" +
                              _vm._s(_vm.allDetail.controversyAmount)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v("提交时间：" + _vm._s(_vm.allDetail.ctime)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "立案时间：" + _vm._s(_vm.allDetail.registerTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "仲裁请求：" +
                              _vm._s(_vm.allDetail.arbitrationRequest)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "事实与理由：" +
                              _vm._s(_vm.allDetail.arbitrationAruth)
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { label: "案件材料" } }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 20px", height: "580px" } },
                        [
                          _c(
                            "div",
                            { staticClass: "material-header" },
                            _vm._l(_vm.materialTabList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "header-item",
                                  class:
                                    _vm.materialTab == index ? "active" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.materialChange(index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item) + "\n                ")]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.materialTab == 0
                            ? _c(
                                "div",
                                { staticClass: "material-content" },
                                [
                                  _vm._l(
                                    _vm.materialList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "material-content-item",
                                        },
                                        [
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 20 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  item.partyName
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "material-content-row",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "提交人：" +
                                                              _vm._s(
                                                                item.partyName
                                                              )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 4 },
                                                },
                                                [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        href: item.aliyunOssHref,
                                                        target: "_blank",
                                                        underline: false,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "查看\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "more-tip" }, [
                                    _vm._v("没有更多了"),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialTab == 1
                            ? _c(
                                "div",
                                { staticClass: "material-content" },
                                [
                                  _vm._l(
                                    _vm.appProofFileList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "material-content-item",
                                        },
                                        [
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 20 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "提交人：" +
                                                          _vm._s(item.partyName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 4 },
                                                },
                                                [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        href: item.aliyunOssHref,
                                                        target: "_blank",
                                                        underline: false,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "查看\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "more-tip" }, [
                                    _vm._v("没有更多了"),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialTab == 2
                            ? _c(
                                "div",
                                { staticClass: "material-content" },
                                [
                                  _vm._l(
                                    _vm.resProofFileList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "material-content-item",
                                        },
                                        [
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 20 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "提交人：" +
                                                          _vm._s(item.partyName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 4 },
                                                },
                                                [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        href: item.aliyunOssHref,
                                                        target: "_blank",
                                                        underline: false,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "查看\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "more-tip" }, [
                                    _vm._v("没有更多了"),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.materialTab == 3
                            ? _c(
                                "div",
                                { staticClass: "material-content" },
                                [
                                  _vm._l(
                                    _vm.caseArbitralFilesDtoList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "material-content-item",
                                        },
                                        [
                                          _c(
                                            "el-main",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 20 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "material-content-row",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 4 },
                                                },
                                                [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        href: item.aliyunOssHref,
                                                        target: "_blank",
                                                        underline: false,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "查看\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "more-tip" }, [
                                    _vm._v("没有更多了"),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { label: "庭审笔录" } }, [
                      _c("div", { staticClass: "case" }, [
                        _vm.currentRole == 4
                          ? _c(
                              "div",
                              [
                                _c("tinymce", {
                                  ref: "tinymce",
                                  attrs: { height: 500 },
                                  model: {
                                    value: _vm.recordContent,
                                    callback: function ($$v) {
                                      _vm.recordContent = $$v
                                    },
                                    expression: "recordContent",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", {
                              staticStyle: {
                                height: "500px",
                                overflow: "hidden",
                                "margin-left": "20px",
                                "padding-top": "5px",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.recordContent),
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "record-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.checkRecord },
                              },
                              [_vm._v("实时查询笔录")]
                            ),
                            _vm._v(" "),
                            _vm.currentRole == 4
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveRecord },
                                  },
                                  [_vm._v("保存笔录")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { label: "庭审信息" } }, [
                      _c("div", { staticClass: "case" }, [
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "庭审名称：" + _vm._s(_vm.conferenceDetail.name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "会议号码：" +
                              _vm._s(_vm.conferenceDetail.conferenceNo)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "会议密码：" +
                              _vm._s(_vm.conferenceDetail.conferencePwd)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "仲裁员：" +
                              _vm._s(_vm.conferenceDetail.arbitratorName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "书记员：" +
                              _vm._s(_vm.conferenceDetail.handingSecretaryName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-item" }, [
                          _vm._v(
                            "庭审时间：" +
                              _vm._s(_vm.conferenceDetail.day) +
                              " " +
                              _vm._s(_vm.conferenceDetail.timer)
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("el-col", { staticClass: "footer-main", attrs: { span: 16 } }, [
            _vm.currentRole == 5
              ? _c(
                  "div",
                  {
                    staticClass: "footer-item",
                    on: {
                      click: function ($event) {
                        return _vm.endConferences()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/video/endCourt.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("结束庭审")]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "footer-item" }, [
              _c("img", {
                attrs: { src: require("@/assets/video/sign.svg"), alt: "" },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("笔录签名")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "异常反馈", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "无声音" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "无视频画面" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请发言",
            visible: _vm.dialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定申请发言吗？仲裁员审核通过后可进行发言。")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }