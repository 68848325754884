"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _utils = require("@/views/trial/videoTrial/demo/components/utils/utils.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "userIdInput",
  props: {
    label: String
  },
  data: function data() {
    return {
      type: "string",
      infoValue: ""
    };
  },
  watch: {
    infoValue: {
      immediate: true,
      handler: function handler(val) {
        this.$emit("change", this.type === "number" ? Number(val) : val);
      }
    }
  },
  mounted: function mounted() {
    switch (this.label) {
      case "userId":
        {
          var userId = (0, _utils.getUrlParam)("userId");
          this.infoValue = userId ? userId : "user_".concat(parseInt(Math.random() * 100000000, 10));
          break;
        }
      case "roomId":
        {
          var roomId = (0, _utils.getUrlParam)("roomId");
          this.type = "number";
          this.infoValue = roomId ? roomId : 87470;
          break;
        }
      case "sdkAppId":
        {
          var sdkAppId = (0, _utils.getUrlParam)("sdkAppId");
          this.type = "number";
          this.infoValue = sdkAppId ? sdkAppId : "1400665003";
          break;
        }
      case "secretKey":
        {
          var secretKey = (0, _utils.getUrlParam)("secretKey");
          this.infoValue = secretKey ? secretKey : "c27a5108649cb033dbc925b8deef68c14e4bd00812b3ba9df19fb70d685b48b1";
          break;
        }
      default:
        break;
    }
  }
};
exports.default = _default;