var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organ-manage global-page-base-layout" },
    [
      _c(
        "div",
        { staticClass: "add-people" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoCreate } },
            [_vm._v("添加仲裁机构")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _vm._v("\n              仲裁机构名称：\n               "),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n              状态：\n              "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择状态" },
                      model: {
                        value: _vm.statevalue,
                        callback: function ($$v) {
                          _vm.statevalue = $$v
                        },
                        expression: "statevalue",
                      },
                    },
                    _vm._l(_vm.stateArr, function (item) {
                      return _c("el-option", {
                        key: item.status,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em", "margin-top": "20px" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "clear-all", on: { click: _vm.clearAllConditon } },
              [_vm._v("清空筛选条件")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "仲裁机构名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "官网" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: scope.row.website,
                              type: "primary",
                              underline: false,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.website))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "电话" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(scope.row.state == 1 ? "已启用" : "已禁用") +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "设置时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "addOrgan",
          attrs: {
            title: _vm.title,
            visible: _vm.addOrganVisible,
            top: "5vh",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrganVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addOrganForm",
              attrs: {
                model: _vm.addOrganForm,
                "label-position": "left",
                "label-width": "140px",
                rules: _vm.addOrganRules,
              },
            },
            [
              _c("span", [_vm._v("仲裁机构信息")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仲裁机构名称", prop: "organName" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off", placeholder: "" },
                    model: {
                      value: _vm.addOrganForm.organName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "organName", _vm._n($$v))
                      },
                      expression: "addOrganForm.organName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "address" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off", placeholder: "详细地址" },
                    model: {
                      value: _vm.addOrganForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "address", $$v)
                      },
                      expression: "addOrganForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "organPhone" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: {
                      autocomplete: "off",
                      placeholder: "手机号码或者座机号码",
                    },
                    model: {
                      value: _vm.addOrganForm.organPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addOrganForm,
                          "organPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addOrganForm.organPhone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "官网", prop: "website" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addOrganForm.website,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "website", $$v)
                      },
                      expression: "addOrganForm.website",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "desc" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addOrganForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "desc", $$v)
                      },
                      expression: "addOrganForm.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "addpersonform-item",
                      model: {
                        value: _vm.addOrganForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addOrganForm, "status", $$v)
                        },
                        expression: "addOrganForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用", value: "1" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("签章设置")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "通用章" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "-130px",
                        top: "0",
                        color: "#F56C6C",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove1,
                        "on-success": _vm.handleSuccess1,
                        multiple: "",
                        accept: ".jpg",
                        limit: 1,
                        "before-upload": _vm.beforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList1,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "通用公章，无特殊签章时的默认公章。请上传不大于5M的JPG图片，并且保证图片清晰。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.haveTo
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#F56C6C",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v("请填写完整信息和上传公章")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "立案章" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove2,
                        "on-success": _vm.handleSuccess2,
                        accept: ".jpg",
                        multiple: "",
                        limit: 1,
                        "before-upload": _vm.beforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList2,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "立案专用公章。请上传不大于 5M 的 JPG 图片，并且保证图片清晰。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组庭章" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove3,
                        "on-success": _vm.handleSuccess3,
                        multiple: "",
                        accept: ".jpg",
                        limit: 1,
                        "before-upload": _vm.beforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList3,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "组庭专用公章。请上传不大于 5M 的 JPG 图片，并且保证图片清晰。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开庭章" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove4,
                        "on-success": _vm.handleSuccess4,
                        multiple: "",
                        accept: ".jpg",
                        limit: 1,
                        "before-upload": _vm.beforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList4,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "开庭专用公章。请上传不大于 5M 的 JPG 图片，并且保证图片清晰。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "裁决章" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove5,
                        "on-success": _vm.handleSuccess5,
                        multiple: "",
                        accept: ".jpg",
                        limit: 1,
                        "before-upload": _vm.beforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList5,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "裁决专用公章。请上传不大于 5M 的 JPG 图片，并且保证图片清晰。"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("logo设置")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "logo上传" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.action,
                        headers: _vm.headers,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleLogoRemove,
                        "on-success": _vm.handleLogoSuccess,
                        multiple: "",
                        accept: ".png,.jpg",
                        limit: 1,
                        "before-upload": _vm.beforeLogoUpload,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.logoFileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("只能上传jpg/png文件，且不超过5M。")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("oss地址配置")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "accessKeyId", prop: "accessKeyId" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off", placeholder: "" },
                    model: {
                      value: _vm.addOrganForm.accessKeyId,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "accessKeyId", _vm._n($$v))
                      },
                      expression: "addOrganForm.accessKeyId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "accessKeySecret", prop: "accessKeySecret" },
                },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off", placeholder: "" },
                    model: {
                      value: _vm.addOrganForm.accessKeySecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "accessKeySecret", $$v)
                      },
                      expression: "addOrganForm.accessKeySecret",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "bucketName", prop: "bucketName" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addOrganForm.bucketName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "bucketName", _vm._n($$v))
                      },
                      expression: "addOrganForm.bucketName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "endpoint", prop: "endpoint" } },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addOrganForm.endpoint,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrganForm, "endpoint", _vm._n($$v))
                      },
                      expression: "addOrganForm.endpoint",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "endpointExtranet",
                    prop: "endpointExtranet",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "addOrganform-item",
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addOrganForm.endpointExtranet,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addOrganForm,
                          "endpointExtranet",
                          _vm._n($$v)
                        )
                      },
                      expression: "addOrganForm.endpointExtranet",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addOrganVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitOrganForm("addOrganForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }