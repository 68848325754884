"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _index = _interopRequireDefault(require("@/views/caseConditionsExt/index.vue"));
var _caseRevoke = require("@/api/caseRevoke");
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "bookExam",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      tableData: [],
      multipleSelection: [],
      caseState: _dictionary.dictionary.caseRevokeStats,
      opinionVisble: false,
      //  确认送达弹窗显示
      opinionform: {
        txt: "",
        radio: "通过审核",
        dealperson: ""
      },
      conditionData: {
        // states: [42, 43, 44, 45, 46]
      },
      //筛选条件
      dealPeople: [],
      //处理人列表
      currentCaseId: "",
      //当前的caseid
      currentId: "",
      examineData: "",
      btnLoading: false,
      //按钮loading
      ruleFile: {},
      closeCaseVisible: false,
      gotoServicing: false,
      rejectLoading: false,
      rejectVisible: false,
      //拒绝弹窗
      opinionform1: {
        //拒绝表单
        txt: ""
      }
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      this.currentPage = 1;
      this.loading = true;
      (0, _caseRevoke.getFindRevokeCasePage)({
        state: 1,
        limit: this.pageLimit,
        page: this.currentPage
        // states: [42, 43, 44, 45, 46]
      }).then(function (res) {
        _this.loading = false;
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    //重新加载列表
    refreshList: function refreshList() {
      var _this2 = this;
      this.loading = true;
      if (this.conditionData.length > 0) {
        this.conditionData.page = this.currentPage;
        this.conditionData.state = 1;
        (0, _caseRevoke.getFindRevokeCasePage)(this.conditionData).then(function (res) {
          _this2.loading = false;
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      } else {
        (0, _caseRevoke.getFindRevokeCasePage)({
          limit: 10,
          state: 1,
          page: this.currentPage
          // states: [42, 43, 44, 45, 46]
        }).then(function (res) {
          _this2.loading = false;
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        state: 1,
        limit: this.pageLimit,
        page: 1
      };
      if (data.caseId) postData.caseId = data.caseId;
      if (data.caseNo) postData.caseNo = data.caseNo;
      if (data.reviewName) postData.reviewName = data.reviewName;
      if (data.time && data.time.length > 0) {
        postData.beginTime = data.time[0];
        postData.endTime = data.time[1];
      }
      this.conditionData = postData;
      this.loading = true;
      (0, _caseRevoke.getFindRevokeCasePage)(this.conditionData).then(function (res) {
        _this3.loading = false;
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        } else {
          _this3.tableData = [];
          _this3.total = 0;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格  点击案号
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //表格  审核
    handleClick: function handleClick(val) {
      var _this4 = this;
      this.currentId = val.id;
      this.currentCaseId = val.caseId;
      this.currentNode = val.node;
      (0, _caseRevoke.onFindRevokeInfo)(val.caseId).then(function (res) {
        if (res.code == 1) {
          _this4.ruleFile = res.data;
          _this4.closeCaseVisible = true;
        }
      });
    },
    // 通过审核
    adoptCase: function adoptCase() {
      var _this5 = this;
      this.gotoServicing = true;
      // 2.审核通过。4.审核拒绝 
      //refuseReason  拒绝理由
      (0, _caseRevoke.onCaseRevokeAudit)({
        id: this.currentId,
        state: 2
      }).then(function (res) {
        _this5.gotoServicing = false;
        if (res.code == 1) {
          _this5.$message.success("审批成功!");
          _this5.gotoServicing = false;
          _this5.closeCaseVisible = false;
          _this5.refreshList();
        } else {
          _this5.$message.warning("审批失败!");
        }
      });
    },
    handleRefuseClick: function handleRefuseClick(val) {
      this.rejectVisible = true;
      this.currentCaseId = val.caseId;
      this.currentNode = val.node;
    },
    //拒绝表单
    confirmReject: function confirmReject(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.rejectLoading = true;
          (0, _caseRevoke.onCaseRevokeAudit)({
            id: _this6.currentId,
            refuseReason: _this6.opinionform.txt,
            state: 4
          }).then(function (res) {
            _this6.rejectLoading = false;
            _this6.rejectVisible = false;
            if (res.code == 1) {
              _this6.$message.success("审批成功!");
              _this6.gotoServicing = false;
              _this6.closeCaseVisible = false;
              _this6.refreshList();
            } else {
              _this6.$message.warning("审批失败!");
            }
          }).catch(function (error) {
            _this6.rejectLoading = false;
          });
        }
      });
    },
    changeAdvice: function changeAdvice(val) {
      if (val == "通过审核") {
        this.opinionform.dealperson = this.dealPeople[0].name;
        this.opinionform.txt = this.examineData[0].content;
      } else {
        this.opinionform.txt = "";
      }
    },
    // 处理人列表改变时
    changeList: function changeList(val) {},
    //重置审核表单
    resetOpinionForm: function resetOpinionForm(formName) {
      this.$refs[formName].resetFields();
      this.opinionVisble = false;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;
      this.pageLimit = val;
      this.loading = true;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _caseRevoke.getFindRevokeCasePage)(this.conditionData).then(function (res) {
          _this7.loading = false;
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _caseRevoke.getFindRevokeCasePage)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          _this7.loading = false;
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this8 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.loading = true;
        (0, _caseRevoke.getFindRevokeCasePage)(this.conditionData).then(function (res) {
          _this8.loading = false;
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _caseRevoke.getFindRevokeCasePage)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          _this8.loading = false;
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      }
    },
    // 表格 审核按钮
    check: function check(data) {
      this.opinionVisble = true;
    },
    //提交表单
    submitForm: function submitForm(data) {
      this.opinionVisble = false;
    }
  }
};
exports.default = _default;