var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _c(
            "router-link",
            {
              key: "collapse",
              staticClass: "sidebar-logo-link",
              attrs: { to: "" },
            },
            [
              _c("img", {
                staticClass: "sidebar-logo",
                staticStyle: { background: "#fff", "border-radius": "50%" },
                attrs: { src: _vm.logo },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "sidebar-title" }, [
                _vm._v(_vm._s(_vm.title) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }