"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'createSettlement',
  data: function data() {
    return {
      cycleVal: '',
      //周期值
      loading: false,
      staffListVisible: false,
      //发放对象弹窗显示
      multipleSelection: [],
      tableData1: [{
        name: '王小虎1',
        duty: '前台',
        department: '技术部',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        name: '王小虎2',
        duty: '产品',
        department: '运营部',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        name: '王小虎3',
        duty: '测试',
        department: '技术部',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        name: '王小虎4',
        duty: '秘书',
        department: '技术部',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
      search: '',
      options: [{
        value: '技术部',
        label: '技术部'
      }, {
        value: '运营部',
        label: '运营部'
      }],
      aa: '',
      tableData: [{
        money: '12987122',
        name: '张时',
        category: '江浙小吃、小吃零食',
        time: '2019-08-01 至 2019-08-31',
        address: '上海市普陀区真北路',
        shop: '王小虎夫妻店',
        shopId: '10333',
        tableData1: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }]
      }, {
        money: '12987123',
        name: '张时',
        category: '江浙小吃、小吃零食',
        time: '2019-08-01 至 2019-08-31',
        address: '上海市普陀区真北路',
        shop: '王小虎夫妻店',
        shopId: '10333',
        tableData1: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }]
      }, {
        money: '12987125',
        name: '张时',
        category: '江浙小吃、小吃零食',
        time: '2019-08-01 至 2019-08-31',
        address: '上海市普陀区真北路',
        shop: '王小虎夫妻店',
        shopId: '10333',
        tableData1: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }]
      }, {
        money: '12987126',
        name: '张时',
        category: '江浙小吃、小吃零食',
        time: '2019-08-01 至 2019-08-31',
        address: '上海市普陀区真北路',
        shop: '王小虎夫妻店',
        shopId: '10333',
        tableData1: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }]
      }],
      noteMakingVisible: false,
      //制单备注显示
      noteMakeVal: '',
      //制单备注内容
      selectPeople: []
    };
  },
  methods: {
    dateChange: function dateChange() {},
    //选择发放对象
    staffListShow: function staffListShow() {
      this.staffListVisible = true;
    },
    //行被点击
    rowExpand: function rowExpand(row, expandedRows) {},
    // 发放对象的勾选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selecArr = JSON.parse(JSON.stringify(val));
    },
    // 发放人员弹窗确定按钮
    chooseStaffConfirm: function chooseStaffConfirm() {
      this.staffListVisible = false;
      this.selectPeople = this.selecArr;
    },
    // 已经选择的人员的删除标签
    handleClose: function handleClose(val, index) {
      this.selectPeople.splice(index, 1); //selectPeople为最后发给后台的数据
    }
  }
};
exports.default = _default;