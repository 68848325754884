"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry");
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
var _arbitrator = require("@/api/arbitrator");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "service",
  data: function data() {
    return {
      //hades add code
      upcomingVisible: false,
      id: "",
      caseId: "",
      partyInfos: {
        title: "",
        content: ""
      },
      partyContent: {
        title: "",
        content: ""
      },
      partyContents: {
        title: "",
        content: ""
      },
      caseContents: {
        title: "",
        content: ""
      },
      arbitrationContents: {
        title: "",
        content: ""
      },
      ruleContents: {
        title: "",
        content: ""
      },
      caseAwardContentDto: {
        title: "",
        caseNo: "",
        subject: ""
      },
      loadingSave: false,
      loadingShow: false,
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      tableData: [],
      multipleSelection: [],
      multipleSelectionId: [],
      caseState: _dictionary.dictionary.caseState,
      opinionVisble: false,
      //  确认送达弹窗显示
      opinionform: {
        txt: "",
        radio: "通过审核",
        dealperson: ""
      },
      conditionData: "",
      //筛选条件
      dealPeople: [],
      //处理人列表
      currentCaseId: "",
      //当前的caseid
      examineData: "",
      btnLoading: false,
      //按钮loading
      ruleFile: {},
      closeCaseVisible: false,
      gotoServicing: false,
      rejectVisible: false,
      //拒绝弹窗
      opinionform1: {
        //拒绝表单
        txt: ""
      },
      handingSecretaryName: "",
      handingSecretaryId: ""
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      this.currentPage = 1;
      (0, _inquiry.getProcessList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    resetHanding: function resetHanding() {
      this.handingSecretaryId = "";
      this.handingSecretaryName = "";
    },
    //重新加载列表
    refreshList: function refreshList() {
      var _this2 = this;
      this.resetHanding();
      if (this.conditionData.length > 0) {
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      } else {
        (0, _inquiry.getProcessList)({
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginRuleTime: data.rowSecondDate[0],
        endRuleTime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量审核
    batchCheck: function batchCheck() {
      var _this4 = this;
      this.$confirm("确定批量审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.multipleSelectionId = [];
        _this4.multipleSelection.forEach(function (item) {
          _this4.multipleSelectionId.push(item.caseId);
        });
        (0, _inquiry.batchProcedureByAdopt)({
          caseIds: _this4.multipleSelectionId
        }).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: "批量审核成功",
              type: "success"
            });
            _this4.init();
          } else {
            _this4.$message.error(res.msg);
          }
        });
      });
    },
    // 表格  点击案号
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //表格  审核
    handleClick: function handleClick(val) {
      var _this5 = this;
      this.resetHanding();
      this.currentCaseId = val.caseId;
      this.currentNode = val.node;
      this.handingSecretaryId = val.handingSecretaryId;
      this.handingSecretaryName = val.handingSecretaryName;
      if (val.state == 42 || val.state == 43) {
        (0, _inquiry.createRuledFile)({
          caseId: val.caseId
        }).then(function (res) {
          if (res.code == 1) {
            _this5.ruleFile = res.data;
            _this5.closeCaseVisible = true;
          } else {
            _this5.$message.error(res.msg);
          }
        });
      } else {
        (0, _inquiry.getDealPeopleList)({
          caseId: val.caseId,
          node: val.node
        }).then(function (res) {
          if (res.code == 1) {
            _this5.opinionVisble = true;
            _this5.examineData = res.data;
            _this5.dealPeople = res.data[0].allReviews;
            _this5.opinionform.dealperson = _this5.dealPeople[0].name;
            _this5.opinionform.txt = res.data[0].content;
          }
        });
      }
    },
    //编辑裁决书
    handleVerdict: function handleVerdict(data) {
      var _this6 = this;
      this.upcomingVisible = true;
      // debugger;
      (0, _arbitrator.getAwardDetail)(data.caseId).then(function (res) {
        if (res.code === 1) {
          var data = res.data;
          //  this.partyInfos=res.partyInfos;

          _this6.caseContents = data.caseContents || {};
          _this6.id = data.id;
          _this6.partyContents = data.partyContents || {};
          _this6.caseId = data.caseId;
          _this6.arbitrationContents = data.arbitrationContents || {};
          _this6.ruleContents = data.ruleContents || {};
          _this6.caseAwardContentDto.subject = data.subject;
          _this6.caseAwardContentDto.title = data.title;
          _this6.caseAwardContentDto.caseNo = data.caseNo;
        }
      });
    },
    // 通过审核
    adoptCase: function adoptCase() {
      var _this7 = this;
      this.gotoServicing = true;
      (0, _inquiry.procedureByAdopt)({
        reviewId: this.handingSecretaryId,
        reviewName: this.handingSecretaryName,
        caseId: this.currentCaseId,
        node: this.currentNode
      }).then(function (res) {
        if (res.code == 1) {
          _this7.gotoServicing = false;
          _this7.closeCaseVisible = false;
          _this7.refreshList();
        }
      });
    },
    //拒绝表单
    confirmReject: function confirmReject(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _inquiry.procedureByRefuse)({
            caseId: _this8.currentCaseId,
            node: _this8.currentNode,
            content: _this8.opinionform1.txt
          }).then(function (res) {
            if (res.code == 1) {
              _this8.$refs[formName].resetFields();
              _this8.rejectVisible = false;
              _this8.closeCaseVisible = false;
              _this8.refreshList();
            }
          });
        } else {
          return false;
        }
      });
    },
    changeAdvice: function changeAdvice(val) {
      if (val == "通过审核") {
        this.opinionform.dealperson = this.dealPeople[0].name;
        this.opinionform.txt = this.examineData[0].content;
      } else {
        this.opinionform.txt = "";
      }
    },
    // 处理人列表改变时
    changeList: function changeList(val) {
      // let item = this.dealPeople.find((ele)=>{
      //   return ele.id == val
      // })
      // this.opinionform.txt = item.content;
    },
    //重置审核表单
    resetOpinionForm: function resetOpinionForm(formName) {
      this.$refs[formName].resetFields();
      this.opinionVisble = false;
    },
    // 提交审核意见
    sumbitOpinionForm: function sumbitOpinionForm(formName) {
      var _this9 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var index = _this9.dealPeople.findIndex(function (ele) {
            return ele.name == _this9.opinionform.dealperson;
          });
          if (_this9.opinionform.radio == "通过审核") {
            _this9.btnLoading = true;
            (0, _inquiry.procedureByAdopt)({
              caseId: _this9.currentCaseId,
              reviewId: _this9.dealPeople[index].id,
              reviewName: _this9.opinionform.dealperson,
              content: _this9.opinionform.txt,
              node: _this9.currentNode
            }).then(function (res) {
              if (res.code == 1) {
                _this9.btnLoading = false;
                _this9.$refs[formName].resetFields();
                _this9.opinionVisble = false;
                _this9.refreshList();
              }
            });
          } else if (_this9.opinionform.radio == "拒绝") {
            (0, _inquiry.procedureByRefuse)({
              caseId: _this9.currentCaseId,
              content: _this9.opinionform.txt
            }).then(function (res) {
              if (res.code == 1) {
                _this9.$refs[formName].resetFields();
                _this9.opinionVisble = false;
                _this9.refreshList();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this10 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getProcessList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this11 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getProcessList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this11.tableData = res.rows;
            _this11.total = res.total;
            _this11.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getProcessList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this11.tableData = res.rows;
            _this11.total = res.total;
            _this11.currentPage = val;
          }
        });
      }
    },
    // 表格 审核按钮
    check: function check(data) {
      this.opinionVisble = true;
    },
    //提交表单
    submitForm: function submitForm(data) {
      this.opinionVisble = false;
    },
    handleDelete1: function handleDelete1(val) {
      if (this.partyInfos.content.length == 1) {
        this.$message.error("当事人信息不能为空");
      } else {
        this.partyInfos.content.splice(val, 1);
      }
    },
    handleDelete2: function handleDelete2(val) {
      this.partyContents.content.splice(val, 1);
    },
    handleDelete3: function handleDelete3(val) {
      this.caseContents.content.splice(val, 1);
    },
    handleDelete4: function handleDelete4(val) {
      this.arbitrationContents.content.splice(val, 1);
    },
    handleDelete5: function handleDelete5(val) {
      this.ruleContents.content.splice(val, 1);
    },
    handleAdd1: function handleAdd1(val) {
      this.partyInfos.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd2: function handleAdd2(val) {
      this.partyContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd3: function handleAdd3(val) {
      this.caseContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd4: function handleAdd4(val) {
      this.arbitrationContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd5: function handleAdd5(val) {
      this.ruleContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleGetRule: function handleGetRule() {
      var textareas1 = document.getElementById("rule1").getElementsByTagName("textarea");
      var newText1 = [];
      if (textareas1.length > 0) {
        for (var i = 0; i < textareas1.length; i++) {
          newText1.push({
            text: textareas1[i].value
          });
        }
      }
      var textareas2 = document.getElementById("rule2").getElementsByTagName("textarea");
      var newText2 = [];
      if (textareas2.length > 0) {
        for (var i = 0; i < textareas2.length; i++) {
          newText2.push({
            text: textareas2[i].value
          });
        }
      }
      var textareas3 = document.getElementById("rule3").getElementsByTagName("textarea");
      var newText3 = [];
      if (textareas3.length > 0) {
        for (var i = 0; i < textareas3.length; i++) {
          newText3.push({
            text: textareas3[i].value
          });
        }
      }
      var textareas4 = document.getElementById("rule4").getElementsByTagName("textarea");
      var newText4 = [];
      if (textareas4.length > 0) {
        for (var i = 0; i < textareas4.length; i++) {
          newText4.push({
            text: textareas4[i].value
          });
        }
      }
      var textareas5 = document.getElementById("rule5").getElementsByTagName("textarea");
      var newText5 = [];
      if (textareas5.length > 0) {
        for (var i = 0; i < textareas5.length; i++) {
          newText5.push({
            text: textareas5[i].value
          });
        }
      }
      var title1 = "";
      var title2 = "";
      var title3 = "";
      var title4 = "";
      var title5 = "";
      if (this.partyInfos.title) {
        title1 = document.getElementById("title1").value;
      }
      if (this.partyContents.title) {
        title2 = document.getElementById("title2").value;
      }
      if (this.caseContents.title) {
        title3 = document.getElementById("title3").value;
      }
      if (this.arbitrationContents.title) {
        title4 = document.getElementById("title4").value;
      }
      if (this.ruleContents.title) {
        title5 = document.getElementById("title5").value;
      }
      if (newText1.length == 1 && newText1[0].text == "") {
        this.$message.error("当事人信息不能为空");
        return false;
      } else {
        var ruleObj = {};
        return ruleObj = {
          partyInfo: JSON.stringify({
            title: title1,
            content: newText1
          }),
          partyContent: JSON.stringify({
            title: title2,
            content: newText2
          }),
          caseContent: JSON.stringify({
            title: title3,
            content: newText3
          }),
          arbitrationContent: JSON.stringify({
            title: title4,
            content: newText4
          }),
          ruleContent: JSON.stringify({
            title: title5,
            content: newText5
          })
        };
      }
    },
    hanleClose: function hanleClose() {
      this.upcomingVisible = false;
    },
    handleSave: function handleSave(type) {
      var _this12 = this;
      if (!document.getElementById("subject").value) {
        this.$message.error("裁决书主题不能为空");
        return;
      }
      if (!document.getElementById("title").value) {
        this.$message.error("裁决书标题不能为空");
        return;
      }
      if (!document.getElementById("caseNo").value) {
        this.$message.error("裁决书编号不能为空");
        return;
      }
      var ruleData = this.handleGetRule();
      if (ruleData) {
        var ruleStr = ruleData.partyInfo + ruleData.partyContent + ruleData.caseContent + ruleData.arbitrationContent + ruleData.ruleContent;
        if (!ruleStr) {
          this.$message.error("裁决书内容不能为空");
          return;
        }
        var params = {
          caseId: this.caseId,
          id: this.id,
          subject: document.getElementById("subject").value,
          title: document.getElementById("title").value,
          caseNo: document.getElementById("caseNo").value,
          partyInfo: ruleData.partyInfo,
          partyContent: ruleData.partyContent,
          caseContent: ruleData.caseContent,
          arbitrationContent: ruleData.arbitrationContent,
          ruleContent: ruleData.ruleContent,
          caseBatchRule: {
            rule: ruleStr
          }
        };
        if (type == 1) {
          this.loadingSave = true;
          this.loadingPass = false;
          this.loadingShow = false;
        }
        if (type == 2) {
          this.loadingSave = false;
          this.loadingPass = false;
          this.loadingShow = true;
        }
        if (type == 3) {
          this.loadingSave = false;
          this.loadingPass = true;
          this.loadingShow = false;
        }
        (0, _arbitrator.saveCaseRule)(params).then(function (res) {
          if (res.code == 1 && type == 1) {
            _this12.$message({
              message: "保存成功",
              type: "success"
            });
            _this12.loadingSave = false;
            _this12.loadingPass = false;
            _this12.loadingShow = false;
            _this12.upcomingVisible = false;
            _this12.init();
          } else if (res.code == 1 && type == 2) {
            //保存预览
            _this12.$message({
              message: "保存成功",
              type: "success"
            });
            _this12.loadingSave = false;
            _this12.loadingSave = false;
            _this12.loadingPass = false;
            _this12.loadingShow = false;
            window.open(res.data);
          } else if (res.code == 1 && type == 3) {
            //保存通过
            _this12.$message({
              message: "保存成功",
              type: "success"
            });
            casesProcessAdopt({
              caseId: _this12.caseId
            }).then(function (res0) {
              if (res0.code == 1) {
                createRuleFile1(_this12.caseId).then(function (res1) {
                  if (res1.code == 1) {
                    _this12.$message({
                      message: "审核成功",
                      type: "success"
                    });
                    _this12.upcomingVisible = false;
                    _this12.loadingSave = false;
                    _this12.loadingPass = false;
                    _this12.loadingShow = false;
                    _this12.init();
                  }
                });
              } else {
                _this12.loadingPass = false;
                _this12.$message.error(res0.msg);
              }
            });
          } else {
            _this12.loadingSave = false;
            _this12.loadingPass = false;
            _this12.loadingShow = false;
            _this12.$message.error(res.msg);
          }
        });
      }
    }
  }
};
exports.default = _default;