"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'suppleMaterial',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      caseDealing: _dictionary.dictionary.caseDealing,
      fileVisble: false,
      MaterialData: [],
      fileLoading: false,
      num: ''
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;
      (0, _inquiry.supplyMaterialList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.loading = false;
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格点击通过
    handleAdopt: function handleAdopt(val) {
      var _this2 = this;
      this.$confirm('确定要通过审核吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _inquiry.supplyMaterialAdopt)({
          id: val.keyId
        }).then(function (res) {
          if (res.code == 1) {
            _this2.$message({
              message: '通过审核成功',
              type: 'success'
            });
            _this2.init();
          } else {
            _this2.$message.error(res.msg);
          }
        });
      });
    },
    // 表格点击拒绝
    handleReject: function handleReject(val) {
      var _this3 = this;
      this.$confirm('确定要拒绝审核吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _inquiry.supplyMaterialReject)({
          id: val.keyId
        }).then(function (res) {
          if (res.code == 1) {
            _this3.$message({
              message: '拒绝审核成功',
              type: 'success'
            });
            _this3.init();
          } else {
            _this3.$message.error(res.msg);
          }
        });
      });
    },
    // 表格点击查看
    handleClick: function handleClick(val) {
      var _this4 = this;
      this.fileVisble = true;
      this.fileLoading = true;
      (0, _inquiry.supplyMaterialSigleList)({
        id: val.keyId
      }).then(function (res) {
        if (res.code == 1) {
          _this4.fileLoading = false;
          _this4.MaterialData = res.data.caseFiles;
        } else {
          _this4.$message.error(res.msg);
        }
      });
    },
    //点击筛选，发送请求列表
    screen: function screen(data) {
      var _this5 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: this.num,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.supplyMaterialList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this5.tableData = res.rows;
          _this5.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.num = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.pageLimit = val;
      if (this.num) {
        // 有筛选条件的情况下
        (0, _inquiry.supplyMaterialList)({
          caseId: this.num,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.supplyMaterialList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this7 = this;
      if (this.num) {
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        // 有筛选条件的情况下
        (0, _inquiry.supplyMaterialList)({
          caseId: this.num,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.supplyMaterialList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;