"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPartyMaintenance = addPartyMaintenance;
exports.addSendService = addSendService;
exports.addSupplementMaterial = addSupplementMaterial;
exports.addTrialRecord = addTrialRecord;
exports.applyCaseTransfer = applyCaseTransfer;
exports.approvalArbClaimChange = approvalArbClaimChange;
exports.auditProof = auditProof;
exports.batchCreateRule = batchCreateRule;
exports.batchFiling = batchFiling;
exports.batchProcedureByAdopt = batchProcedureByAdopt;
exports.batchSendCompose = batchSendCompose;
exports.batchSendCourt = batchSendCourt;
exports.batchSendRule = batchSendRule;
exports.batchTask = batchTask;
exports.batchUploadRule = batchUploadRule;
exports.caseAccept = caseAccept;
exports.caseCorrectionContentAuit = caseCorrectionContentAuit;
exports.checkEvidentMaterial = checkEvidentMaterial;
exports.choiceArbitrator = choiceArbitrator;
exports.choiceCourtAddress = choiceCourtAddress;
exports.computePrice = computePrice;
exports.confirmCaseMessage = confirmCaseMessage;
exports.creatCourtFile = creatCourtFile;
exports.creatRefuseWithdrawFile = creatRefuseWithdrawFile;
exports.creatWithdrawFile = creatWithdrawFile;
exports.createClaimChangeFile = createClaimChangeFile;
exports.createComposeFile = createComposeFile;
exports.createRegisterFile = createRegisterFile;
exports.createRuleSendFile = createRuleSendFile;
exports.createRuledFile = createRuledFile;
exports.deletePartyFile = deletePartyFile;
exports.downLoadProve = downLoadProve;
exports.downloadOnFiles = downloadOnFiles;
exports.evadingAuditHanding = evadingAuditHanding;
exports.filingPass = filingPass;
exports.filingRefuse = filingRefuse;
exports.filingSupplyProof = filingSupplyProof;
exports.findArbClaimChange = findArbClaimChange;
exports.findArbClaimChangePage = findArbClaimChangePage;
exports.findCaseCourtListByPage = findCaseCourtListByPage;
exports.findCaseReplyInfo = findCaseReplyInfo;
exports.findRevokeCasePage = findRevokeCasePage;
exports.findRevokeInfo = findRevokeInfo;
exports.findTransferCasePage = findTransferCasePage;
exports.findTrialRecord = findTrialRecord;
exports.getArbitratorList = getArbitratorList;
exports.getCaseCompareInfo = getCaseCompareInfo;
exports.getCaseCorrectionContent = getCaseCorrectionContent;
exports.getCaseCorrectionContentNextProcess = getCaseCorrectionContentNextProcess;
exports.getCaseCorrectionContentPage = getCaseCorrectionContentPage;
exports.getCaseExmainRecord = getCaseExmainRecord;
exports.getCaseMessage = getCaseMessage;
exports.getCaseMessageList = getCaseMessageList;
exports.getCaseOnFilesList = getCaseOnFilesList;
exports.getCasePayInfo = getCasePayInfo;
exports.getCasePower = getCasePower;
exports.getCasePowerDetail = getCasePowerDetail;
exports.getCaseReplyListByPage = getCaseReplyListByPage;
exports.getCaseService = getCaseService;
exports.getCasemoveRecord = getCasemoveRecord;
exports.getChoiceArbitrator = getChoiceArbitrator;
exports.getCommAllList = getCommAllList;
exports.getCourtList = getCourtList;
exports.getDealPeopleList = getDealPeopleList;
exports.getDetail = getDetail;
exports.getList = getList;
exports.getMaterial = getMaterial;
exports.getObtainEvidentList = getObtainEvidentList;
exports.getPartyInfo = getPartyInfo;
exports.getPartyMaintenance = getPartyMaintenance;
exports.getPayLogList = getPayLogList;
exports.getProcessList = getProcessList;
exports.getProveList = getProveList;
exports.getReasonList = getReasonList;
exports.getRepeatList = getRepeatList;
exports.getRuleFile = getRuleFile;
exports.getSecretaryList = getSecretaryList;
exports.getevadingAuditDetail = getevadingAuditDetail;
exports.getevadingAuditList = getevadingAuditList;
exports.getplatformPreviewList = getplatformPreviewList;
exports.getsubmitterList = getsubmitterList;
exports.insertCourtInfo = insertCourtInfo;
exports.markRepeatCase = markRepeatCase;
exports.obtainEvidentBatchUpload = obtainEvidentBatchUpload;
exports.obtainEvidentUpload = obtainEvidentUpload;
exports.onBatchSendRegisterPayBook = onBatchSendRegisterPayBook;
exports.onCaseBatchHandleUpdateReview = onCaseBatchHandleUpdateReview;
exports.onCorrectSaveFile = onCorrectSaveFile;
exports.onfindArbitratorByCaseId = onfindArbitratorByCaseId;
exports.partyUploadFiles = partyUploadFiles;
exports.passAudit = passAudit;
exports.procedureByAdopt = procedureByAdopt;
exports.procedureByRefuse = procedureByRefuse;
exports.reCreatePayBook = reCreatePayBook;
exports.rejectionArbClaimChange = rejectionArbClaimChange;
exports.resendCaseEmail = resendCaseEmail;
exports.saveCaseCorrectionContent = saveCaseCorrectionContent;
exports.saveDecisionFile = saveDecisionFile;
exports.saveRuleFile = saveRuleFile;
exports.selectRegisterIsPayInfo = selectRegisterIsPayInfo;
exports.sendClaimChangeFile = sendClaimChangeFile;
exports.sendCompose = sendCompose;
exports.sendCourt = sendCourt;
exports.sendEvident = sendEvident;
exports.sendRefuseWithdraw = sendRefuseWithdraw;
exports.sendRegister = sendRegister;
exports.sendRegisterPayBook = sendRegisterPayBook;
exports.sendRule = sendRule;
exports.sendWithdraw = sendWithdraw;
exports.sendserviceType = sendserviceType;
exports.signArbitrationNoticeFiles = signArbitrationNoticeFiles;
exports.signatureVerify = signatureVerify;
exports.submitRevoke = submitRevoke;
exports.supplyMaterialAdopt = supplyMaterialAdopt;
exports.supplyMaterialList = supplyMaterialList;
exports.supplyMaterialReject = supplyMaterialReject;
exports.supplyMaterialSigleList = supplyMaterialSigleList;
exports.updatePartyMaintenance = updatePartyMaintenance;
exports.uploadRuleFile = uploadRuleFile;
var _request = _interopRequireDefault(require("@/utils/request"));
function onBatchSendRegisterPayBook(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchSendRegisterPayBook',
    method: 'post',
    data: data
  });
}

//查询补正书详情
function getCaseCorrectionContent(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/findByCaseId/' + data,
    method: 'get'
  });
}
//caseBatchHandle/updateReview
function onCaseBatchHandleUpdateReview(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchHandle/updateReview',
    method: 'post',
    data: data
  });
}
;

// caseArbitrator / findArbitratorByCaseId / { caseId }
function onfindArbitratorByCaseId(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitrator/findArbitratorByCaseId/' + data,
    method: 'get'
  });
}
;

//补正书、、caseCorrectionContent/saveFile
function onCorrectSaveFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/saveFile',
    method: 'post',
    data: data
  });
}
;
function getCaseCorrectionContentNextProcess(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/findNextProcess/' + data,
    method: 'get'
  });
}
function caseCorrectionContentAuit(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/audit',
    method: 'post',
    data: data
  });
}
//保存补正书
function saveCaseCorrectionContent(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/save',
    method: 'post',
    data: data
  });
}

//查询补正书分页
function getCaseCorrectionContentPage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/findListByPage',
    method: 'post',
    data: data
  });
}

//送达类型 1 立案送达 2 组庭送达 3 开庭送达 4 裁决送达 5 结案 99.补正书送达
function sendserviceType(data) {
  return (0, _request.default)({
    url: '/batchCase/caseCorrectionContent/send',
    method: 'post',
    data: data
  });
}

// 列表请求  -peng
function getCommAllList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCaseCommAllListByPage',
    method: 'post',
    data: data
  });
}
// 列表请求  -peng
function getList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCaseListByPage',
    method: 'post',
    data: data
  });
}
//案件审核记录
function getCaseExmainRecord(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchAudits/findCaseAuditByPage',
    method: 'post',
    data: data
  });
}
//案件流转记录 -peng
function getCasemoveRecord(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchRecord/findRecordByPage',
    method: 'post',
    data: data
  });
}

//案件送达记录 -peng
function getCaseService(data) {
  return (0, _request.default)({
    url: '/batchCase/caseMessage/findCaseMessageByPage',
    method: 'post',
    data: data
  });
}
// 案件归档  列表
function getCaseOnFilesList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCaseListByPage',
    method: 'post',
    data: data
  });
}
// 案件归档  下载
function downloadOnFiles(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/downloadFileByCase',
    method: 'post',
    data: data
  });
}

//案件管理  获取当事人信息 -peng
function getPartyMaintenance(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/selectCasePartyByCaseId',
    method: 'post',
    data: data
  });
}
//案件管理  新增当事人信息 -peng
function addPartyMaintenance(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/addPartyByCaseId',
    method: 'post',
    data: data
  });
}
//案件管理  修改当事人信息 -peng
function updatePartyMaintenance(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/updatePartyByCaseId',
    method: 'post',
    data: data
  });
}
//案件管理  当事人材料上传接口 -peng
function partyUploadFiles(data) {
  return '/batchCase/fileUpload/uploadFile';
}
//案件管理  当事人材料删除接口 -peng
function deletePartyFile(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/delPartyFileById',
    method: 'post',
    data: data
  });
}
//案件管理   补充材料 提交人列表
function getsubmitterList(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/getCasePartyInfo',
    method: 'post',
    data: data
  });
}
//案件管理   确定补充材料
function addSupplementMaterial(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartymaintain/addCasePartyProof',
    method: 'post',
    data: data
  });
}

//（行使权利）列表页  -peng
function getCasePower(data) {
  return (0, _request.default)({
    url: '/batchCase/caseExercisePower/listCaseExercisePower',
    method: 'post',
    data: data
  });
}

// 行使权利详情 -peng
function getCasePowerDetail(data) {
  return (0, _request.default)({
    url: '/batchCase/caseExercisePower/revocationCaseFile',
    method: 'post',
    data: data
  });
}

//行使权利详情页  通过审核/拒绝按钮  -peng
function passAudit(data) {
  return (0, _request.default)({
    url: '/batchCase/caseExercisePower/checkCaseExercisePower',
    method: 'put',
    data: data
  });
}
// 案件流程审核  获取列表
function getProcessList(data) {
  return (0, _request.default)({
    url: '/batchCase/manager/caseReview/findReviewCasePage',
    method: 'post',
    data: data
  });
}

// 案件流程审核  获取列表
function getCaseReplyListByPage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseReply/findCaseReplyListByPage',
    method: 'post',
    data: data
  });
}
// 案件流程审核  处理人列表
function getDealPeopleList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/selectReviewList',
    method: 'post',
    data: data
  });
}

// 案件流程审核  批量审核
function batchProcedureByAdopt(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchProcessAdopt',
    method: 'post',
    data: data
  });
}

// 案件流程审核  通过审核
function procedureByAdopt(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/processEngineByAdopt',
    method: 'post',
    data: data
  });
}

// 案件流程审核  拒绝
function procedureByRefuse(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/processEngineByRefuse',
    method: 'post',
    data: data
  });
}

// 批量立案送达
function batchFiling(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchSendRegister',
    method: 'post',
    data: data
  });
}

// 批量处理
function batchTask(data) {
  return (0, _request.default)({
    url: '/batchCase/batchTaskRecord/addData',
    method: 'post',
    data: data
  });
}

// 所有案件（案号）点击详情请求   后面直接参数 -peng
function getDetail(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/caseDetail/' + params,
    method: 'get'
  });
}

// 申请人或者被申请人案件材料
function getMaterial(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartyProof/findProofByPage',
    method: 'post',
    data: data
  });
}

// 查询当事人信息
function getPartyInfo(params) {
  return (0, _request.default)({
    url: '/batchCase/caseParty/findCasePartyById/' + params,
    method: 'get'
  });
}

// 审核证据材料
function auditProof(data) {
  return (0, _request.default)({
    url: '/batchCase/casePartyProof/auditProof',
    method: 'post',
    data: data
  });
}

// 秘书列表
// /batchCase/caseBatchInfo/findSecretaryList   ==>先前接口
function getSecretaryList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findSecretaryByProcess',
    method: 'post',
    data: data
  });
}

// 选择办案秘书及提交经办意见
function filingPass(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/choiceHandingSecretary',
    method: 'post',
    data: data
  });
}

// 案件受理-补充材料
function filingSupplyProof(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/supplyProof',
    method: 'post',
    data: data
  });
}

// 案件受理-不予立案
function filingRefuse(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/caseRegisterRefuse',
    method: 'post',
    data: data
  });
}

// 查询所属仲裁委的案由列表
function getReasonList() {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findReasonList',
    method: 'get'
  });
}

// 费用快算
function computePrice(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/computePrice',
    method: 'post',
    data: data
  });
}

// 案件立案受理-更新案由
function caseAccept(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/caseRegisterAccept',
    method: 'post',
    data: data
  });
}

// 生成立案文书
function createRegisterFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createRegisterFile/' + params,
    method: 'get'
  });
}

// 配置送达方式
function addSendService(data) {
  return (0, _request.default)({
    url: '/batchCase/caseServiceMethod/addSendService',
    method: 'post',
    data: data
  });
}

// 立案送达
function sendRegister(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/casesProcessAdopt',
    method: 'post',
    data: data
  });
}

// 查询案件已选择仲裁员
function getChoiceArbitrator(params) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitrator/findArbitratorByCaseId/' + params,
    method: 'get'
  });
}

// 查询所属仲裁委的仲裁员列表
function getArbitratorList(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findArbitratorList',
    method: 'get'
  });
}

// 秘书选择仲裁员
function choiceArbitrator(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitrator/secretaryChoiceArbitrator',
    method: 'post',
    data: data
  });
}

// 生成组庭文书
function createComposeFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createComposeFile/' + params,
    method: 'get'
  });
}

// 组庭送达
function sendCompose(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendCompose/' + params,
    method: 'get'
  });
}

// 批量组庭送达
function batchSendCompose(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchSendCompose',
    method: 'post',
    data: data
  });
}

// 查询所属仲裁委的仲裁庭列表
function getCourtList(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCourtList',
    method: 'get'
  });
}

// 新增开庭地点
function insertCourtInfo(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/insertCourtInfo',
    method: 'post',
    data: data
  });
}

// 设置开庭地点
function choiceCourtAddress(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/choiceCourtAddress',
    method: 'post',
    data: data
  });
}

// 生成开庭文书
function creatCourtFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/creatCourtFile/' + params,
    method: 'get'
  });
}

// 开庭送达
function sendCourt(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendCourt/' + params,
    method: 'get'
  });
}

// 批量开庭送达
function batchSendCourt(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchSendCourt',
    method: 'post',
    data: data
  });
}

// 生成裁决书
function createRuledFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/getRuledFile',
    method: 'post',
    data: data
  });
}

// 查询裁决书
function getRuleFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/findRuleFile/' + params,
    method: 'get'
  });
}

// 上传裁决书
// export function uploadRuleFile(data) {
//   return request({
//     url: '/batchCase/fileUpload/uploadRuleFile',
//     method: 'post',
//     data
//   })
// }

// 上传裁决书-deal api
function uploadRuleFile() {
  return '/batchCase/fileUpload/uploadFile';
}

// 保存裁决书
function saveRuleFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/saveRuleFile',
    method: 'post',
    data: data
  });
}

// 保存撤案决定书
function saveDecisionFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/saveDecisionFile',
    method: 'post',
    data: data
  });
}

// 生成裁决送达
function createRuleSendFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createRuleSendFile/' + params,
    method: 'get'
  });
}

// 裁决送达
function sendRule(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendRule/' + params,
    method: 'get'
  });
}

// 批量审核
function batchSendRule(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchSendRule',
    method: 'post',
    data: data
  });
}

// 批量生成裁决书（批量下载裁决书）
function batchCreateRule(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/batchCreateRule',
    method: 'post',
    data: data
  });
}

// 批量上传裁决书送达
function batchUploadRule(data) {
  return '/batchCase/fileUpload/batchUploadRule';
}

// 分页查询案件送达记录
function getCaseMessageList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseMessage/findCaseMessageByPage',
    method: 'post',
    data: data
  });
}

// 查看送达记录详情
function getCaseMessage(params) {
  return (0, _request.default)({
    url: '/batchCase/caseMessage/findCaseMessageById/' + params,
    method: 'get'
  });
}

// 重新送达
function resendCaseEmail(data) {
  return (0, _request.default)({
    url: '/batchCase/caseResendMsg/resendCaseEmail',
    method: 'post',
    data: data
  });
}

// 手动确认送达成功
function confirmCaseMessage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseMessage/confirmCaseMessage',
    method: 'post',
    data: data
  });
}

// 分页查询重复案件列表
function getRepeatList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findRepeatCasePage',
    method: 'post',
    data: data
  });
}

// 查询重复案件对比信息
function getCaseCompareInfo(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCaseCompareInfo/' + params,
    method: 'get'
  });
}

// 标记重复案件
function markRepeatCase(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/markRepeatCase/' + params,
    method: 'get'
  });
}

// 可申请转移案件列表
function findTransferCasePage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseTransferLog/findTransferCasePage',
    method: 'post',
    data: data
  });
}

// 申请案件转移
function applyCaseTransfer(data) {
  return (0, _request.default)({
    url: '/batchCase/caseTransferLog/applyCaseTransfer',
    method: 'post',
    data: data
  });
}

// 平台预审数据列表
function getplatformPreviewList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/complianceList',
    method: 'post',
    data: data
  });
}

// 回避审核列表
function getevadingAuditList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findAvoidAuditiList',
    method: 'post',
    data: data
  });
}
// 回避审核获取详情
function getevadingAuditDetail(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/avoidMaterial/' + params,
    method: 'get'
  });
}

// 回避审核列表的审核或者拒绝接口
function evadingAuditHanding(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/avoidApplyAuditing',
    method: 'post',
    data: data
  });
}

// 生效证明查询列表
function getProveList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findCaseListByPage',
    method: 'post',
    data: data
  });
}

// 生效证明查询列表
function downLoadProve(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/downCaseEffectiveProof',
    method: 'post',
    data: data
  });
}

//仲裁委取证列表
function getObtainEvidentList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitralFiles/findArbitralFilesCaseListByPage',
    method: 'post',
    data: data
  });
}

// 仲裁委取证确认上传材料
function obtainEvidentUpload(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitralFiles/addFiles',
    method: 'post',
    data: data
  });
}
// 仲裁委取证批量上传材料
function obtainEvidentBatchUpload(data) {
  return (0, _request.default)({
    url: 'batchCase/caseArbitralFiles/addBatchFiles',
    method: 'post',
    data: data
  });
}
//查询某个取证材料
function checkEvidentMaterial(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitralFiles/selectArbitralFilesByCaseId',
    method: 'post',
    data: data
  });
}
//仲裁委取证确认送达
function sendEvident(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitralFiles/sendFiles',
    method: 'post',
    data: data
  });
}
//申请人材料列表
function supplyMaterialList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseAddProof/findAddCaseListByPage',
    method: 'post',
    data: data
  });
}

//申请人材料 通过
function supplyMaterialAdopt(data) {
  return (0, _request.default)({
    url: '/batchCase/caseAddProof/proofAdopt',
    method: 'post',
    data: data
  });
}
//申请人材料 拒绝
function supplyMaterialReject(data) {
  return (0, _request.default)({
    url: '/batchCase/caseAddProof/proofRefuse',
    method: 'post',
    data: data
  });
}

//申请人材料 材料列表
function supplyMaterialSigleList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseAddProof/findAddCaseProof',
    method: 'post',
    data: data
  });
}
//获取缴费费用
function getCasePayInfo(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/getCasePayInfo',
    method: 'post',
    data: data
  });
}

//获取缴费费用
function reCreatePayBook(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/caseRegisterUInfo',
    method: 'post',
    data: data
  });
}

//送达缴费通知
function sendRegisterPayBook(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendRegisterPayBook',
    method: 'post',
    data: data
  });
}
//查询缴费信息
function selectRegisterIsPayInfo(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRegisterPayLog/selectRegisterIsPayInfo',
    method: 'post',
    data: data
  });
}

//仲裁员签名声明书
function signArbitrationNoticeFiles(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/signArbitrationNoticeFiles',
    method: 'post',
    data: data
  });
}
function findCaseCourtListByPage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbitrator/findCaseCourtListByPage',
    method: 'post',
    data: data
  });
}
function findCaseReplyInfo(data) {
  return (0, _request.default)({
    url: '/batchCase/caseReply/findCaseReplyInfo',
    method: 'post',
    data: data
  });
}
// 分页查询待审核的变更仲裁请求列表
function findArbClaimChangePage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbClaimChange/findArbClaimChangePage',
    method: 'post',
    data: data
  });
}

//查询变更仲裁请求详情
function findArbClaimChange(params) {
  return (0, _request.default)({
    url: '/batchCase/caseArbClaimChange/findArbClaimChange/' + params,
    method: 'get'
  });
}

// 通过变更请求（废弃）
function approvalArbClaimChange(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbClaimChange/approvalArbClaimChange',
    method: 'post',
    data: data
  });
}

// 拒绝变更请求（废弃）
function rejectionArbClaimChange(data) {
  return (0, _request.default)({
    url: '/batchCase/caseArbClaimChange/rejectionArbClaimChange',
    method: 'post',
    data: data
  });
}

// 生成变更仲裁请求通知
function createClaimChangeFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createClaimChangeFile',
    method: 'post',
    data: data
  });
}

// 变更仲裁请求通知送达
function sendClaimChangeFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendClaimChangeFile',
    method: 'post',
    data: data
  });
}

// 上传庭审笔录
function addTrialRecord(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/addTrialRecord',
    method: 'post',
    data: data
  });
}

//查询庭审笔录
function findTrialRecord(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/findTrialRecord/' + params,
    method: 'get'
  });
}

//电子签章验证
function signatureVerify(params) {
  return (0, _request.default)({
    url: '/batchCase/casePartyProof/signatureVerify/' + params,
    method: 'get'
  });
}

// 查询撤案列表
function findRevokeCasePage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/findRevokeCasePage',
    method: 'post',
    data: data
  });
}

//查询撤案信息
function findRevokeInfo(params) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/findRevokeInfo/' + params,
    method: 'get'
  });
}

// 仲裁委提交撤案
function submitRevoke(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/submitRevoke',
    method: 'post',
    data: data
  });
}

//生成拒绝撤案文书
function creatRefuseWithdrawFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/creatRefuseWithdrawFile/' + params,
    method: 'get'
  });
}

// 拒绝撤案送达
function sendRefuseWithdraw(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendRefuseWithdraw',
    method: 'post',
    data: data
  });
}

//生成撤案文书
function creatWithdrawFile(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/creatWithdrawFile/' + params,
    method: 'get'
  });
}

// 撤案送达
function sendWithdraw(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/sendWithdraw',
    method: 'post',
    data: data
  });
}
// 列表请求  -peng
function getPayLogList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRegisterPayLog/findPayCaseListByPage',
    method: 'post',
    data: data
  });
}