var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "case-detail" },
        [
          _c("el-col", { staticClass: "detail", attrs: { span: 20 } }, [
            _c("div", { staticClass: "anchor", attrs: { id: "caseStatus" } }, [
              _c("span", { staticClass: "case-detail-state" }, [
                _vm._v(_vm._s(_vm.caseState[_vm.detailData.state])),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseAbout" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("案件概况")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案件受理号：" + _vm._s(_vm.detailData.caseId)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("提交时间：" + _vm._s(_vm.detailData.ctime)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案由：" + _vm._s(_vm.detailData.reason)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案件来源：" + _vm._s(_vm.detailData.source)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "办案秘书：" +
                            _vm._s(_vm.detailData.handlingSecretaryName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "仲裁费：" + _vm._s(_vm.detailData.arbitrationAmount)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("案号：" + _vm._s(_vm.detailData.caseNo)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "立案时间：" + _vm._s(_vm.detailData.registerTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "适用程序：" +
                            _vm._s(_vm.detailData.applicableProcedure)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "受理秘书：" +
                            _vm._s(_vm.detailData.acceptSecretaryName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "标的额：" + _vm._s(_vm.detailData.controversyAmount)
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "casePerson" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("当事人信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("申请人：\n                     "),
                          _vm.detailData.appPartyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.appPartyList,
                                function (app) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(app.partyId)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(app.name))]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("申请人代理人：\n                   "),
                          _vm.detailData.appProxyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.appProxyList,
                                function (appProxy) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(
                                            appProxy.partyId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(appProxy.name))]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("被申请人：\n                     "),
                          _vm.detailData.resPartyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.resPartyList,
                                function (res) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(res.partyId)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(res.name))]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("被申请人代理人：\n                     "),
                          _vm.detailData.resProxyList == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _vm._l(
                                _vm.detailData.resProxyList,
                                function (resProxy) {
                                  return _c(
                                    "el-link",
                                    {
                                      staticClass: "margin-right20",
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPartyInfo(
                                            resProxy.partyId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(resProxy.name))]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseItem" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁事项")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("仲裁请求：\n                    "),
                          _c("span", { staticClass: "text-ellipsis" }, [
                            _vm._v(_vm._s(_vm.detailData.arbitrationRequest)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detailData.arbitrationRequest)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.arbitrationRequestFlag,
                                      expression: "arbitrationRequestFlag",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                },
                                [_vm._v("显示全部")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("事实和理由：\n                    "),
                          _c("span", { staticClass: "text-ellipsis" }, [
                            _vm._v(_vm._s(_vm.detailData.arbitrationAruth)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detailData.arbitrationAruth)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.arbitrationAruthFlag,
                                      expression: "arbitrationAruthFlag",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                },
                                [_vm._v("显示全部")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseMaterial" } },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._v("申请人案件材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.MaterialData1 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "submitUserName",
                                label: "提交人",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileType", label: "材料类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(
                                            _vm.fileType[scope.row.fileType]
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileName", label: "名称" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                            href: scope.row.aliyunOssHref,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.fileName))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "state", label: "审核状态" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(
                                            _vm.materialState[scope.row.state]
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "utime", label: "审核时间" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "page-content" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "current-page": _vm.MaterialCurrentPage1,
                                "page-sizes": [5],
                                "page-size": 5,
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.MaterialTotal1,
                              },
                              on: {
                                "size-change": _vm.handleMaterialSizeChange1,
                                "current-change":
                                  _vm.handleMaterialCurrentChange1,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "header" }, [
                  _vm._v("被申请人案件材料"),
                ]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.MaterialData2 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "submitUserName",
                                label: "提交人",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileType", label: "材料类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.fileType[scope.row.fileType]
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "fileName", label: "名称" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                            href: scope.row.aliyunOssHref,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.fileName))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "state", label: "审核状态" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.materialState[scope.row.state]
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "utime", label: "审核时间" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "page-content" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "current-page": _vm.MaterialCurrentPage2,
                                "page-sizes": [5],
                                "page-size": 5,
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.MaterialTotal2,
                              },
                              on: {
                                "size-change": _vm.handleMaterialSizeChange2,
                                "current-change":
                                  _vm.handleMaterialCurrentChange2,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "holdInformation" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁庭信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "首席仲裁员：" + _vm._s(_vm.courtInfo.arbitratorName)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("开庭日期：" + _vm._s(_vm.courtInfo.courtTime)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("审理方式：" + _vm._s(_vm.courtInfo.trialWay)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "组庭时间：" + _vm._s(_vm.courtInfo.composeTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v(
                          "开庭地点：" + _vm._s(_vm.courtInfo.courtAddress)
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "resInformation" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("答辩信息")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "case-txt" }, [
                        _vm._v("答辩状：\n                       "),
                        _vm.detailData.caseReply.content == ""
                          ? _c("span", [_vm._v("暂无")])
                          : _c("div", { staticClass: "caseItem-txt" }, [
                              _vm._v(_vm._s(_vm.detailData.caseReply.content)),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "case-txt" },
                        [
                          _vm._v("答辩材料：\n                       "),
                          _vm.detailData.caseReply.replyName == ""
                            ? _c("span", [_vm._v("暂无")])
                            : _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: _vm.detailData.caseReply
                                      .aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.detailData.caseReply.replyName)
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "arbitrDoc" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("仲裁文书")]),
                _vm._v(" "),
                _c("el-main", [
                  _c(
                    "div",
                    {
                      staticClass: "application-doc",
                      staticStyle: {
                        "border-bottom": "1px dotted gray",
                        "padding-bottom": "10px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                     申请人文书：\n                     "
                      ),
                      _vm.detailData.appFileInstrumentList == ""
                        ? _c("span", [_vm._v("暂无")])
                        : _vm._l(
                            _vm.detailData.appFileInstrumentList,
                            function (item) {
                              return _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: item.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.fileName))]
                              )
                            }
                          ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "respontent-doc" },
                    [
                      _vm._v(
                        "\n                     被申请人文书：\n                     "
                      ),
                      _vm.detailData.resFileInstrumentList == ""
                        ? _c("span", [_vm._v("暂无")])
                        : _vm._l(
                            _vm.detailData.resFileInstrumentList,
                            function (item) {
                              return _c(
                                "el-link",
                                {
                                  staticClass: "margin-right20",
                                  attrs: {
                                    type: "primary",
                                    underline: false,
                                    href: item.aliyunOssHref,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.fileName))]
                              )
                            }
                          ),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "colseDoc" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("结案文书")]),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    _vm.detailData.endCaseDocument.fileName == ""
                      ? _c("span", [_vm._v("暂无")])
                      : _c(
                          "el-link",
                          {
                            staticClass: "margin-right20",
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: _vm.detailData.endCaseDocument
                                .aliyunOssHref,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.detailData.endCaseDocument.fileName)
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "ExerciseRight" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("行使权利")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.rightTableData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "registerTime", label: "提交时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "appName", label: "提交人" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "procedureType", label: "程序类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                             " +
                                  _vm._s(
                                    _vm.caseExamineType[scope.row.procedureType]
                                  ) +
                                  "\n                           "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "caseState", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.checkState == 0
                                ? _c("span", [_vm._v("未审核")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.checkState == 1
                                ? _c("span", [_vm._v("通过")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.checkState == -1
                                ? _c("span", [_vm._v("拒绝")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.RightCurrentPage,
                        "page-sizes": [5],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.RightTotal,
                      },
                      on: {
                        "size-change": _vm.handleRightSizeChange,
                        "current-change": _vm.handleRightCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseMove" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("案件流转记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.caseMoveData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "recordName", label: "流程阶段" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(
                                    _vm.caseState[scope.row.currentCaseState]
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "recordName", label: "事项" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "remark", label: "备注" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "errorReason", label: "理由" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "recordTime", label: "流转时间" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.MoveCurrentPage,
                        "page-sizes": [5],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.MoveTotal,
                      },
                      on: {
                        "size-change": _vm.handleMoveSizeChange,
                        "current-change": _vm.handleMoveCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseAudting" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("审核记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.auditRecordData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "registerTime", label: "审核阶段" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "appName", label: "审核人" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "procedureName", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                             " +
                                  _vm._s(_vm.caseState[scope.row.caseState]) +
                                  "\n                           "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "备注" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "经办意见" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "审核时间" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.AudtingCurrentPage,
                        "page-sizes": [5],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.AudtingTotal,
                      },
                      on: {
                        "size-change": _vm.handleAudtingSizeChange,
                        "current-change": _vm.handleAudtingCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchor", attrs: { id: "caseService" } },
              [
                _c("div", { staticClass: "header" }, [_vm._v("送达记录")]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.caseServiceData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "deliveryAddress", label: "送达地址" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "type", label: "类型" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                             " +
                                  _vm._s(_vm.sendMsgType[scope.row.type]) +
                                  "\n                           "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "state", label: "状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.state == 0
                                ? _c("span", [_vm._v("第一次发送失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 1
                                ? _c("span", [_vm._v("第二次发送失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 2
                                ? _c("span", [_vm._v("通知成功")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 3
                                ? _c("span", [_vm._v("通知失败")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 4
                                ? _c("span", [_vm._v("发送成功")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.state == 5
                                ? _c("span", [_vm._v("发送中")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "ctime", label: "发送时间" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "fromAddress", label: "发送地址" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "errMsg", label: "失败详情" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-content" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.ServiceCurrentPage,
                        "page-sizes": [5],
                        "page-size": 5,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.ServiceTotal,
                      },
                      on: {
                        "size-change": _vm.handleServiceSizeChange,
                        "current-change": _vm.handleServiceCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "detail-btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { staticClass: "dash", attrs: { span: 4 } }, [
            _c("ul", { staticClass: "anchor-link" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseStatus" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseStatus")
                      },
                    },
                  },
                  [_vm._v("案件状态")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseAbout" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseAbout")
                      },
                    },
                  },
                  [_vm._v("案件概况")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "casePerson" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("casePerson")
                      },
                    },
                  },
                  [_vm._v("当事人信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseItem" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseItem")
                      },
                    },
                  },
                  [_vm._v("仲裁事项")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseMaterial" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseMaterial")
                      },
                    },
                  },
                  [_vm._v("案件材料")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "holdInformation" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("holdInformation")
                      },
                    },
                  },
                  [_vm._v("仲裁庭信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "resInformation" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("resInformation")
                      },
                    },
                  },
                  [_vm._v("答辩信息")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "arbitrDoc" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("arbitrDoc")
                      },
                    },
                  },
                  [_vm._v("仲裁文书")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "colseDoc" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("colseDoc")
                      },
                    },
                  },
                  [_vm._v("结案文书")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "ExerciseRight" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("ExerciseRight")
                      },
                    },
                  },
                  [_vm._v("行使权力")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseMove" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseMove")
                      },
                    },
                  },
                  [_vm._v("案件流转记录")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseAudting" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseAudting")
                      },
                    },
                  },
                  [_vm._v("审核记录")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { bor: _vm.clicked == "caseService" },
                    on: {
                      click: function ($event) {
                        return _vm.anchorTo("caseService")
                      },
                    },
                  },
                  [_vm._v("送达记录")]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.partyInfoVisible ? _c("party-info", { ref: "partyInfo" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }