var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "process global-page-base-layout" },
    [
      _c(
        "div",
        { staticClass: "add-process" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.addProcess },
            },
            [_vm._v("添加流程")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n                   仲裁委：\n                   "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "全部" },
                      model: {
                        value: _vm.committeeValue,
                        callback: function ($$v) {
                          _vm.committeeValue = $$v
                        },
                        expression: "committeeValue",
                      },
                    },
                    _vm._l(_vm.committeeArr, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n                状  态：\n                "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择状态" },
                      model: {
                        value: _vm.statevalue,
                        callback: function ($$v) {
                          _vm.statevalue = $$v
                        },
                        expression: "statevalue",
                      },
                    },
                    _vm._l(_vm.stateArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "clear-all", on: { click: _vm.clearAllConditon } },
              [_vm._v("清空筛选条件")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "process-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "commName", label: "仲裁委" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "工作流名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "content", label: "说明" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(
                              scope.row.status == 1 ? "已启用" : "已禁用"
                            ) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "utime", label: "最后修改时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "变更历史" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.check(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "work-flow",
          attrs: {
            title: "工作流变更历史",
            visible: _vm.workflowHistoryVisible,
            top: "5vh",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.workflowHistoryVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.gridData } },
            [
              _c("el-table-column", {
                attrs: { label: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.gridPage > 1
                                ? scope.$index + (_vm.gridPage - 1) * 10
                                : scope.$index + 1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "ctime", label: "变更时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "cname", label: "变更人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "type", label: "变更项" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "content", label: "变更内容" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.gridPage,
                  "page-sizes": [10],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.gridTotal,
                },
                on: {
                  "size-change": _vm.handleGridSizeChange,
                  "current-change": _vm.handleGridCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }