"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _router = _interopRequireDefault(require("@/router"));
var _security = require("@/utils/security");
var _encryptKey = require("@/config/encryptKey");
var CryptoJS = require("crypto-js");

// create an axios instance
var service = _axios.default.create({
  // baseURL:"https://devapi.aihuz.cn",
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url    （提交时改这个）
  // baseURL: '/api',
  withCredentials: true,
  // send cookies when cross-domain requests(跨域请求时发送cookie)
  timeout: 60000,
  // request timeout
  headers: {
    post: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_auth.getToken) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["Authorization"] = (0, _auth.getToken)();
  }
  console.log("原始数据:", config.data);
  console.log("接口地址：", config.url);
  //AES对请求数据加密处理

  config.data = {
    request: (0, _security.encryptByAES)(JSON.stringify(config.data), _encryptKey.encryptKey.key)
  };
  config.params = {
    request: (0, _security.encryptByAES)(JSON.stringify(config.params), _encryptKey.encryptKey.key)
  };
  // console.log("加密之后data:", config.data);
  // console.log("加密之后params:", config.params);
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  // AES数据解密处理
  if (response.data.response) {
    var resp = (0, _security.decryptByAES)(response.data.response, _encryptKey.encryptKey.key);
    response.data = JSON.parse(resp);
    // debugger;
  }

  var res = response.data;
  console.log(res);
  if (res.code == "10002" || res.code == "10001" || res.code == "10000") {
    // debugger;

    (0, _elementUi.Message)({
      message: res.msg + "请重新登录",
      type: "error",
      duration: 2 * 1000
    });
    // re-login
    _store.default.dispatch("user/resetToken").then(function () {
      location.reload();
    });
  }
  return res;
}, function (error, res) {
  (0, _elementUi.Message)({
    message: error.message,
    type: "error",
    duration: 2 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;