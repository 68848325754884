exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".trial .add-btn{margin:10px}.trial .content{margin-top:40px}.trial .select-conditions{width:100%;padding:20px;background:#fff}.trial .select-conditions-row{width:100%;height:40px;margin:10px 0}.trial .clear-all{margin-left:40px;font-size:14px;cursor:pointer}.trial .trial-dialog{height:850px;overflow-y:scroll}.trial .trial-dialog .trial-dialog-item{width:100%}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px",
	"bgColor": "#F2F3F5",
	"fontColor": "#409EFF",
	"searchBg": "#fff"
};