var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "global-page-base-layout",
    },
    [
      _c("Caseconditons", {
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-all-btn" },
        [
          _c("el-button", { on: { click: _vm.handleAutoAllot } }, [
            _vm._v("自动分配秘书"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.handleBatchAllot },
            },
            [_vm._v("手动分配秘书")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分配秘书",
            visible: _vm.selectSecVisble,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectSecVisble = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "selectSecform",
              attrs: {
                model: _vm.selectSecform,
                rules: _vm.selectSecRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "秘书", prop: "secretaryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "70%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.selectSecform.secretaryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectSecform, "secretaryId", $$v)
                        },
                        expression: "selectSecform.secretaryId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectSecVisble = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSelectSecSubmit("selectSecform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }