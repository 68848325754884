var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-settlement" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-tip" }, [
          _vm._v("从起始日 00:00:00 至截止日 23:59:59，所有新增应付报酬"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-content bg-purple header-date" },
          [
            _vm._v("\n        起止日期：\n        "),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                format: "yyyy 年 MM 月 dd 日",
                "value-format": "yyyy-MM-dd",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              on: {
                change: function ($event) {
                  return _vm.dateChange(_vm.cycleVal)
                },
              },
              model: {
                value: _vm.cycleVal,
                callback: function ($$v) {
                  _vm.cycleVal = $$v
                },
                expression: "cycleVal",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-content bg-purple header-date" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.staffListShow } },
              [_vm._v("选择发放对象")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-content bg-purple header-date" },
          [
            _vm._v("\n        已选择的发放对象：\n        "),
            _vm._v(" "),
            _vm._l(_vm.selectPeople, function (item, index) {
              return _vm.selectPeople.length > 0
                ? _c(
                    "el-tag",
                    {
                      key: index,
                      attrs: { closable: "", "disable-transitions": false },
                      on: {
                        close: function ($event) {
                          return _vm.handleClose(item, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.selectPeople.length == 0
              ? _c("el-tag", [_vm._v("空")])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "content-tip" }, [
            _vm._v("共 3000 个案件，合计金额 300000 元"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
              on: { "expand-change": _vm.rowExpand },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: props.row.tableData1 },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "案号",
                                width: "200",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "结案时间",
                                width: "200",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "报酬类型",
                                width: "300",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "报酬金额(元)",
                                width: "300",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发放对象", prop: "name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "报酬周期", prop: "time" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "报酬总金额(元)", prop: "money" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  _vm.noteMakingVisible = true
                },
              },
            },
            [_vm._v("备注")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "btn", attrs: { size: "medium", type: "primary" } },
            [_vm._v("申请对账")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "制单备注", visible: _vm.noteMakingVisible },
          on: {
            "update:visible": function ($event) {
              _vm.noteMakingVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请写入备注",
              resize: "none",
              rows: "5",
            },
            model: {
              value: _vm.noteMakeVal,
              callback: function ($$v) {
                _vm.noteMakeVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "noteMakeVal",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "选择发放对象", visible: _vm.staffListVisible },
          on: {
            "update:visible": function ($event) {
              _vm.staffListVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData1.filter(function (data) {
                  return data.department.includes(_vm.aa)
                }),
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "姓名", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "职位", prop: "duty" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "部门", prop: "department" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系方式", prop: "duty" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", size: "medium" },
                            model: {
                              value: _vm.aa,
                              callback: function ($$v) {
                                _vm.aa = $$v
                              },
                              expression: "aa",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.staffListVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.chooseStaffConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }