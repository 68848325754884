"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var batch = {
  adut: function adut() {}
};
var _default = batch;
exports.default = _default;