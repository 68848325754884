var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("el-button", { attrs: { type: "success", plain: "" } }, [
            _vm._v("待对账"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "basic-message" },
        [
          _c(
            "el-main",
            [
              _c("div", { staticClass: "basic-message-title" }, [
                _vm._v("基本信息"),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单人：张科")]),
                _vm._v(" "),
                _c("div", [_vm._v("制单备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("对账备注：")]),
                _vm._v(" "),
                _c("div", [_vm._v("收款备注：")]),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "message", attrs: { span: 12 } }, [
                _c("div", [_vm._v("结算单周期：2019-09-01 至 2019-09-06")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "summary" },
        [
          _c("div", { staticClass: "summary-title" }, [_vm._v("结算汇总")]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "summary-tip" }, [
            _vm._v("共 3000 个案件，合计金额 300000 元"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: "rgba(242, 243, 245, 1)" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "link-style" }, [
                          _vm._v(_vm._s(scope.row.reference)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "结案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "标的额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "application", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "respondent", label: "鉴定费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "operate", label: "结算总金额(元)" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "detail-settle-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: 4,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("el-button", { staticClass: "btn", attrs: { size: "medium" } }, [
            _vm._v("作废"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.reconciliationVisible = true
                },
              },
            },
            [_vm._v("对账完成")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.receivablesVisible = true
                },
              },
            },
            [_vm._v("收款确认")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "对账确认", visible: _vm.reconciliationVisible },
          on: {
            "update:visible": function ($event) {
              _vm.reconciliationVisible = $event
            },
          },
        },
        [
          _c("div", [_vm._v("确认账单信息无误吗？")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.reconciliationVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.reconciliationVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "收款确认", visible: _vm.receivablesVisible },
          on: {
            "update:visible": function ($event) {
              _vm.receivablesVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收款账户",
                    "label-width": _vm.formLabelWidth,
                    rules: [{ required: true, message: "账户不能为空" }],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入收款账户",
                    },
                    model: {
                      value: _vm.form.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "account", $$v)
                      },
                      expression: "form.account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收款确认",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      rows: "4",
                      autocomplete: "off",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.form.tip,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tip", $$v)
                      },
                      expression: "form.tip",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receivablesVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.receivablesVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }