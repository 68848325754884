var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { padding: "0 50px 0 0px" } }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detectStep == 0,
              expression: "detectStep == 0",
            },
          ],
        },
        [
          _c("h1", { staticClass: "center" }, [_vm._v("设备连接")]),
          _vm._v(" "),
          _c("div", { staticClass: "center connect-desc" }, [
            _vm._v(
              "\n        设备检测前请务必给当前页面开放摄像头，麦克风权限哦~\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "center device" }, [
            _c("div", { staticClass: "device-icon" }, [
              _c("span", {
                staticClass: "iconify-inline",
                staticStyle: { color: "#000" },
                attrs: { "data-icon": "icon-park-outline:monitor-camera" },
              }),
              _vm._v(" "),
              _vm.hasPrepareFinish && _vm.hasCameraConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#67C23A" },
                      attrs: { "data-icon": "icon-park-outline:check-small" },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPrepareFinish && !_vm.hasCameraConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#F56C6C" },
                      attrs: { "data-icon": "icon-park-outline:close-small" },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "device-icon" }, [
              _c("span", {
                staticClass: "iconify-inline",
                staticStyle: { color: "#000" },
                attrs: { "data-icon": "icon-park-outline:headphone-sound" },
              }),
              _vm._v(" "),
              _vm.hasPrepareFinish && _vm.hasVoiceConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#67C23A" },
                      attrs: { "data-icon": "icon-park-outline:check-small" },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPrepareFinish && !_vm.hasVoiceConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#F56C6C" },
                      attrs: { "data-icon": "icon-park-outline:close-small" },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "device-icon" }, [
              _c("span", {
                staticClass: "iconify-inline",
                staticStyle: { color: "#000" },
                attrs: { "data-icon": "icon-park-outline:microphone" },
              }),
              _vm._v(" "),
              _vm.hasPrepareFinish && _vm.hasMicConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#67C23A" },
                      attrs: { "data-icon": "icon-park-outline:check-small" },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPrepareFinish && !_vm.hasMicConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#F56C6C" },
                      attrs: { "data-icon": "icon-park-outline:close-small" },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "device-icon" }, [
              _c("span", {
                staticClass: "iconify-inline",
                staticStyle: { color: "#000" },
                attrs: {
                  "data-icon": "icon-park-outline:database-network-point",
                },
              }),
              _vm._v(" "),
              _vm.hasPrepareFinish && _vm.hasNetworkConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#67C23A" },
                      attrs: { "data-icon": "icon-park-outline:check-small" },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPrepareFinish && !_vm.hasNetworkConnect
                ? _c("div", [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { color: "#F56C6C" },
                      attrs: { "data-icon": "icon-park-outline:close-small" },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasPrepareLoading,
                  expression: "hasPrepareLoading",
                },
              ],
              staticClass: "center device-progress",
            },
            [
              _c("el-progress", {
                attrs: {
                  "show-text": false,
                  percentage: _vm.preparePercentage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "center connect-info",
              style: "color:" + _vm.prepareConnectColor,
            },
            [_vm._v("\n        " + _vm._s(_vm.prepareConnectInfo) + "\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "center " },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlePrepareStart },
                },
                [_vm._v(_vm._s(_vm.prepareConnectBtn))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detectStep > 0,
              expression: "detectStep > 0",
            },
          ],
          staticClass: "step-body",
        },
        [
          _c(
            "div",
            { staticStyle: { height: "60px" } },
            [
              _c(
                "el-steps",
                { attrs: { active: _vm.detectStep } },
                [
                  _c("el-step", { attrs: { icon: "el-icon-upload" } }, [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { "font-size": "24px" },
                      attrs: {
                        slot: "icon",
                        "data-icon": "icon-park-outline:monitor-camera",
                      },
                      slot: "icon",
                    }),
                  ]),
                  _vm._v(" "),
                  _c("el-step", { attrs: { icon: "el-icon-upload" } }, [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { "font-size": "24px" },
                      attrs: {
                        slot: "icon",
                        "data-icon": "icon-park-outline:headphone-sound",
                      },
                      slot: "icon",
                    }),
                  ]),
                  _vm._v(" "),
                  _c("el-step", { attrs: { icon: "el-icon-upload" } }, [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { "font-size": "24px" },
                      attrs: {
                        slot: "icon",
                        "data-icon": "icon-park-outline:microphone",
                      },
                      slot: "icon",
                    }),
                  ]),
                  _vm._v(" "),
                  _c("el-step", { attrs: { icon: "el-icon-upload" } }, [
                    _c("span", {
                      staticClass: "iconify-inline",
                      staticStyle: { "font-size": "24px" },
                      attrs: {
                        slot: "icon",
                        "data-icon": "icon-park-outline:database-network-point",
                      },
                      slot: "icon",
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detectStep == 1,
                    expression: "detectStep == 1",
                  },
                ],
                staticClass: "center ",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: {
                          width: "300px",
                          display: "inline-block",
                        },
                        attrs: { "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "摄像头选择" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.cameraDeviceOptionValue,
                                  callback: function ($$v) {
                                    _vm.cameraDeviceOptionValue = $$v
                                  },
                                  expression: "cameraDeviceOptionValue",
                                },
                              },
                              _vm._l(_vm.cameraDeviceOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticStyle: {
                    width: "200px",
                    height: "200px",
                    display: "inline-block",
                  },
                  attrs: { id: "camera-video" },
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _vm._v("是否可以清楚的看到自己？"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.handleCameraBtnNo },
                      },
                      [_vm._v("看不到")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.handleCameraBtnYes },
                      },
                      [_vm._v("可以看到")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detectStep == 2,
                    expression: "detectStep == 2",
                  },
                ],
                staticClass: "center",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: {
                          width: "300px",
                          display: "inline-block",
                        },
                        attrs: { "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "扬声器选择" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.voiceDeviceOptionValue,
                                  callback: function ($$v) {
                                    _vm.voiceDeviceOptionValue = $$v
                                  },
                                  expression: "voiceDeviceOptionValue",
                                },
                              },
                              _vm._l(_vm.voiceDeviceOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "10px" } }, [
                  _vm._v(
                    "\n            请调高设备音量, 点击播放下面的音频试试～\n          "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _vm._v("是否可以听到声音？"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.handleVoiceBtnNo },
                      },
                      [_vm._v("听不到")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.handleVoiceBtnYes },
                      },
                      [_vm._v("可以听到")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detectStep == 3,
                    expression: "detectStep == 3",
                  },
                ],
                staticClass: "center",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: {
                          width: "300px",
                          display: "inline-block",
                        },
                        attrs: { "label-width": "90px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "麦克风选择" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.micDeviceOptionValue,
                                  callback: function ($$v) {
                                    _vm.micDeviceOptionValue = $$v
                                  },
                                  expression: "micDeviceOptionValue",
                                },
                              },
                              _vm._l(_vm.micDeviceOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "10px" } }, [
                  _vm._v("\n            对着麦克风说'哈喽'试试～\n          "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "400px",
                      height: "200px",
                      display: "inline-block",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mic-bar-container" },
                      [
                        _vm._l(28, function (i) {
                          return [
                            i < _vm.micVolumeNum
                              ? _c("div", { staticClass: "mic-bar active" })
                              : _c("div", { staticClass: "mic-bar " }),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: { height: "0px" },
                      attrs: { id: "audio-container" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _vm._v("是否可以看到音量图标跳动？"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.handleMicBtnNo },
                      },
                      [_vm._v("看不到")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.handleMicBtnYes },
                      },
                      [_vm._v("可以看到")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.detectStep == 4,
                    expression: "detectStep == 4",
                  },
                ],
              },
              [
                _vm._v("\n          操作系统"),
                _c("br"),
                _vm._v("\n          浏览器版本"),
                _c("br"),
                _vm._v("\n          屏幕共享能力"),
                _c("br"),
                _vm._v("\n          网络延迟"),
                _c("br"),
                _vm._v("\n          上行网络质量"),
                _c("br"),
                _vm._v("\n          下行网络质量"),
                _c("br"),
                _vm._v("\n          检测剩余时间"),
                _c("br"),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "footer",
      attrs: { slot: "footer" },
      slot: "footer",
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "300px",
          height: "200px",
          display: "inline-block",
          "padding-top": "20px",
        },
      },
      [
        _c("audio", {
          attrs: {
            id: "audio-player",
            src: "https://web.sdk.qcloud.com/trtc/webrtc/assets/bgm-test.mp3",
            controls: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }