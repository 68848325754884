"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportCaseListByPage = exportCaseListByPage;
var _request = _interopRequireDefault(require("@/utils/request"));
function exportCaseListByPage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/exportCaseListByPage',
    method: 'post',
    data: data
  });
}
;