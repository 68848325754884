"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/defineProperty.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _ref;
    return _ref = {
      loading: false,
      enterAccountDate: '',
      //入账时间
      accountName: '',
      //账户名称
      accountType: '',
      //账户类型
      payType: '',
      //支付方式
      input: '',
      //单号
      // 案件搜索
      searchArr: [{
        value: '当事人',
        label: '当事人'
      }, {
        value: '案号',
        label: '案号'
      }, {
        value: '受理号',
        label: '受理号'
      }],
      caseOrigin: [{
        value: '互仲链',
        label: '互仲链'
      }]
    }, (0, _defineProperty2.default)(_ref, "input", ''), (0, _defineProperty2.default)(_ref, "searchvalue", '当事人'), (0, _defineProperty2.default)(_ref, "caseOriginvalue", ''), (0, _defineProperty2.default)(_ref, "date", ''), (0, _defineProperty2.default)(_ref, "tableData", [{
      aa1: '仲裁退费',
      aa2: '2019-08-28 00:00:00',
      aa3: 'AJ20190903180541LS20190903180541',
      aa4: '预缴费用',
      aa5: '收入',
      aa6: '林某某',
      aa7: '现金',
      aa8: '100',
      aa9: '1000'
    }]), (0, _defineProperty2.default)(_ref, "chooseCase", '案号00205'), (0, _defineProperty2.default)(_ref, "caseArr", [{
      type: '标的额',
      num: 465654
    }, {
      type: '受理费',
      num: 456654
    }, {
      type: '处理费',
      num: 45
    }, {
      type: '仲裁费',
      num: 2250
    }]), (0, _defineProperty2.default)(_ref, "feeDetailVisible", false), _ref;
  },
  methods: {
    // 筛选按钮
    screen: function screen() {},
    //点击清空所有的筛选条件
    clear: function clear() {
      this.input = "";
      this.searchvalue = '当事人';
      this.date = "";
      this.caseOriginvalue = '';
    },
    // 表格 按钮
    handleClick: function handleClick() {
      this.feeDetailVisible = true;
    },
    dateChange: function dateChange() {}
  }
};
exports.default = _default;