var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-upload", {
        staticClass: "avatar-uploader",
        attrs: {
          action: _vm.serverUrl,
          name: "img",
          headers: _vm.header,
          "show-file-list": false,
          "on-success": _vm.uploadSuccess,
          "on-error": _vm.uploadError,
          "before-upload": _vm.beforeUpload,
        },
      }),
      _vm._v(" "),
      _c("quill-editor", {
        ref: "myQuillEditor",
        staticClass: "editor",
        attrs: { options: _vm.editorOption },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }