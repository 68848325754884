var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handing global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: {
          serviceStatusConditon: true,
          serviceWayCondition: true,
          serviceTypeCondition: true,
          caseReplyCondition: false,
          caseTypeCondition: false,
          caseOriginCondition: false,
        },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: {
                "sort-change": _vm.handleSort,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "送达编号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", sortable: "", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "fromAddress", label: "发件方地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "deliveryAddress", label: "收件方地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "partyType", label: "当事人类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.partyIdType[scope.row.partyType]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "送达方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.listSendType[scope.row.type]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "serviceType", label: "案件送达状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.serviceType[scope.row.serviceType]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "送达状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.sendMsgState[scope.row.state]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "receiptState", label: "回执状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.receiptState[scope.row.receiptState]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "errMsg", label: "错误信息" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ctime", sortable: "", label: "发送时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleResend(scope.row)
                              },
                            },
                          },
                          [_vm._v("重新送达\n          ")]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        scope.row.state == "0"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleConfirm(scope.row)
                                  },
                                },
                              },
                              [_vm._v("确认送达\n          ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheck(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看\n          ")]
                            ),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "send-again",
              attrs: {
                title: _vm.title,
                visible: _vm.serviceLogVisible,
                "close-on-click-modal": false,
                top: "2vh",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.serviceLogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _vm.sendDetailData.state == 0
                    ? _c("el-alert", {
                        staticClass: "case-error",
                        attrs: {
                          title: _vm.sendDetailData.errMsg,
                          type: "error",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-main",
                    { staticClass: "header-main" },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("当事人类型："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.partyIdType[_vm.sendDetailData.partyType]
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("送达方式："),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.listSendType[_vm.sendDetailData.type])
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "case-txt" },
                          [
                            _vm._v("\n              送达地址："),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.addressEditVal
                                    ? _vm.addressEditVal
                                    : _vm.sendDetailData.deliveryAddress
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: { placement: "top", width: "200" },
                                model: {
                                  value: _vm.popoverVisible,
                                  callback: function ($$v) {
                                    _vm.popoverVisible = $$v
                                  },
                                  expression: "popoverVisible",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { placeholder: "请输入地址" },
                                  model: {
                                    value: _vm.addressEditVal,
                                    callback: function ($$v) {
                                      _vm.addressEditVal = $$v
                                    },
                                    expression: "addressEditVal",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: { click: _vm.cancelAddressEdit },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.confiremAddressEdit },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.title == "重新送达",
                                        expression: "title == '重新送达' ",
                                      },
                                    ],
                                    staticClass: "link-style",
                                    attrs: { slot: "reference", size: "mini" },
                                    slot: "reference",
                                  },
                                  [_vm._v("更改")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("送达状态："),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.sendMsgState[_vm.sendDetailData.state])
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("案件状态："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.serviceType[_vm.sendDetailData.serviceType]
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("案号："),
                          _c("span", { staticClass: "link-style" }, [
                            _vm._v(_vm._s(_vm.sendDetailData.caseNo)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("发送时间："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.sendDetailData.ctime)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("送达时间："),
                          _c("span", [
                            _vm._v(_vm._s(_vm.sendDetailData.ctime)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-txt" }, [
                          _vm._v("回执状态："),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.receiptState[
                                  _vm.sendDetailData.receiptState
                                ]
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.sendDetailData.type == 1
                    ? _c(
                        "el-main",
                        { staticClass: "header-main" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  margin: "10px 0",
                                },
                              },
                              [_vm._v("短信内容")]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.sendDetailData.content),
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sendDetailData.type == 1
                    ? _c(
                        "el-main",
                        { staticClass: "header-main" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  margin: "10px 0",
                                },
                              },
                              [_vm._v("电子存证证书")]
                            ),
                            _vm._v(" "),
                            _vm.sendDetailData.type == 1
                              ? _c(
                                  "div",
                                  [
                                    _vm.sendDetailData.evidences
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              href: _vm.sendDetailData
                                                .evidenceFiles,
                                              underline: false,
                                              target: "_blank",
                                              type: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.sendDetailData.evidences
                                              ) + "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.sendDetailData.evidences
                                      ? _c("div", [_vm._v("未存证")])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table-again" },
                [
                  _vm.sendDetailData.type != 1
                    ? _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.sendDetailData.sendFileList,
                            "tooltip-effect": "dark",
                            "header-cell-style": {
                              background: _vm.tableHeaderColor,
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "fileName", label: "名称" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "100",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "medium",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                href: scope.row.href,
                                                underline: false,
                                                target: "_blank",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("查看")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "medium",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                href: scope.row.href,
                                                underline: false,
                                                target: "_blank",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("下载")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3939487912
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.title == "重新送达"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleResendSubmit },
                        },
                        [_vm._v("确认&保存")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "确认送达成功",
                visible: _vm.confirmServiceVisble,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.confirmServiceVisble = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "confirmServiceform",
                  attrs: {
                    model: _vm.confirmServiceform,
                    rules: _vm.confirmRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: "5",
                          placeholder: "请输入手动确认成功的理由",
                          resize: "none",
                        },
                        model: {
                          value: _vm.confirmServiceform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.confirmServiceform, "remark", $$v)
                          },
                          expression: "confirmServiceform.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirmSubmit("confirmServiceform")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }