"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dictionary = require("@/config/dictionary");
var _role = require("@/api/role");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {},
  data: function data() {
    return {
      loading: false,
      visible: false,
      span: 18,
      dataForm: {
        id: '',
        name: '',
        account: '',
        mobile: '',
        password: '',
        roleIds: [],
        state: '',
        validityDay: ''
      },
      stateList: _dictionary.dictionary.adminState,
      roleList: [],
      dataRule: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }, {
          max: 30,
          message: '名称最大长度11位数',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '账号不能为空',
          trigger: 'blur'
        }, {
          min: 5,
          max: 30,
          message: '账号长度5-30位数',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机不能为空',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '手机号码11位数',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          max: 30,
          message: '名称长度6-30位数',
          trigger: 'blur'
        }],
        roleIds: [{
          required: false,
          message: '角色不能为空',
          trigger: 'blur'
        }],
        state: [{
          required: false,
          message: '状态不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getRoleList();
  },
  methods: {
    init: function init() {
      this.visible = true;
    },
    getRoleList: function getRoleList() {
      var _this = this;
      (0, _role.getRoleList)().then(function (res) {
        _this.roleList = res.data;
      }).catch(function (error) {});
    },
    handleAddAdmin: function handleAddAdmin() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          (0, _role.addAdmin)(_this2.dataForm).then(function (res) {
            _this2.loading = false;
            if (res.code === 1) {
              _this2.visible = false;
              _this2.$message({
                message: '添加成功',
                type: 'success',
                duration: 1500,
                onClose: function onClose() {
                  _this2.$refs['dataForm'].resetFields();
                  _this2.$emit('refreshDataList');
                }
              });
            } else {
              _this2.$message.error(res.msg);
            }
          }).catch(function (error) {});
        }
      });
    },
    cancel: function cancel(dataForm) {
      this.$refs[dataForm].resetFields();
      this.visible = false;
    }
  }
};
exports.default = _default;