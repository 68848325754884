"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      time: [],
      input: "",
      reviewName: "",
      searchvalue: 'caseNo',
      // 案件搜索
      searchArr: [{
        value: "caseNo",
        label: "案号"
      }, {
        value: "caseId",
        label: "受理号"
      }]
    };
  },
  methods: {
    clearAllConditon: function clearAllConditon() {
      this.input = "";
      this.time = [];
      this.reviewName = "";
    },
    screen: function screen() {
      var params = {};
      if (this.searchvalue) {
        params[this.searchvalue] = this.input;
      }
      params.time = this.time;
      params.reviewName = this.reviewName;
      this.$emit("choose", params);
    }
  }
};
exports.default = _default;