"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _inquiry = require("@/api/inquiry.js");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _dictionary = require("@/config/dictionary");
var _caseRevoke = require("@/api/caseRevoke.js");
var _security = require("@/utils/security");
var _encryptKey = require("@/config/encryptKey");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "suppleMaterial",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: "",
      //来自子组件的数据
      date: "",
      caseState: _dictionary.dictionary.caseState,
      caseDealing: _dictionary.dictionary.caseDealing,
      fileVisble: false,
      MaterialData: [],
      fileLoading: false,
      num: "",
      reviewVisible: false,
      //审核弹窗
      rejectVisible: false,
      //驳回弹窗
      state: "",
      stateArr: [{
        value: "",
        label: "全部"
      }, {
        value: "0",
        label: "申请"
      },
      // {
      //   value: "1",
      //   label: "通过"
      // },

      {
        value: "4",
        label: "拒绝"
      }],
      reviewForm: {},
      btnLoading: false,
      btnLoading1: false,
      sendCaseVisible: false,
      withdrawRejectVisible: false,
      withdrawRejectForm: {
        reason: ""
      },
      sendForm: {},
      sendVisble: false,
      sending: false,
      fileData: {
        appPartyList: "",
        resPartyList: "",
        appFileList: "",
        resFileList: "",
        appSms: "",
        resSms: ""
      },
      reviewOpe: "",
      withdrawAgreeVisible: false,
      withdrawAgreeForm: {
        refund: "",
        refundState: "",
        caseId: ""
      },
      selectShow: false,
      selectVisible: false,
      processVisible: false,
      loadingUpload: false,
      loadingSave: false,
      docFileList: [],
      headers: {
        Authorization: localStorage.getItem("token")
      },
      action: process.env.VUE_APP_BASE_API + (0, _inquiry.partyUploadFiles)(),
      dataContentForm: {
        title: "",
        subject: "",
        caseNo: ""
      },
      dataContent: [],
      selectType: 1,
      processNextId: "",
      upcomingVisible: false,
      selectCurRow: null,
      searchvalue: "当事人",
      input: "",
      searchArr: [{
        value: "当事人",
        label: "当事人"
      }, {
        value: "案号",
        label: "案号"
      }, {
        value: "受理号",
        label: "受理号"
      }]
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;
      var params = this.getParams();
      (0, _inquiry.findRevokeCasePage)(params).then(function (res) {
        if (res.code == 1) {
          _this.loading = false;
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    getParams: function getParams() {
      var params = {
        limit: this.pageLimit,
        page: this.currentPage
      };
      if (this.state) {
        params.state = this.state;
      }
      if (this.searchvalue == "当事人") {
        params.partyName = this.input;
      } else if (this.searchvalue == "案号") {
        params.caseNo = this.input;
      } else if (this.searchvalue == "受理号") {
        params.caseId = this.input;
      }
      return params;
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //表格 处理按钮
    handleReview: function handleReview(data) {
      var _this2 = this;
      this.caseId = data.caseId;
      this.selectCurRow = data;
      // if(data.state==4){
      //   this.upcomingVisible = true;
      //   this.onLoadFixtFile();
      //   return;
      // }

      this.reviewVisible = true;
      (0, _inquiry.findRevokeInfo)(this.caseId).then(function (res) {
        if (res.code == 1) {
          _this2.reviewForm = res.data;
        } else {
          _this2.$message.error(res.msg);
        }
      });
    },
    // 拒绝按钮
    handleReject: function handleReject() {
      this.withdrawRejectVisible = true;
      this.withdrawRejectForm.reason = "";
      this.reviewOpe = "reject";
    },
    // 拒绝-下一步
    handleRejectNext: function handleRejectNext(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.btnLoading1 = true;
          (0, _inquiry.creatRefuseWithdrawFile)(_this3.caseId).then(function (res) {
            _this3.btnLoading1 = false;
            if (res.code == 1) {
              _this3.sendVisble = true;
              _this3.fileData = res.data;
            } else {
              _this3.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 送达
    handleSend: function handleSend() {
      var _this4 = this;
      this.sending = true;
      if (this.reviewOpe == "reject") {
        (0, _inquiry.sendRefuseWithdraw)({
          caseId: this.caseId,
          reason: this.withdrawRejectForm.reason
        }).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: "送达成功",
              type: "success"
            });
            window.location.reload();
          } else {
            _this4.sending = false;
            _this4.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this4.sending = false;
        });
      } else if (this.reviewOpe == "agree") {
        (0, _inquiry.sendWithdraw)({
          caseId: this.caseId,
          refund: this.withdrawAgreeForm.refund,
          refundState: this.withdrawAgreeForm.refundState
        }).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: "送达成功",
              type: "success"
            });
            window.location.reload();
          } else {
            _this4.sending = false;
            _this4.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this4.sending = false;
        });
      }
    },
    //编辑决定书
    handleEdit: function handleEdit(data) {
      this.selectCurRow = data;
      this.upcomingVisible = true;
      this.onLoadFixtFile();
    },
    // 同意按钮
    handleAgree: function handleAgree() {
      this.selectVisible = true;
      // this.withdrawAgreeVisible = true;
      // this.reviewOpe = "agree";
    },
    // 同意-下一步
    handleAgreeNext: function handleAgreeNext(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.btnLoading1 = true;
          (0, _inquiry.creatWithdrawFile)(_this5.caseId).then(function (res) {
            _this5.btnLoading1 = false;
            if (res.code == 1) {
              _this5.sendVisble = true;
              _this5.fileData = res.data;
            } else {
              _this5.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //点击筛选，发送请求列表
    screen: function screen(data) {
      var _this6 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var params = this.getParams();
      params.page = 1;
      this.conditionData = params;
      (0, _inquiry.findRevokeCasePage)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this6.tableData = res.rows;
          _this6.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.input = '';
      this.state = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;
      this.pageLimit = val;
      var params = this.getParams();
      (0, _inquiry.findRevokeCasePage)(params).then(function (res) {
        if (res.code == 1) {
          _this7.tableData = res.rows;
          _this7.total = res.total;
        }
      });
      // var params=this.getParams();
      // if (this.num) {

      // } else {
      //   //没有筛选条件的情况下
      //   findRevokeCasePage({ limit: val, page: this.currentPage }).then(res => {
      //     if (res.code == 1) {
      //       this.tableData = res.rows;
      //       this.total = res.total;
      //     }
      //   });
      // }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this8 = this;
      // if (this.num) {
      this.currentPage = val;
      var params = this.getParams();
      this.conditionData = params;
      (0, _inquiry.findRevokeCasePage)(params).then(function (res) {
        if (res.code == 1) {
          _this8.tableData = res.rows;
          _this8.total = res.total;
          _this8.currentPage = val;
        }
      });
      // } else {
      //   //没有筛选条件的情况下
      //   findRevokeCasePage({ limit: this.pageLimit, page: val }).then(res => {
      //     if (res.code == 1) {
      //       this.tableData = res.rows;
      //       this.total = res.total;
      //       this.currentPage = val;
      //     }
      //   });
      // }
    },
    changeType: function changeType(val) {
      if (val == 2) {
        this.selectShow = true;
      } else {
        this.selectShow = false;
      }
    },
    docHandlePreview: function docHandlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        var res = JSON.parse((0, _security.decryptByAES)(file.response.response, _encryptKey.encryptKey.key));
        if (res.code == 1) {
          window.open(res.data.href);
        } else {
          location.reload();
        }
      }
    },
    docHandleExceed: function docHandleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E91\u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    docBeforeUpload: function docBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      testmsg = testmsg.toLowerCase();
      var extension = testmsg === "doc";
      var extension2 = testmsg === "docx";
      var isLt2M = file.size / 1024 / 1024 < 5;
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是doc、docx格式!",
          type: "warning"
        });
      }
      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 5MB!",
          type: "warning"
        });
      }
      return (extension || extension2) && isLt2M;
    },
    docBeforeRemove: function docBeforeRemove(file) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    docHandleRemove: function docHandleRemove(file) {
      var index = (0, _index2.findWithAttr)(this.docFileList, "uid", file.uid);
      this.docFileList.splice(index, 1);
    },
    docHandleSuccess: function docHandleSuccess(res1, files, fileList) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));
      if (res.code == 1) {
        this.docFileList.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          filePath: res.data.filePath,
          uid: files.uid
        });
      } else {
        location.reload();
      }
    },
    handleUpload: function handleUpload() {
      if (this.selectType == 1) {
        this.selectVisible = false;
        this.upcomingVisible = true;
        this.onLoadFixtFile();
      } else {
        this.submitUploadForm();
      }
    },
    //获取决定书详情
    onLoadFixtFile: function onLoadFixtFile() {
      var _this9 = this;
      this.upcomingVisible = true;
      (0, _caseRevoke.onFindRevokeInfo)(this.selectCurRow.caseId).then(function (res) {
        var rest = res.data || {};
        var content = rest.content || "{}";
        var contentJSON = JSON.parse(content);
        _this9.dataContent = contentJSON.content;
        _this9.dataContentForm.title = rest.revokeBook;
        _this9.dataContentForm.subject = rest.subject;
        _this9.dataContentForm.caseNo = rest.caseNo;
        _this9.selectCurRow.reviewId = rest.reviewId;
        _this9.selectCurRow.reviewName = rest.reviewName;
      });
    },
    //提交决定书文件
    submitUploadForm: function submitUploadForm() {
      var _this10 = this;
      var id = this.selectCurRow.id;
      if (this.docFileList.length <= 0) {
        this.$message({
          message: "请上传决定书文件!",
          type: "warning"
        });
        return;
      }
      this.loadingUpload = true;
      (0, _caseRevoke.onCaseRevokeSaveFile)({
        id: id,
        filePath: this.docFileList[0].filePath
      }).then(function (res) {
        _this10.selectVisible = false;
        _this10.loadingUpload = false;
        _this10.docFileList = [];
        _this10.reviewVisible = false;
        _this10.upcomingVisible = false;
        _this10.withdrawAgreeVisible = false;
        if (res.code == "1") {
          _this10.$message({
            message: "操作成功!",
            type: "success"
          });
          _this10.init();
        } else {
          _this10.$message({
            message: res.msg ? res.msg : "操作失败",
            type: "warning"
          });
        }
      }).catch(function (error) {
        _this10.$message({
          message: "操作失败",
          type: "warning"
        });
        _this10.loadingUpload = false;
      });
    },
    handleDelete: function handleDelete(val) {
      if (this.dataContent.length == 0) {
        this.$message.error("决定书修正信息已为空");
      } else {
        this.dataContent.splice(val, 1);
      }
    },
    handleAdd: function handleAdd(val) {
      this.dataContent.splice(val + 1, 0, {
        text: ""
      });
    },
    //决定书保存
    handleSave: function handleSave() {
      var _this11 = this;
      this.loadingSave = true;
      var parmas = {
        id: this.selectCurRow.id,
        content: JSON.stringify({
          content: this.dataContent
        })
      };
      (0, _caseRevoke.onCaseRevokeSave)(parmas).then(function (res) {
        _this11.loadingSave = false;
        if (res.code == "1") {
          _this11.$message({
            message: "操作成功!",
            type: "success"
          });
          _this11.reviewVisible = false;
          _this11.upcomingVisible = false;
          _this11.withdrawAgreeVisible = false;
          _this11.init();
        } else {
          _this11.$message({
            message: res.msg ? res.msg : "操作失败!",
            type: "warning"
          });
        }
      });
      //保存预览
    },
    handleNewReject: function handleNewReject() {
      var _this12 = this;
      this.$refs["withdrawRejectForm"].validate(function (valid) {
        if (valid) {
          _this12.btnLoading1 = true;
          (0, _caseRevoke.onCaseRevokeRefuse)({
            id: _this12.selectCurRow.id,
            reason: _this12.withdrawRejectForm.reason
          }).then(function (res) {
            _this12.btnLoading1 = false;
            _this12.reviewVisible = false;
            _this12.withdrawRejectVisible = false;
            if (res.code == 1) {
              _this12.$message({
                message: "操作成功!",
                type: "success"
              });
              setTimeout(function () {
                _this12.init();
              }, 1000);
            } else {
              _this12.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    contentChangeLine: function contentChangeLine(msg) {
      // if(msg.length>10){
      //   for(var i=0;<)
      // }
    },
    contentChangeMin: function contentChangeMin(msg) {}
  }
};
exports.default = _default;