var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: { effect: "dark", content: _vm.dataContent, placement: "top" },
    },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "10px" },
          attrs: { size: "mini", type: "text" },
          on: { click: _vm.handleClick },
        },
        [
          _c("span", {
            staticClass: "iconify-inline",
            style:
              "color:" + _vm.dataColor + "; font-size: " + _vm.fontSize + ";",
            attrs: { "data-icon": _vm.dataIcon },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }