var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-settlement" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-tip" }, [
          _vm._v(
            "从起始日 00:00:00 至截止日 23:59:59，所有可结算状态案件的代收类费用汇总。"
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-content bg-purple header-date" },
          [
            _vm._v("\n        选择结算周期：\n        "),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                format: "yyyy 年 MM 月 dd 日",
                "value-format": "yyyy-MM-dd",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              on: {
                change: function ($event) {
                  return _vm.dateChange(_vm.cycleVal)
                },
              },
              model: {
                value: _vm.cycleVal,
                callback: function ($$v) {
                  _vm.cycleVal = $$v
                },
                expression: "cycleVal",
              },
            }),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "primary" } }, [_vm._v("确定")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "content-tip" }, [
            _vm._v("共 3000 个案件，合计金额 300000 元"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: "rgba(242, 243, 245, 1)" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "link-style" }, [
                          _vm._v(_vm._s(scope.row.reference)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "结案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "acceptNum", label: "标的额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "application", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "respondent", label: "鉴定费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "operate", label: "结算总金额(元)" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "create-settle-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: 4,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  _vm.noteMakingVisible = true
                },
              },
            },
            [_vm._v("备注")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "btn", attrs: { size: "medium", type: "primary" } },
            [_vm._v("申请对账")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "制单备注", visible: _vm.noteMakingVisible },
          on: {
            "update:visible": function ($event) {
              _vm.noteMakingVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请写入备注",
              resize: "none",
              rows: "5",
            },
            model: {
              value: _vm.noteMakeVal,
              callback: function ($$v) {
                _vm.noteMakeVal = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "noteMakeVal",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noteMakingVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }