var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "refund" },
    [
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _vm._v("\n              案件搜索：\n              "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "medium", placeholder: "当事人" },
                      model: {
                        value: _vm.searchvalue,
                        callback: function ($$v) {
                          _vm.searchvalue = $$v
                        },
                        expression: "searchvalue",
                      },
                    },
                    _vm._l(_vm.searchArr, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { width: "300px", display: "inline-block" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "medium", placeholder: "请输入内容" },
                        model: {
                          value: _vm.input,
                          callback: function ($$v) {
                            _vm.input = $$v
                          },
                          expression: "input",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            false ? _c("el-col", { attrs: { span: 8 } }) : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _vm._v("\n              提交时间：\n              "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dateChange()
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n               案件来源：\n               "),
                  _c(
                    "el-select",
                    {
                      attrs: { size: "medium", placeholder: "请选择案件来源" },
                      model: {
                        value: _vm.caseOriginvalue,
                        callback: function ($$v) {
                          _vm.caseOriginvalue = $$v
                        },
                        expression: "caseOriginvalue",
                      },
                    },
                    _vm._l(_vm.caseOrigin, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "clear-all", on: { click: _vm.clear } }, [
              _vm._v("清空筛选条件"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "refund-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: "rgba(242, 243, 245, 1)" },
              },
            },
            [
              _c("el-table-column", { attrs: { prop: "aa1", label: "案号" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa2", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa3", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa4", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "aa5", label: "案由" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa6", label: "标的额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa7", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa8", label: "案件状态" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "aa9", label: "案件来源" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row.caseId)
                              },
                            },
                          },
                          [_vm._v("退费")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核退费", visible: _vm.feeDetailVisible },
          on: {
            "update:visible": function ($event) {
              _vm.feeDetailVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "fee-detail-content" }, [
            _c("p", [_vm._v(_vm._s(_vm.chooseCase) + "案件仲裁费计算:")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fee-list" },
              _vm._l(_vm.caseArr, function (item, index) {
                return _c("div", { key: index, staticClass: "fee-list-item" }, [
                  _c("div", { staticClass: "fee-list-item-name" }, [
                    _vm._v(_vm._s(item.type)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fee-list-item-num" }, [
                    _vm._v(_vm._s(item.num) + "元"),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.feeDetailVisible = false
                    },
                  },
                },
                [_vm._v("拒 绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.feeReturnVisible = true
                    },
                  },
                },
                [_vm._v("通 过")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退费确认",
            visible: _vm.feeReturnVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.feeReturnVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "fee-return-item" },
            [
              _vm._v("\n          退回方式："),
              _c(
                "el-select",
                {
                  attrs: { size: "medium", placeholder: "当事人" },
                  model: {
                    value: _vm.searchvalue,
                    callback: function ($$v) {
                      _vm.searchvalue = $$v
                    },
                    expression: "searchvalue",
                  },
                },
                _vm._l(_vm.searchArr, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fee-return-item" }, [
            _vm._v("\n          退回仲裁费：3000元\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.feeReturnVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.feeReturnVisible = false
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }