var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "arbitrator-free global-page-base-layout" },
    [
      _c(
        "div",
        { staticClass: "add-people" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoCreate } },
            [_vm._v("添加仲裁费模板")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "select-conditions" }, [
        _c(
          "div",
          { staticClass: "select-conditions-row" },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n              所属仲裁机构：\n              "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择" },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    },
                    _vm._l(_vm.committeeArr, function (item, index) {
                      return _c("el-option", {
                        key: item.index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-light" },
                [
                  _vm._v("\n              状态：\n              "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择状态" },
                      model: {
                        value: _vm.statevalue,
                        callback: function ($$v) {
                          _vm.statevalue = $$v
                        },
                        expression: "statevalue",
                      },
                    },
                    _vm._l(_vm.stateArr, function (item) {
                      return _c("el-option", {
                        key: item.status,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "select-conditions-row",
            staticStyle: { "text-indent": "5em", "margin-top": "25px" },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.screen },
              },
              [_vm._v("筛选")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "clear-all", on: { click: _vm.clearAllConditon } },
              [_vm._v("清空筛选条件")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "commName", label: "仲裁机构名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "仲裁费收费模板名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(scope.row.state == 1 ? "已启用" : "已禁用") +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "设置时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "utime", label: "最后修改时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "free",
          attrs: {
            title: _vm.title,
            visible: _vm.freeVisible,
            top: "5vh",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.freeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "freeForm",
              attrs: {
                model: _vm.freeForm,
                "label-position": "left",
                "label-width": "120px",
                rules: _vm.freeRules,
              },
            },
            [
              _c("span", [_vm._v("基础信息")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属仲裁机构", prop: "organName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { size: "medium", placeholder: "请选择" },
                      model: {
                        value: _vm.freeForm.organName,
                        callback: function ($$v) {
                          _vm.$set(_vm.freeForm, "organName", $$v)
                        },
                        expression: "freeForm.organName",
                      },
                    },
                    _vm._l(_vm.committeeArr, function (item, index) {
                      return _c("el-option", {
                        key: item.index,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模板名称", prop: "temName" } },
                [
                  _c("el-input", {
                    staticClass: "freeform-item",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.freeForm.temName,
                      callback: function ($$v) {
                        _vm.$set(_vm.freeForm, "temName", $$v)
                      },
                      expression: "freeForm.temName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模板状态", prop: "state" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.freeForm.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.freeForm, "state", $$v)
                        },
                        expression: "freeForm.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "启用" } }),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v("费用设置")]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("span", { staticClass: "fee-tip" }, [
                _vm._v("仲裁费=案件受理费+案件处理费"),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.freeTableData },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { label: "标的额" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", width: "120px", label: "大于" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: scope.row.priceMin,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "priceMin", $$v)
                                    },
                                    expression: "scope.row.priceMin",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          width: "120px",
                          label: "小于或等于",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: scope.row.priceMax,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "priceMax", $$v)
                                    },
                                    expression: "scope.row.priceMax",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "受理费" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "60%" },
                                model: {
                                  value: scope.row.acceptanceType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "acceptanceType", $$v)
                                  },
                                  expression: "scope.row.acceptanceType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "按百分比(%)",
                                    value: "按百分比(%)",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "固定金额(元)",
                                    value: "固定金额(元)",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "33%" },
                              attrs: { type: "text" },
                              model: {
                                value: scope.row.acceptanceFee,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "acceptanceFee", $$v)
                                },
                                expression: "scope.row.acceptanceFee",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "处理费" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "60%" },
                                model: {
                                  value: scope.row.handlingType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "handlingType", $$v)
                                  },
                                  expression: "scope.row.handlingType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "按百分比(%)",
                                    value: "按百分比(%)",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "固定金额(元)",
                                    value: "固定金额(元)",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "33%" },
                              attrs: { type: "text" },
                              model: {
                                value: scope.row.handlingFree,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "handlingFree", $$v)
                                },
                                expression: "scope.row.handlingFree",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow(
                                      scope.$index,
                                      _vm.freeTableData
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n                移除\n              ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "add-row", on: { click: _vm.addRows } },
                [_vm._v("添加行")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitFreeForm("freeForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }