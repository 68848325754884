var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "修改角色",
        "close-on-click-modal": false,
        visible: _vm.visible,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "180px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "name" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.dataForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "权限分配" } },
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "快速查找" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "role-main" },
        [
          _c(
            "div",
            { staticClass: "select-btn" },
            [
              _c("el-button", { on: { click: _vm.resetChecked } }, [
                _vm._v("清空"),
              ]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.restoreCheckedKeys } }, [
                _vm._v("恢复"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.data,
              "show-checkbox": "",
              "node-key": "id",
              "default-expanded-keys": _vm.selectedId,
              "default-checked-keys": _vm.selectedId,
              "expand-on-click-node": false,
              "filter-node-method": _vm.filterNode,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.cancel("dataForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAddRole()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }