var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-page-base-layout" },
    _vm._l(_vm.tableData, function (item) {
      return _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v(_vm._s(item.name) + "提醒"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-remind" },
          [
            _vm._v("\n      流转到" + _vm._s(item.name) + "后\n      "),
            _c("el-input", {
              staticClass: "remind-input",
              attrs: { type: "number" },
              on: {
                blur: function ($event) {
                  return _vm.remindDayBlur(item)
                },
              },
              model: {
                value: item.remindDay,
                callback: function ($$v) {
                  _vm.$set(item, "remindDay", $$v)
                },
                expression: "item.remindDay",
              },
            }),
            _vm._v("\n      天提醒\n      "),
            _c(
              "el-radio-group",
              {
                staticClass: "radio-group",
                on: {
                  change: function ($event) {
                    return _vm.remindStateChange(item)
                  },
                },
                model: {
                  value: item.remindState,
                  callback: function ($$v) {
                    _vm.$set(item, "remindState", $$v)
                  },
                  expression: "item.remindState",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }