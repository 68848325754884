var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "judge global-page-base-layout" },
    [
      _c("Caseconditons", {
        attrs: { rowSecondName: "开庭日期" },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "judge-all-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.handleBatchReview },
            },
            [_vm._v("批量审核")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "judge-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: "rgba(242, 243, 245, 1)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitratorName", label: "仲裁员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "composeTime", label: "组庭时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == "41"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("裁决书审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        scope.row.state == "41"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "medium" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAgainCourt(scope.row)
                                  },
                                },
                              },
                              [_vm._v("再次开庭")]
                            )
                          : _c("el-button", {
                              attrs: { type: "text", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleReview(scope.row)
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              attrs: {
                title: _vm.ruleFile.fileName,
                visible: _vm.closeCaseVisible,
                size: "50%",
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.closeCaseVisible = $event
                },
              },
            },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.ruleFile.aliyunOssHref,
                  width: "100%",
                  height: "90%",
                  frameborder: "0",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "demo-drawer__content" },
                [
                  _c(
                    "el-dialog",
                    {
                      staticClass: "close-case",
                      attrs: {
                        "append-to-body": true,
                        title: "拒绝理由",
                        visible: _vm.rejectVisible,
                        "close-on-click-modal": false,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.rejectVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "opinionform",
                          attrs: {
                            "label-width": "50px",
                            model: _vm.opinionform,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "理由",
                                prop: "txt",
                                rules: [
                                  { required: true, message: "请填写拒绝理由" },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "textarea",
                                  rows: "5",
                                  placeholder: "请输入审核意见",
                                  resize: "none",
                                },
                                model: {
                                  value: _vm.opinionform.txt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.opinionform,
                                      "txt",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "opinionform.txt",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.rejectVisible = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmReject("opinionform")
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "demo-drawer__footer",
                      staticStyle: { float: "right", margin: "30px 30px 0 0" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.rejectVisible = true
                            },
                          },
                        },
                        [_vm._v("拒绝")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.gotoServicing,
                          },
                          on: { click: _vm.gotoService },
                        },
                        [_vm._v("通过")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "上传裁决书",
                visible: _vm.uploadVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.uploadVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件：" } },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "margin-right",
                          attrs: {
                            type: "primary",
                            underline: false,
                            href: _vm.uploadRule.aliyunOssHref,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.uploadRule.fileName))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: ".pdf,.PDF",
                                action: _vm.uploadApi,
                                "show-file-list": false,
                                headers: { Authorization: _vm.token },
                                "on-success": _vm.handleAvatarSuccess,
                                "before-upload": _vm.beforeAvatarUpload,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "medium", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.uploadVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.gotoServicing },
                      on: { click: _vm.handleUploadSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "holdcourt",
              attrs: {
                title: "案件开庭",
                visible: _vm.holdcourtVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.holdcourtVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "holdcourtForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "120px",
                    model: _vm.holdcourtForm,
                    rules: _vm.holdcourtRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭时间", prop: "moment" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择开庭日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.holdcourtForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtForm, "date", $$v)
                              },
                              expression: "holdcourtForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              placeholder: "选择开庭时间",
                              "picker-options": {
                                start: "08:00",
                                step: "00:05",
                                end: "21:00",
                              },
                            },
                            model: {
                              value: _vm.holdcourtForm.moment,
                              callback: function ($$v) {
                                _vm.$set(_vm.holdcourtForm, "moment", $$v)
                              },
                              expression: "holdcourtForm.moment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开庭地址", prop: "courtAddress" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.holdcourtForm.courtAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.holdcourtForm, "courtAddress", $$v)
                            },
                            expression: "holdcourtForm.courtAddress",
                          },
                        },
                        _vm._l(_vm.courtList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.address, value: item.name },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "holdcourtLot-add",
                          on: {
                            click: function ($event) {
                              _vm.holdcourtInnerVisible = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.holdcourtVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.gotoServicing },
                      on: {
                        click: function ($event) {
                          return _vm.handleAgainCourtSubmit("holdcourtForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "添加开庭地址",
                    visible: _vm.holdcourtInnerVisible,
                    "close-on-click-modal": false,
                    "append-to-body": "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.holdcourtInnerVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addAddressForm",
                      attrs: {
                        "label-position": "right",
                        "label-width": "120px",
                        model: _vm.addAddressForm,
                        rules: _vm.addAddressRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写开庭名称（20字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "name", $$v)
                              },
                              expression: "addAddressForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写开庭地址（255字以内）",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "address", $$v)
                              },
                              expression: "addAddressForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式", prop: "tel" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: {
                              placeholder: "请填写联系方式",
                              resize: "none",
                            },
                            model: {
                              value: _vm.addAddressForm.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.addAddressForm, "tel", $$v)
                              },
                              expression: "addAddressForm.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.holdcourtInnerVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddAddress("addAddressForm")
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "award-editor",
              attrs: {
                title: "编辑裁决书",
                visible: _vm.editVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "award-btn" },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("自动生成裁决书"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            "auto-upload": false,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.submitUpload },
                            },
                            [_vm._v("上传裁决书")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "500px" } },
                [
                  _c("Editor", {
                    model: {
                      value: _vm.article.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.article, "content", $$v)
                      },
                      expression: "article.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.gotoServicing },
                      on: { click: _vm.gotoService },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.gotoServicing },
                      on: { click: _vm.gotoService },
                    },
                    [_vm._v("保存并提交审核")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量上传裁决书",
                visible: _vm.batchUploadVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.batchUploadVisible = $event
                },
              },
            },
            [
              _c("div", [
                _vm._v("（温馨提示：文件不能超过100MB，支持文件格式：zip）"),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        accept: ".zip",
                        action: _vm.batchUploadApi,
                        "show-file-list": false,
                        headers: { Authorization: _vm.token },
                        "on-success": _vm.handleBatchUploadSuccess,
                        "before-upload": _vm.beforeBatchUpload,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.batchUploadVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.sendCaseVisible
        ? _c("send-case", {
            ref: "sendCase",
            on: {
              handleShowing: _vm.updateShow,
              handleErrorShowing: _vm.updateErrorShow,
              initList: _vm.refreshPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }